import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT } from "src/utils/constants";
import { CabVendor } from "./bookings";

export interface DriverDataForm {
  name: string;
  // address: string;
  // pincode: string;
  city: string;
  // country: string;
  driver_license_number: string;
  contact_number: string;
  alternate_number: string | null;
  cab_number: string;
  cab_vendor_id: string | null;
  rating: string;
  notes: string;
  // status: string;
}

export interface DriverEditDataForm extends DriverDataForm {
  id: string;
}

export interface DriverData {
  id: string;
  name: string;
  cab_vendor?: CabVendor;
  // address: string;
  // pincode: string;
  city: string;
  // country: string;
  driver_license_number: string;
  contact_number: string;
  alternate_number: string;
  cab_number: string;
  cab_vendor_id: string;
  rating: number;
  notes: string;
  status: number;
  created_date: string;
  updated_date: string;
}

export interface DriversState {
  // data?: DriverData[];
  data?: Pagination<DriverData>;
  status: API_STATUS;
  list: {
    data: DriverData[];
    status: API_STATUS;
  };
  view: {
    data?: DriverData;
    status: API_STATUS;
  };
}

const initialState: DriversState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  list: {
    data: [],
    status: "idle",
  },
  view: {
    data: undefined,
    status: "idle",
  },
  status: "idle",
};

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    refreshDriverList: (state) => {
      state.status = "idle";
    },
    resetDriverView(state) {
      state.view.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDrivers.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchDrivers.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchDrivers.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchListDrivers.pending, (state, action) => {
      state.list.status = "pending";
    });
    builder.addCase(fetchListDrivers.fulfilled, (state, action) => {
      state.list.status = "success";
      state.list.data = action.payload;
    });
    builder.addCase(fetchListDrivers.rejected, (state, action) => {
      state.list.status = "error";
    });
    builder.addCase(addDriver.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(viewDriver.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(viewDriver.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(viewDriver.rejected, (state, action) => {
      state.view.status = "error";
    });
  },
});

export const fetchListDrivers = createAsyncThunk<
  DriverData[],
  void,
  { state: RootState }
>("drivers/fetchList", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<DriverData[]>,
      AxiosResponse<PartnerAPIResponseCover<DriverData[]>>
    >(`partners/driver/search/?format=json`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchDrivers = createAsyncThunk<
  Pagination<DriverData>,
  { page: number; keyword: string },
  { state: RootState }
>("driver/fetch", async ({ page, keyword }, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<DriverData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<DriverData>>>
    >(
      `partners/driver?limit=${DATA_LIMIT}&keyword=${keyword}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`
    );
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const listDrivers = createAsyncThunk<
  DriverData[],
  void,
  { state: RootState }
>("driver/list", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<DriverData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<DriverData>>>
    >("partners/driver/search/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const deleteDriver = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("driver/delete", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>>
    >(`partners/driver/${id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const updateDriver = createAsyncThunk<
  { status: string },
  DriverEditDataForm,
  { state: RootState }
>("driver/update", async (data: DriverEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, DriverEditDataForm>,
      DriverEditDataForm
    >(`partners/driver/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const viewDriver = createAsyncThunk<
  DriverData,
  string,
  { state: RootState }
>("driver/view", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<DriverData>,
      AxiosResponse<APIResponseCover<DriverData>>
    >(`partners/driver/${id}?format=json`);
    // console.log(response.data);
    return response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addDriver = createAsyncThunk<
  { status: string },
  DriverDataForm,
  { state: RootState }
>("driver/add", async (data: DriverDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, DriverDataForm>,
      DriverDataForm
    >("partners/driver?format=json", data);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshDriverList, resetDriverView } = driversSlice.actions;

export default driversSlice.reducer;
