import { useEffect } from "react";
export const useDocumentEvent = (events: { type: string; callback: any }[]) => {
  useEffect(() => {
    events.forEach((e) => {
      document.addEventListener(e.type, e.callback);
    });
    return () =>
      events.forEach((e) => {
        document.removeEventListener(e.type, e.callback);
      });
  }, [events]);
};
