import React, { Fragment, useState } from "react";

import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
//import RightSideBar from "./RightSideBar";
// import ChatBox from "../ChatBox";

const JobieNav = () => {
  // const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  // const [toggle, setToggle] = useState("");
  // const onClick = (name: string) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      <NavHeader />
      {/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
      <Header
      // onNote={() => onClick("chatbox")}
      // onNotification={() => onClick("notification")}
      // onProfile={() => onClick("profile")}
      // toggle={toggle}
      // title={title}
      // onBox={() => onClick("box")}
      // onClick={() => ClickToAddEvent()}
      />
      <SideBar />
    </Fragment>
  );
};

export default JobieNav;
