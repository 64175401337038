import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import {
  BookingData,
  BookingDataEditForm,
  editBooking,
  nullValueKeysBooking,
} from "src/store/reducers/bookings";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import {
  KeyRequired,
  LengthRequired,
  RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { fetchListAgents } from "src/store/reducers/agents";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

type IEditBookingCanvas = {
  Title: string;
  editId: string;
  hide: () => void;
  bookingData: BookingData;
};

const EditBookingCanvas = ({
  Title,
  editId,
  hide,
  bookingData,
}: IEditBookingCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  const agentsList = useAppSelector((state) => state.agents.list.data);
  const agentsListStatus = useAppSelector((state) => state.agents.list.status);
  // const navigate = useNavigate();

  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      customer_name: bookingData.customer_name,
      customer_number: bookingData.customer_number.toString(),
      email: bookingData.email || "",
      arrival_date: bookingData.arrival_date,
      departure_date: bookingData.departure_date,
      total_pax: bookingData.total_pax ? bookingData.total_pax.toString() : "",
      total_children: bookingData.total_children
        ? bookingData.total_children.toString()
        : "",
      total_amount: bookingData.total_amount.toString(),
      amount_received: bookingData.amount_received
        ? bookingData.amount_received.toString()
        : "",
      next_payment_date: bookingData.next_payment_date || "",
      agent_commission: bookingData.agent_commission || "",
      flight_tickets: bookingData.flight_tickets || "",
      travel_agent_id: bookingData.travel_agent?.id || "",
      customer_address: bookingData.customer_address || "",
      customer_gstn: bookingData.customer_gstn || "",
      notes: bookingData.notes || "",
      //   profit: bookingData.profit,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      customer_name: StringLengthValidation("Customer name", 3, 30, false),
      customer_number: LengthRequired("Customer number", 10, false),
      email: Yup.string().email("Invalid email address"),
      arrival_date: KeyRequired("Arrival date"),
      departure_date: KeyRequired("Departure date"),
      agent_commission: RangeRequired("Agent commission", 0, 100, false),
      total_pax: Yup.number().positive().integer(),
      // total_children: Yup.number().positive().integer(),
      total_amount: Yup.number()
        .positive()
        .integer()
        .required("Total amount is required"),
      amount_received: Yup.number().positive().integer(),
      next_payment_date: Yup.string(),
      flight_tickets: Yup.string(),
      // travel_agent_id: KeyRequired("Travel Agent"),
      customer_address: Yup.string(),
      customer_gstn: Yup.string(),
      notes: Yup.string(),
      //   profit: Yup.string().required("Profit is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      // console.log(values);
      const finalValues: BookingDataEditForm = {
        ...values,
        id: bookingData.id,
      };
      setPending(true);
      nullValueKeysBooking.forEach((k) => {
        if (!finalValues[k]) {
          finalValues[k] = null;
        }
      });
      editB(finalValues);
      hide();
    },
  });

  const editB = async (values: BookingDataEditForm) => {
    try {
      await dispatch(editBooking(values)).unwrap();
      toast.success("Booking is updated successfully");
    } catch (error: any) {
      toast.error(error.message || "Failed");
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    resetForm();
    if (editId !== "") {
      dispatch(fetchListAgents());
    }
  }, [resetForm, editId, dispatch]);

  // useEffect(() => {
  //   if (agentsListStatus === "idle") {
  //     dispatch(fetchListAgents());
  //   }
  // }, [agentsListStatus, dispatch]);

  return (
    <Offcanvas
      show={editId !== ""}
      onHide={hide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h3 className="modal-title" id="#gridSystemModal">
          {Title}
        </h3>
        <button type="button" className="btn-close" onClick={hide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_name"
                  labelText="Customer name"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_name}
                  touched={touched.customer_name}
                  errors={errors.customer_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_number"
                  labelText="Customer number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_number}
                  touched={touched.customer_number}
                  errors={errors.customer_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email"
                  inputType="email"
                  labelText="Customer Email"
                  // required={true}
                  handleChange={handleChange}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="arrival_date"
                  labelText="Arrival date"
                  required={true}
                  handleChange={(date) => setFieldValue("arrival_date", date)}
                  value={values.arrival_date}
                  touched={touched.arrival_date}
                  errors={errors.arrival_date}
                  selectsStart
                  endDate={values.departure_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="departure_date"
                  labelText="Departure date"
                  required={true}
                  handleChange={(date) => setFieldValue("departure_date", date)}
                  value={values.departure_date}
                  touched={touched.departure_date}
                  errors={errors.departure_date}
                  selectsEnd
                  startDate={values.arrival_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="agent_commission"
                  labelText="Agent commision"
                  // required={true}
                  handleChange={handleChange}
                  value={values.agent_commission}
                  touched={touched.agent_commission}
                  errors={errors.agent_commission}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="profit"
                  labelText="Profit"
                  required={true}
                  handleChange={handleChange}
                  value={values.profit}
                  touched={touched.profit}
                  errors={errors.profit}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_pax"
                  labelText="Total pax"
                  // required={true}
                  handleChange={handleChange}
                  value={values.total_pax}
                  touched={touched.total_pax}
                  errors={errors.total_pax}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_children"
                  labelText="Total children"
                  // required={true}
                  handleChange={handleChange}
                  value={values.total_children}
                  touched={touched.total_children}
                  errors={errors.total_children}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_amount"
                  labelText="Total amount"
                  required={true}
                  handleChange={handleChange}
                  value={values.total_amount}
                  touched={touched.total_amount}
                  errors={errors.total_amount}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="amount_received"
                  labelText="Amount received"
                  // required={true}
                  handleChange={handleChange}
                  value={values.amount_received}
                  touched={touched.amount_received}
                  errors={errors.amount_received}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="next_payment_date"
                  labelText="Next payment date"
                  // required={true}
                  handleChange={(date) =>
                    setFieldValue("next_payment_date", date)
                  }
                  value={values.next_payment_date}
                  touched={touched.next_payment_date}
                  errors={errors.next_payment_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_gstn"
                  labelText="Customer GSTN"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_gstn}
                  touched={touched.customer_gstn}
                  errors={errors.customer_gstn}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_address"
                  labelText="Customer address"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_address}
                  touched={touched.customer_address}
                  errors={errors.customer_address}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Travel Agent"
                  placeholder="Travel Agent"
                  id={"travel_agent_id"}
                  options={agentsList.map((agent) => ({
                    label: agent.name,
                    value: agent.id,
                  }))}
                  handleChange={handleChange}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                  value={
                    agentsList
                      .filter((agent) => agent.id === values.travel_agent_id)
                      ?.map((agent) => ({
                        label: agent.name,
                        value: agent.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="travel_agent_id"
                  labelText="Travel Agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent_id}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                >
                  <option>Select Travel Agent</option>
                  {agentsList.map((agent) => (
                    <option value={agent.id} key={agent.id}>
                      {agent.name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="travel_agent_id"
                  labelText="Travel Agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent_id}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                /> */}
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="flight_tickets"
                  labelText="Flight tickets"
                  // required={true}
                  handleChange={handleChange}
                  value={values.flight_tickets ?? undefined}
                  touched={touched.flight_tickets}
                  errors={errors.flight_tickets}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={pending}
              className="btn btn-primary me-1"
            >
              Submit
            </button>
            <Link to={"#"} onClick={hide} className="btn btn-danger light ms-1">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default EditBookingCanvas;
