import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  BOOKING_STATUS_LABEL,
  BOOKING_STATUS_LABEL_COLOR,
} from "src/store/reducers/bookings";
import { StatusReport } from "src/store/reducers/reports";
type IAllProjectDonutChart = {
  statusData: StatusReport[];
};
const AllProjectDonutChart = ({ statusData }: IAllProjectDonutChart) => {
  const seriesData = statusData?.map((data) => data.count);
  const seriesLabels = statusData?.map(
    (data) => BOOKING_STATUS_LABEL[data.status]
  );

  const seriesLabelColors = statusData?.map(
    (data) => BOOKING_STATUS_LABEL_COLOR[data.status]
  );
  const data: {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexCharts.ApexOptions;
  } = {
    series: seriesData,
    options: {
      chart: {
        type: "donut",
        width: 150,
      },
      colors: seriesLabelColors,
      labels: seriesLabels,
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: 12,
              },
              value: {
                show: true,
                fontSize: "22px",
                fontFamily: "Arial",
                fontWeight: "500",
                offsetY: -17,
              },
              total: {
                show: true,
                fontSize: "11px",
                fontWeight: "500",
                fontFamily: "Arial",
                label: "All bookings",
                color: "var(--primary)",

                formatter: function (w) {
                  return w.globals.seriesTotals.reduce(
                    (a: number, b: number) => {
                      return a + b;
                    },
                    0
                  );
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <div id="AllProject">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        width={150}
      />
    </div>
  );
};

export default AllProjectDonutChart;
