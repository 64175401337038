import React, { useCallback, useEffect, useState } from "react";
import { Link, redirect, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  BOOKING_STATUS_LABEL,
  deleteCabBooking,
  deleteHotelBooking,
  downloadBookingVoucher,
  fetchBookingDetail,
  resetBookingView,
} from "src/store/reducers/bookings";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import BookingDetailItem from "./BookingDetailItem";
import EditBookingCanvas from "./EditBookingCanvas";
import ListBookingCabs from "./ListBookingCabs";
import ConfirmationModal from "src/layouts/modals/ConfirmationModal";
import ListBookingHotels from "./ListBookingHotels";
import { toast } from "react-toastify";
import ListBookingTransactions from "./ListBookingTransactions";
import BookingDetailItemIcon from "./BookingDetailItemIcon";
import { GainMarginCSSLabel } from "src/utils/constants";

const ViewBooking = () => {
  const dispatch = useAppDispatch();

  const { id: bookingId } = useParams();

  const bookingViewStatus = useAppSelector(
    (state) => state.bookings.view.status
  );
  const bookingData = useAppSelector((state) => state.bookings.view.data);
  const [deleteHotelId, setDeleteHotelId] = useState<string>();
  const [deleteCabId, setDeleteCabId] = useState<string>();
  const [voucherDownload, setVoucherDownload] = useState(false);
  const [editId, setEditId] = useState("");
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  // const [currentPage, setCurrentPage] = useState(0);
  // const sort = 5;

  useEffect(() => {
    if (!bookingId) {
      redirect("/bookings");
    }
  }, [bookingId]);

  const retry = useCallback(() => {
    dispatch(fetchBookingDetail(bookingId));
  }, [bookingId, dispatch]);

  useEffect(() => {
    if (bookingViewStatus === "idle") {
      retry();
    }
  }, [bookingViewStatus, retry]);

  const downloadVoucher = useCallback(async () => {
    try {
      setVoucherDownload(true)
      toast.info("Please wait while we are downloading voucher");
      const url = await dispatch(downloadBookingVoucher(bookingId)).unwrap();
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Voucher${bookingData?.customer_name}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      setTimeout(function () {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 200);

      // toast.success("Voucher downloaded successfully");
    } catch (e) {
      toast.error("Please try again");
    }
    setVoucherDownload(false)

  }, [bookingId, bookingData, dispatch]);

  const confirmDelete = useCallback(async () => {
    if (deleteHotelId) {
      try {
        dispatch(deleteHotelBooking(deleteHotelId));
        toast.success("Hotel deleted successfully");
      } catch (e) {
        toast.error("Please try again");
      } finally {
        setDeleteHotelId(undefined);
      }
    }
    if (deleteCabId) {
      try {
        dispatch(deleteCabBooking(deleteCabId));
        toast.success("Cab deleted successfully");
      } catch (e) {
        toast.error("Please try again");
      } finally {
        setDeleteCabId(undefined);
      }
    }
    // setShowConfirmation(false);
  }, [deleteCabId, deleteHotelId, dispatch]);

  const cancelDelete = () => {
    setDeleteHotelId(undefined);
    setDeleteCabId(undefined);
  };

  useEffect(() => {
    if (bookingData?.id !== bookingId) {
      dispatch(resetBookingView());
    }
  }, [bookingData?.id, bookingId, dispatch]);

  return (
    <>
      {/* <MainPagetitle
        mainTitle="View Booking"
        pageTitle="View Booking"
        parentTitle="Home"
      /> */}
      <div className="container-fluid mh-auto">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {bookingViewStatus === "success" && bookingData ? (
                  <div className="row">
                    <div className="col-xl-3 col-lg-3  col-md-3 col-sm-3">
                      <div className="about-me">
                        <ul>
                          <BookingDetailItemIcon
                            title={"Customer name"}
                            icon={"user"}
                            value={bookingData.customer_name}
                          />
                          <BookingDetailItemIcon
                            icon={"phone"}
                            title={"Customer number"}
                            value={bookingData.customer_number}
                          />
                          <BookingDetailItemIcon
                            icon={"plane-arrival"}
                            title={"Arrival Date"}
                            value={new Date(
                              bookingData.arrival_date
                            ).toLocaleDateString("en-IN")}
                          />
                          <BookingDetailItemIcon
                            icon={"plane-departure"}
                            title={"Departure date"}
                            value={new Date(
                              bookingData.departure_date
                            ).toLocaleDateString("en-IN")}
                          />
                          <BookingDetailItemIcon
                            icon={"user-md"}
                            title={"User"}
                            value={
                              bookingData.user.first_name +
                              " " +
                              bookingData.user.last_name
                            }
                          />
                          {/* <li>
                            <i className="fa-solid fa-book"></i>
                            <div>
                              <h6>Techno India NJR Institute</h6>
                            </div>
                          </li>
                          <li>
                            <i className="fa-solid fa-location-dot"></i>
                            <div>
                              <h6>Lived In Usa</h6>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                      <div className="d-flex mt-4 justify-content-end">
                        <a
                          className={!voucherDownload ? "modal-btn btn btn-primary btn-sm  ms-2" : "modal-btn btn btn-primary btn-sm  ms-2 disabled"}
                          onClick={ () => !voucherDownload ? downloadVoucher() : () => {}}
                          
                        >
                          {voucherDownload ? <i className="fa-solid fa-spinner me-1"></i> : <i className="fa-solid fa-download me-1"></i> }
                           Voucher
                        </a>
                        <Link
                          to={"#"}
                          onClick={() => setEditId(bookingData.id)}
                          className="modal-btn btn btn-primary btn-sm  ms-2"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          Edit
                        </Link>
                        {/* <button
                          type="button"
                          className="modal-btn btn btn-primary btn-sm  ms-2 "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          <span>Edit </span>
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9  col-md-9 col-sm-9">
                      <div className="product-detail-content">
                        <div className=" pr">
                          {/* <h4
                            className="heading mb-0"
                            style={{ wordWrap: "break-word" }}
                          >
                            View booking details
                          </h4>
                          <Link
                            to={"#"}
                            onClick={() => setEditId(bookingData.id)}
                            className="product-review text-primary align-items-sm-end"
                          >
                            Edit
                          </Link> */}

                          <div className="row">
                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                {/* <BookingDetailItem
                                  title={"Customer name"}
                                  value={bookingData.customer_name}
                                /> */}
                                <BookingDetailItem
                                  title={"Total Adults"}
                                  value={bookingData.total_pax}
                                />
                                <BookingDetailItem
                                  title={"Total Children"}
                                  value={bookingData.total_children}
                                />
                                <BookingDetailItem
                                  title={"Booking date"}
                                  value={new Date(
                                    bookingData.booking_date
                                  ).toLocaleString("en-IN")}
                                />
                                {/* <BookingDetailItem
                                  title={"Customer number"}
                                  value={bookingData.customer_number}
                                />*/}
                                <BookingDetailItem
                                  title={"Email"}
                                  value={bookingData.email}
                                />
                                {/* <BookingDetailItem
                            title={"Gain margin colour"}
                            value={bookingData.gain_margin_colour}
                          /> */}
                                {/* <BookingDetailItem
                            title={"User"}
                            value={bookingData.user}
                          /> */}
                                <BookingDetailItem
                                  title={"Status"}
                                  value={
                                    BOOKING_STATUS_LABEL[bookingData.status]
                                  }
                                />
                                <BookingDetailItem
                                  title={"Updated"}
                                  value={new Date(
                                    bookingData.updated
                                  ).toLocaleString("en-IN")}
                                />
                                {/* <BookingDetailItem
                                  title={"Created"}
                                  value={new Date(
                                    bookingData.created
                                  ).toLocaleString('en-IN')}
                                /> */}
                                <BookingDetailItem
                                  title={"Flight Tickets"}
                                  value={bookingData.flight_tickets}
                                  hr={false}
                                />
                                <BookingDetailItem
                                  title={"Travel agent"}
                                  value={bookingData.travel_agent?.name}
                                />
                                <BookingDetailItem
                                  title={"Customer Address"}
                                  value={bookingData.customer_address}
                                />
                                <BookingDetailItem
                                  title={"Customer GSTN"}
                                  value={bookingData.customer_gstn}
                                />
                              </ul>
                            </div>

                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                <BookingDetailItem
                                  title={"Total amount"}
                                  value={bookingData.total_amount}
                                />
                                <BookingDetailItem
                                  title={"Agent Commission"}
                                  value={bookingData.agent_commission}
                                />
                                <BookingDetailItem
                                  title={"Amount received"}
                                  value={bookingData.amount_received}
                                />
                                <BookingDetailItem
                                  title={"Gain Margin"}
                                  value={
                                    <span
                                      className={
                                        "badge badge-rounded badge-" +
                                        GainMarginCSSLabel[
                                          bookingData.gain_margin_colour
                                        ]
                                      }
                                    >
                                      {bookingData.gain_margin}
                                    </span>
                                  }
                                />
                                <BookingDetailItem
                                  title={"Profit"}
                                  value={bookingData.profit}
                                />
                                <BookingDetailItem
                                  title={"Next payment date"}
                                  value={
                                    bookingData.next_payment_date
                                      ? new Date(
                                          bookingData.next_payment_date
                                        ).toLocaleDateString("en-IN")
                                      : null
                                  }
                                  hr={false}
                                />
                                <BookingDetailItem
                                  title={"Notes"}
                                  value={bookingData.notes}
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : bookingViewStatus === "pending" ||
                  bookingViewStatus === "idle" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : bookingViewStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* review */}
        </div>
      </div>
      {bookingData ? (
        <>
          <ListBookingHotels
            bookingHotelData={bookingData.booking_hotels}
            setDeleteHotelId={setDeleteHotelId}
            bookingId={bookingId}
          />

          <ListBookingCabs
            bookingCabData={bookingData.bookingcab_set}
            setDeleteCabId={setDeleteCabId}
            bookingId={bookingId}
          />

          <ListBookingTransactions
            transactionsData={bookingData.booking_transactions}
            bookingData={bookingData}
            // setDeleteCabId={setDeleteCabId}
            bookingId={bookingId}
          />

          <EditBookingCanvas
            editId={editId}
            Title={"Edit Booking"}
            bookingData={bookingData}
            hide={() => setEditId("")}
          />
          <ConfirmationModal
            show={
              typeof deleteCabId !== "undefined" ||
              typeof deleteHotelId !== "undefined"
            }
            handleClose={cancelDelete}
            confirmClose={confirmDelete}
          />
        </>
      ) : null}
    </>
  );
};

export default ViewBooking;
