import React, { useCallback, useEffect, useState } from "react";
import { Link, redirect, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import BookingDetailItem from "../booking/BookingDetailItem";
// import { toast } from "react-toastify";
import BookingDetailItemIcon from "../booking/BookingDetailItemIcon";
import {
  resetCabVendorView,
  viewCabVendor,
} from "src/store/reducers/cabVendors";
import CabVendorFormCanvas from "./CabVendorFormCanvas";

const ViewCabVendor = () => {
  const dispatch = useAppDispatch();

  const { id: cabVendorId } = useParams();

  const cabVendorViewStatus = useAppSelector(
    (state) => state.cabVendors.view.status
  );
  const cabVendorData = useAppSelector((state) => state.cabVendors.view.data);
  const [editId, setEditId] = useState("");
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  // const [currentPage, setCurrentPage] = useState(0);
  // const sort = 5;

  useEffect(() => {
    if (!cabVendorId) {
      redirect("/cabVendors");
    }
  }, [cabVendorId]);

  const retry = useCallback(() => {
    dispatch(viewCabVendor(cabVendorId));
  }, [cabVendorId, dispatch]);

  useEffect(() => {
    if (cabVendorViewStatus === "idle") {
      retry();
    }
  }, [cabVendorViewStatus, retry]);

  useEffect(() => {
    if (cabVendorData?.id !== cabVendorId) {
      dispatch(resetCabVendorView());
    }
  }, [cabVendorData?.id, cabVendorId, dispatch]);

  return (
    <>
      {/* <MainPagetitle
        mainTitle="View Booking"
        pageTitle="View Booking"
        parentTitle="Home"
      /> */}
      <div className="container-fluid mh-auto">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {cabVendorViewStatus === "success" && cabVendorData ? (
                  <div className="row">
                    <div className="col-xl-3 col-lg-3  col-md-3 col-sm-3">
                      <div className="about-me">
                        <ul>
                          <BookingDetailItemIcon
                            title={"Contact person Name"}
                            icon={"user"}
                            value={cabVendorData.contact_person_name}
                          />
                          <BookingDetailItemIcon
                            icon={"phone"}
                            title={"Contact number"}
                            value={cabVendorData.contact_number}
                          />
                          <BookingDetailItemIcon
                            icon={"plane-arrival"}
                            title={"City"}
                            value={cabVendorData.city}
                          />
                          {/* <li>
                            <i className="fa-solid fa-book"></i>
                            <div>
                              <h6>Techno India NJR Institute</h6>
                            </div>
                          </li>
                          <li>
                            <i className="fa-solid fa-location-dot"></i>
                            <div>
                              <h6>Lived In Usa</h6>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                      <div className="d-flex mt-4 justify-content-end">
                        <Link
                          to={"#"}
                          onClick={() => setEditId(cabVendorData.id)}
                          className="modal-btn btn btn-primary btn-sm  ms-2"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          Edit
                        </Link>
                        {/* <button
                          type="button"
                          className="modal-btn btn btn-primary btn-sm  ms-2 "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          <span>Edit </span>
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9  col-md-9 col-sm-9">
                      <div className="product-detail-content">
                        <div className=" pr">
                          {/* <h4
                            className="heading mb-0"
                            style={{ wordWrap: "break-word" }}
                          >
                            View booking details
                          </h4>
                          <Link
                            to={"#"}
                            onClick={() => setEditId(cabVendorData.id)}
                            className="product-review text-primary align-items-sm-end"
                          >
                            Edit
                          </Link> */}

                          <div className="row">
                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                <BookingDetailItem
                                  title={"Company name"}
                                  value={cabVendorData.company_name}
                                />
                                <BookingDetailItem
                                  title={"Alternate number"}
                                  value={cabVendorData.alternate_number}
                                />
                                <BookingDetailItem
                                  title={"Email"}
                                  value={cabVendorData.email_id}
                                />
                                {/* <BookingDetailItem
                            title={"User"}
                            value={cabVendorData.user}
                          /> */}
                                <BookingDetailItem
                                  title={"Created"}
                                  value={new Date(
                                    cabVendorData.created_date
                                  ).toLocaleString('en-IN')}
                                />
                                <BookingDetailItem
                                  title={"Updated"}
                                  value={new Date(
                                    cabVendorData.updated_date
                                  ).toLocaleString('en-IN')}
                                />
                              </ul>
                            </div>

                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                <BookingDetailItem
                                  title={"Notes"}
                                  value={cabVendorData.notes}
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : cabVendorViewStatus === "pending" ||
                  cabVendorViewStatus === "idle" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : cabVendorViewStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* review */}
        </div>
      </div>
      {cabVendorData ? (
        <>
          <CabVendorFormCanvas
            show={editId !== ""}
            hide={() => setEditId("")}
            editId={editId}
            Title={editId === "Add" ? "Add CabVendor" : "Edit CabVendor"}
            cabVendorData={cabVendorData}
          />
        </>
      ) : null}
    </>
  );
};

export default ViewCabVendor;
