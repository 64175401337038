import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  BookingSearchOption,
  fetchBookings,
} from "src/store/reducers/bookings";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import AddBookingCanvas from "./AddBookingCanvas";
import AddTransaction from "./AddTransaction";
import { GainMarginCSSLabel } from "src/utils/constants";
import BottomPagination from "src/jsx/customComponents/pagination/BottomPagination";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
// import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { fetchListDrivers } from "src/store/reducers/drivers";
import { fetchListCabVendors } from "src/store/reducers/cabVendors";
import { fetchListAgents } from "src/store/reducers/agents";
import { fetchListHotels } from "src/store/reducers/hotels";
import { fetchUsers } from "src/store/reducers/users";
import { fetchListTaxis } from "src/store/reducers/taxis";
// import Select from "react-select";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

const Bookings = () => {
  const dispatch = useAppDispatch();
  const bookingList = useAppSelector((state) => state.bookings.data);
  const bookingListStatus = useAppSelector((state) => state.bookings.status);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addTransationId, setAddTransationId] = useState("");
  const [vendorArr, setVendorArr] = useState<
    { label: string; value: string }[]
  >([]);
  const [userArr, setUserArr] = useState<{ label: string; value: string }[]>(
    []
  );
  const [bookingFilter, setBookingFilter] = useState<BookingSearchOption>({});
  const [pending, setPending] = useState(false);
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  // const sort = 5;

  const GroupSelection = useMemo(
    () =>
      [
        { id: "driver_id", name: "Driver" },
        { id: "taxi_id", name: "Taxi" },
        { id: "travel_agent_id", name: "Travel Agent" },
        { id: "cab_vendor_id", name: "Cab Vendor" },
        { id: "hotel_id", name: "Hotel" },
        // { id: "user_id", name: "User" },
      ] as const,
    []
  );

  const ProfitLossSelection = useMemo(
    () =>
      [
        { id: "red", name: "Loss" },
        { id: "green", name: "Profit" },
      ] as const,
    []
  );

  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    // touched,
    // errors,
  } = useFormik({
    initialValues: {
      start_date: bookingFilter?.start_date,
      end_date: bookingFilter?.end_date,
      filter_type: bookingFilter?.filter_type,
      keyword: bookingFilter?.keyword,
      user_id: bookingFilter?.user_id,
      gain_margin_colour: bookingFilter?.gain_margin_colour,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      start_date: Yup.string(),
      end_date: Yup.string(),
      filter_type: Yup.string(),
      keyword: Yup.string(),
      user_id: Yup.string(),
      gain_margin_colour: Yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // const finalValues: BookingDataForm = { ...values };
      setPending(true);
      // nullValueKeysBooking.forEach((k) => {
      //   if (!finalValues[k]) {
      //     finalValues[k] = null;
      //   }
      // });

      Object.keys(values).forEach((k) => {
        if (typeof values[k] === "undefined") {
          delete values[k];
        }
      });
      switch (values?.select_group) {
        case "taxi_id":
        case "driver_id":
        case "travel_agent_id":
        case "cab_vendor_id":
        case "hotel_id":
          // case "user_id":
          setVendorFieldData(values, values?.select_group, values.group_id);
          break;
        default:
          setVendorFieldData(values, "group_id", values.group_id);
          delete values.group_id;
          delete values.select_group;
          break;
      }

      setSearchFormData(values);
    },
  });

  const setVendorFieldData = useCallback(
    (values, vendor, group_id: string) => {
      values[vendor] = group_id;
      GroupSelection.forEach(({ id }) => {
        if (id !== vendor) {
          delete values[id];
        }
      });
    },
    [GroupSelection]
  );

  const resetFilterData = useCallback(() => {
    setBookingFilter({});
    setVendorFieldData(values, "group_id", values.group_id);
    delete values.group_id;
    delete values.select_group;
    resetForm();
  }, [resetForm, setVendorFieldData, values]);

  const setSearchFormData = useCallback(async (values: BookingSearchOption) => {
    if (
      typeof values.filter_type === "undefined" ||
      values.filter_type === ""
    ) {
      delete values.start_date;
      delete values.end_date;
      delete values.filter_type;
    }

    setBookingFilter(values);
    setPage(1);
  }, []);

  const retry = useCallback(() => {
    dispatch(fetchBookings({ page, filter: bookingFilter }));
    setPending(false);
  }, [bookingFilter, dispatch, page]);

  // useEffect(() => {
  //   if (bookingListStatus === "idle") {
  //     retry();
  //   }
  // }, [retry, bookingListStatus]);

  // const setPageAndFetch = useCallback((page: number) => {
  //   setPage(page);
  // }, []);

  useEffect(() => {
    retry();
  }, [retry]);

  const fetchVendorDataArr = useCallback(
    async (fetchMethod: any, label = "name", value = "id") => {
      try {
        const data = await dispatch(fetchMethod()).unwrap();
        setVendorArr(
          data.map((vendor: any) => ({
            label: vendor[label],
            value: vendor[value],
          }))
        );
      } catch (error: any) {
        // FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        // setPending(false);
      }
    },
    [dispatch]
  );

  const fetchUserDataArr = useCallback(
    async (fetchMethod: any, label = "name", value = "id") => {
      try {
        const data = await dispatch(fetchMethod()).unwrap();
        setUserArr(
          data.map((vendor: any) => ({
            label: vendor[label],
            value: vendor[value],
          }))
        );
      } catch (error: any) {
        // FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        // setPending(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async function () {
      switch (values?.select_group) {
        case "driver_id":
          await fetchVendorDataArr(fetchListDrivers, "name", "id");
          break;
        case "taxi_id":
          await fetchVendorDataArr(fetchListTaxis, "vehicle_name", "id");
          break;
        case "travel_agent_id":
          await fetchVendorDataArr(fetchListAgents, "name", "id");
          break;
        case "cab_vendor_id":
          await fetchVendorDataArr(
            fetchListCabVendors,
            "contact_person_name",
            "id"
          );
          break;
        case "hotel_id":
          await fetchVendorDataArr(fetchListHotels, "name", "id");
          break;
        // case "user_id":
        //   break;
        default:
          setVendorArr([]);
          break;
      }
      await fetchUserDataArr(fetchUsers, "first_name", "uuid");
    })();
  }, [dispatch, fetchUserDataArr, fetchVendorDataArr, values?.select_group]);

  // User
  // Earning
  // useEffect(() => console.log(bookingFilter));

  return (
    <>
      {/* <MainPagetitle
        mainTitle="Bookings"
        pageTitle="Bookings"
        parentTitle="Home"
      /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              {/* <div className="card-header">
                <h4 className="heading mb-0">Filter</h4>
              </div> */}
              <form onSubmit={handleSubmit}>
                <div className="row container-fluid">
                  {/* <div className="col-xl-3 mb-3">
                    // <CustomInputWithLabel
                      id="keyword"
                      placeholder={"Search"}
                      handleChange={handleChange}
                      // labelContent={{ text: "End Date", required: true }}
                      // handleChange={handleChange}
                      value={values.keyword}
                      // touched={touched.end_date}
                      // errors={errors.end_date}
                    />
                  </div> */}
                  <div className="col-xl-3 mb-3">
                    <CustomSelectWithLabel
                      id="select_group"
                      // placeholder={"Select User"}
                      // labelContent={{ text: "Select User" }}
                      handleChange={handleChange}
                      value={values.select_group}
                      // touched={touched.select_group}
                      // errors={errors.select_group}
                    >
                      <option>Select Vendor</option>
                      {GroupSelection.map((agent) => (
                        <option value={agent.id} key={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </CustomSelectWithLabel>
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomReactSelectWithLabel
                      placeholder={
                        GroupSelection.find(
                          ({ id }) => values.select_group === id
                        )?.name ?? "Vendor"
                      }
                      id={"group_id"}
                      options={vendorArr}
                      handleChange={handleChange}
                      value={
                        vendorArr.find(
                          (user) => user.value === values.group_id
                        ) || ""
                      }
                    />
                    {/* <CustomSelectWithLabel
                      id="group_id"
                      // placeholder={"Select User"}
                      // labelContent={{ text: "Select User" }}
                      handleChange={handleChange}
                      value={values.group_id}
                      // touched={touched.group_id}
                      // errors={errors.group_id}
                    >
                      <option>
                        Select{" "}
                        {GroupSelection.find(
                          ({ id }) => values.select_group === id
                        )?.name ?? "Vendor"}
                      </option>
                      {vendorArr.map((agent) => (
                        <option value={agent.value} key={agent.value}>
                          {agent.label}
                        </option>
                      ))}
                    </CustomSelectWithLabel> */}
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomSelectWithLabel
                      id="gain_margin_colour"
                      // placeholder={"Select User"}
                      // labelContent={{ text: "Select User" }}
                      handleChange={handleChange}
                      value={values.gain_margin_colour}
                      // touched={touched.select_group}
                      // errors={errors.select_group}
                    >
                      <option>Select P/L</option>
                      {ProfitLossSelection.map((agent) => (
                        <option value={agent.id} key={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </CustomSelectWithLabel>
                  </div>

                  <div className="col-xl-3 mb-3">
                    <CustomReactSelectWithLabel
                      placeholder="User"
                      id={"user_id"}
                      options={userArr}
                      handleChange={handleChange}
                      value={
                        userArr.find((user) => user.value === values.user_id) ||
                        ""
                      }
                    />
                    {/* <Select
                      placeholder="Select User"
                      id="user_id"
                      options={userArr}
                      value={
                        userArr.filter(
                          (user) => user.value === values.user_id
                        )?.[0] || ""
                      }
                      onChange={(e) =>
                        handleChange({
                          target: { name: "user_id", value: e?.value },
                        })
                      }
                    /> */}

                    {/* // <CustomSelectWithLabel
                    //   id="user_id"
                    //   handleChange={handleChange}
                    //   value={values.user_id}
                    // >
                    //   <option>Select User</option>
                    //   {userArr.map((agent) => (
                    //     <option value={agent.value} key={agent.value}>
                    //       {agent.label}
                    //     </option>
                    //   ))}
                    // </CustomSelectWithLabel> */}
                  </div>
                </div>
                <div className="row container-fluid">
                  <div className="col-xl-3 mb-3">
                    <CustomSelectWithLabel
                      id="filter_type"
                      // placeholder={"Select User"}
                      // labelContent={{ text: "Select User" }}
                      handleChange={handleChange}
                      value={values.filter_type}
                      // touched={touched.filter_type}
                      // errors={errors.filter_type}
                    >
                      <option value={""}>Select Range type</option>
                      {[
                        { id: "arrival_date", name: "Arrival Date" },
                        { id: "booking_date", name: "Booking Date" },
                      ].map((agent) => (
                        <option value={agent.id} key={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </CustomSelectWithLabel>
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="start_date"
                      placeholder={"Start Date"}
                      handleChange={(date) => setFieldValue("start_date", date)}
                      // labelContent={{ text: "Start Date", required: true }}
                      // handleChange={handleChange}
                      value={values.start_date}
                      // touched={touched.start_date}
                      // errors={errors.start_date}
                      selectsStart
                      endDate={values.end_date}
                      // startDate={values.start_date}
                    />{" "}
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="end_date"
                      placeholder={"End Date"}
                      handleChange={(date) => setFieldValue("end_date", date)}
                      // labelContent={{ text: "End Date", required: true }}
                      // handleChange={handleChange}
                      value={values.end_date}
                      // touched={touched.end_date}
                      // errors={errors.end_date}
                      selectsEnd
                      startDate={values.start_date}
                      // endDate={values.end_date}
                    />
                  </div>
                  <div className="col-xl-1 mb-3">
                    <Button
                      className="form-control"
                      title="Clear Search Filters"
                      disabled={Object.values(bookingFilter).length === 0}
                      onClick={resetFilterData}
                      // type="submit"
                    >
                      {Object.values(bookingFilter).length === 0 ? "X" : "X"}
                    </Button>
                  </div>
                  <div className="col-xl-2 mb-3">
                    {/* <Form.Label>&nbsp;</Form.Label> */}
                    <Button
                      className="form-control"
                      disabled={pending}
                      type="submit"
                    >
                      {pending ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Bookings</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setShowAddModal(true)}
                >
                  + Add Booking
                </Link>
              </div>
              <div className="card-body ">
                {bookingListStatus === "success" ? (
                  bookingList?.results && bookingList?.results?.length > 0 ? (
                    <div className="table-responsive active-projects">
                      <div
                        id="projects-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="projects-tbl"
                          className="table table-hover dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Contact Number</th>
                              <th>Booking Date</th>
                              <th>Arrival Date</th>
                              <th>Total Amount</th>
                              {/* <th>Cab driver</th> */}
                              <th>Margin</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookingList?.results?.map((bookingData) => (
                              <tr key={bookingData.id}>
                                <td>{bookingData.customer_name}</td>
                                <td>{bookingData.customer_number}</td>
                                <td>
                                  {new Date(
                                    bookingData.booking_date
                                  ).toLocaleDateString('en-IN')}
                                </td>
                                <td>
                                  {new Date(
                                    bookingData.arrival_date
                                  ).toLocaleDateString('en-IN')}
                                </td>
                                <td>{bookingData.total_amount}</td>
                                {/* <td>
                                  {bookingData.bookingcab_set?.[0]?.driver?.name
                                    ? bookingData.bookingcab_set?.[0].driver
                                        ?.name
                                    : null}
                                </td> */}
                                <td>
                                  <span
                                    className={
                                      "badge badge-rounded badge-" +
                                      GainMarginCSSLabel[
                                        bookingData.gain_margin_colour
                                      ]
                                    }
                                  >
                                    {bookingData.gain_margin}
                                  </span>
                                </td>
                                <td>
                                  <NavLink
                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                    to={`/booking-view/${bookingData.id}`}
                                    target="_blank"
                                    title="View"
                                  >
                                    <i className={`fas fa-paper-plane`} />
                                    {/* <span>View</span> */}
                                  </NavLink>{" "}
                                  <NavLink
                                    className="btn btn-success shadow btn-xs sharp"
                                    onClick={() =>
                                      setAddTransationId(bookingData.id)
                                    }
                                    to={"#"}
                                    title="Add Transaction"
                                  >
                                    <i className={`fas fa-plus`} />
                                    {/* <span>Add Transaction</span> */}
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <BottomPagination
                          count={bookingList?.count}
                          page={page}
                          setPageAndFetch={setPage}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>NA</strong>
                    </div>
                  )
                ) : bookingListStatus === "pending" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : bookingListStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddBookingCanvas
        show={showAddModal}
        setShow={setShowAddModal}
        Title="Add Booking"
      />
      <AddTransaction
        bookingId={addTransationId}
        bookingData={bookingList?.results?.find(
          (bookingData) => bookingData.id === addTransationId
        )}
        hide={() => setAddTransationId("")}
        Title="Add Transaction"
      />
    </>
  );
};

export default Bookings;
