import { configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/auth";
import users from "./reducers/users";
import bookings from "./reducers/bookings";
import reports from "./reducers/reports";
import agents from "./reducers/agents";
import drivers from "./reducers/drivers";
import hotels from "./reducers/hotels";
import cabVendors from "./reducers/cabVendors";
import taxis from "./reducers/taxis";
import settings from "./reducers/settings";

const store = configureStore({
  reducer: {
    auth: auth,
    users: users,
    bookings: bookings,
    reports: reports,
    agents: agents,
    drivers: drivers,
    hotels: hotels,
    cabVendors: cabVendors,
    taxis: taxis,
    settings: settings,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
