import { ReactElement, ReactNode } from "react";
import { Form } from "react-bootstrap";
import FormErrorFeedback from "./validations/FormErrorFeedback";

interface CustomSelectWithLabelProps {
  id: string;
  required?: boolean;
  labelText?: string;
  value?: string | number;
  touched?: boolean;
  errors?: string | string[];
  children?: ReactElement | ReactNode;
  handleChange: (e: React.ChangeEvent<any>) => void;
}
const CustomSelectWithLabel = ({
  id,
  labelText,
  required,
  handleChange,
  value = "",
  touched,
  errors,
  children,
}: CustomSelectWithLabelProps) => (
  <>
    {labelText ? (
      <Form.Label htmlFor={id}>
        {labelText}
        {required ? <span className="text-danger">*</span> : null}
      </Form.Label>
    ) : null}
    <Form.Select
      className="form-control"
      aria-label={labelText}
      id={id}
      value={value}
      onChange={handleChange}
      isValid={touched && !errors}
      isInvalid={touched && !!errors}
    >
      {children}
    </Form.Select>
    {touched && errors ? (
      <FormErrorFeedback id={id} label={labelText} errors={errors} />
    ) : null}
  </>
);

export default CustomSelectWithLabel;
