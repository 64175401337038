import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import { APIResponseCover } from "./auth.d";
import { readAPIErrorMessage } from "src/utils/errorUtil";
import { API_STATUS, UserRole } from "src/utils/constants";

export interface SettingDataForm {
  company_name: string;
  address: string;
  contact_number: string;
  email_id: string;
  gst_number: string;
  logo: any;
  inclusions: string;
  exclusions: string;
  cancellation_policy: string;
  terms_and_conditions: string;
  gain_margin: string;
  gst: string;
  pan_number: string;
  tan_number: string;
  website: string;
  alternate_number: string;
  email_notifications: 0 | 1;
  // logo: string;
}

export interface SettingsState {
  data?: SettingDataForm;
  status: API_STATUS;
}

const initialState: SettingsState = {
  data: undefined,
  status: "idle",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // setAuthentication: (state: any, { payload }: any) => {
    //   state.authentication = payload;
    // },
    refreshSetting: (state) => {
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSetting.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchSetting.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchSetting.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(setSetting.fulfilled, (state, action) => {
      state.status = "idle";
    });
  },
});

export const fetchSetting = createAsyncThunk<
  SettingDataForm,
  void,
  { state: RootState }
>("settings/fetch", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<SettingDataForm>,
      AxiosResponse<APIResponseCover<SettingDataForm>>
    >("CompanySettings/?format=json");
    console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const setSetting = createAsyncThunk<
  { status: string },
  SettingDataForm,
  { state: RootState }
>("settings/add", async (data, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, SettingDataForm>,
      SettingDataForm
    >("CompanySettings/", data,  { "headers" : {
      "Content-Type": "multipart/form-data",
    }});
    console.log(data);
    console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshSetting } = settingsSlice.actions;

export default settingsSlice.reducer;
