// import React from react;

const BookingByteIcon = () => {
  return (
    <svg
      id="icon-layer"
      data-name="Icon"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 330.76 330.76"
    >
      <defs>
        {/* <style>
      .cls-1 {
        fill: url(#linear-gradient);
        stroke-width: 0px;
      }
    </style> */}
        <linearGradient
          id="linear-gradientIcon"
          x1="2.74"
          y1="165.38"
          x2="328.02"
          y2="165.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#575ca8" />
          <stop offset=".54" stopColor="#ae3693" />
          <stop offset="1" stopColor="#f27238" />
        </linearGradient>
      </defs>
      <path
        fill="url(#linear-gradientIcon)"
        strokeWidth="0px"
        d="m242.33,2.74H88.43C41.3,2.74,2.74,41.3,2.74,88.43v153.9c0,47.13,38.56,85.69,85.69,85.69h153.9c47.13,0,85.69-38.56,85.69-85.69V88.43c0-47.13-38.56-85.69-85.69-85.69Zm-110.17,253.05h-.36c-.33.34-.68.67-1.04.98-2.47,2.12-5.7,3.4-9.22,3.4-4.04,0-7.68-1.69-10.27-4.38h-15.34c-10.84,0-20.91-4.86-27.59-13.31-12.15-15.36-17.05-32.91-13.79-49.42,2.89-14.73,11.97-27.47,24.93-35.12-9.81-9.06-15.41-21.81-15.41-35.15s4.98-24.73,14.05-33.78c9.02-9.06,21.02-14.03,33.75-14.03h68.18c4.59,0,8.3,3.71,8.3,8.3v104.52c3.33,2.62,5.48,6.67,5.48,11.24s-1.96,8.25-5.05,10.87c-2.47,2.12-5.7,3.4-9.22,3.4-7.89,0-14.27-6.4-14.27-14.27,0-3.25,1.1-6.22,2.92-8.62-.02-.35-.05-.7-.05-1.05v-94.48h-56.29c-7.43,0-14.4,2.9-19.69,8.19-5.31,5.32-8.2,12.28-8.2,19.67,0,.31,0,.6.01.9.26,8.88,4.76,17.61,12.61,24.59,1.7,1.49,3.9,2.35,6.25,2.44h1.25c5.51,0,10.5,2.23,14.11,5.84,3.61,3.61,5.84,8.6,5.84,14.11h-27.48c-4.23,0-8.33,1.83-11.24,4.99-11.62,12.7-14.95,27.56-9.13,40.75,5.22,11.86,16.95,19.52,29.89,19.52h5.31c.53-.53,1.08-1.04,1.69-1.49,2.37-1.75,5.29-2.77,8.45-2.77,7.87,0,14.27,6.4,14.27,14.28,0,3.64-1.39,6.96-3.65,9.48v.4Zm129.14-15.28c-9.7,9.44-23.05,15.28-37.7,15.28h-74.94c-4.58,0-8.3-3.71-8.3-8.29v-126.28c5.58,0,10.62,2.25,14.28,5.91,3.66,3.66,5.91,8.69,5.91,14.27v94.48h64.12c8.95,0,17.29-3.55,23.5-10,6.22-6.46,9.44-14.93,9.11-23.87-.64-17.33-15.63-31.39-33.42-31.39h-27.18c0-5.51,2.23-10.5,5.84-14.11,3.6-3.61,8.59-5.84,14.1-5.84h2.47c14.76-.45,26.8-11.82,27.65-26.22.48-8.03-2.42-15.61-8.16-21.37-5.29-5.29-12.26-8.19-19.69-8.19h-2.08c-5.11,0-9.93-2-13.53-5.62-3.08-3.08-4.99-7.01-5.48-11.27-.09-.74-.13-1.5-.13-2.25v-.76h21.23c12.74,0,24.73,4.98,33.75,14.03,9.07,9.05,14.05,21.04,14.05,33.78s-5.6,26.1-15.41,35.15c16.18,9.56,26.08,26.99,25.92,45.77-.12,14.36-6.2,27.38-15.9,36.8Z"
      />
    </svg>
  );
};

export default BookingByteIcon;
