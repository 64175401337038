import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/store/hooks";
import Home from "../components/Dashboard/Home";
// import DashboardDark from "../components/Dashboard/DashboardDark";
// import Performance from "../components/Dashboard/Performance";
// import Projects from "../components/Dashboard/Projects";
// import TaskSummary from "../components/Dashboard/TaskSummary";
// import HomeBlog from "../components/Dashboard/Blog";
// import ManageClient from "../components/Dashboard/ManageClient";
// import Report from "../components/Dashboard/Report";
// import Finance from "../components/Dashboard/Finance";
import Employees from "../../layouts/modules/user/Employees";
// import Task from "../components/Dashboard/Task";
// import CoreHr from "../components/Dashboard/CoreHr";

// //Update Pages
// import SvgIcons from "../components/Dashboard/SvgIcons";

// //Apps
// import Contacts from "../components/AppsMenu/Contacts";
// import User from "../components/AppsMenu/AppProfile/User";
// import UserRoles from "../components/AppsMenu/AppProfile/UserRoles";
// import AddRole from "../components/AppsMenu/AppProfile/AddRole";
// import AppProfile from "../components/AppsMenu/AppProfile/AppProfile";
// import AppProfile2 from "../components/AppsMenu/AppProfile/AppProfile2";
// import EditProfile from "../components/AppsMenu/AppProfile/EditProfile";
// import PostDetails from "../components/AppsMenu/AppProfile/PostDetails";
// import CustomerProfile from "../components/AppsMenu/AppProfile/CustomerProfile";
// import AppCustomer from "../components/AppsMenu/AppProfile/AppCustomer";
// import Compose from "../components/AppsMenu/Email/Compose/Compose";
// import Inbox from "../components/AppsMenu/Email/Inbox/Inbox";
// import Read from "../components/AppsMenu/Email/Read/Read";
// import Calendar from "../components/AppsMenu/Calendar/Calendar";

// //CMS
// import Content from "../components/Cms/Content";
// import Menu from "../components/Cms/Menu";
// import EmailTemplate from "../components/Cms/EmailTemplate";
// import CmsBlog from "../components/Cms/Blog";
// import ContentAdd from "../components/Cms/ContentAdd";
// import AddMail from "../components/Cms/AddMail";
// import AddBlog from "../components/Cms/AddBlog";
// import BlogCategory from "../components/Cms/BlogCategory";

// //Aikit pages
// import AutoWriter from "../components/Aikit/AutoWriter";
// import Scheduler from "../components/Aikit/Scheduler";
// import Repurpose from "../components/Aikit/Repurpose";
// import RSS from "../components/Aikit/Rss";
// import Chatbot from "../components/Aikit/Chatbot";
// import FineTuneModels from "../components/Aikit/FineTuneModels";
// import AIMenu from "../components/Aikit/AIMenu";
// import Settings from "../components/Aikit/Settings";
// import ImportExport from "../components/Aikit/ImportExport";

// /// Product List
// import ProductGrid from "../components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "../components/AppsMenu/Shop/ProductList/ProductList";
// import ProductDetail from "../components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import ProductOrder from "../components/AppsMenu/Shop/ProductOrder";
// import Checkout from "../components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "../components/AppsMenu/Shop/Invoice/Invoice";
// import Customers from "../components/AppsMenu/Shop/Customers/Customers";

// /// Charts
// import SparklineChart from "../components/charts/Sparkline";
// import ChartJs from "../components/charts/Chartjs";
// import RechartJs from "../components/charts/rechart";
// import ApexChart from "../components/charts/apexcharts";

// /// Bootstrap
// import UiAccordion from "../components/bootstrap/Accordion";
// import UiAlert from "../components/bootstrap/Alert";
// import UiBadge from "../components/bootstrap/Badge";
// import UiButton from "../components/bootstrap/Button";
// import UiModal from "../components/bootstrap/Modal";
// import UiButtonGroup from "../components/bootstrap/ButtonGroup";
// import UiListGroup from "../components/bootstrap/ListGroup";
// import UiCards from "../components/bootstrap/Cards";
// import UiCarousel from "../components/bootstrap/Carousel";
// import UiDropDown from "../components/bootstrap/DropDown";
// import UiPopOver from "../components/bootstrap/PopOver";
// import UiProgressBar from "../components/bootstrap/ProgressBar";
// import UiTab from "../components/bootstrap/Tab";
// import UiPagination from "../components/bootstrap/Pagination";
// import UiGrid from "../components/bootstrap/Grid";
// import UiTypography from "../components/bootstrap/Typography";

// /// Plugins
// import Select2 from "../components/PluginsMenu/Select2/Select2";
// import MainSweetAlert from "../components/PluginsMenu/SweetAlert/SweetAlert";
// import Toastr from "../components/PluginsMenu/Toastr/Toastr";
// import JqvMap from "../components/PluginsMenu/JqvMap/JqvMap";
// import Lightgallery from "../components/PluginsMenu/Lightgallery/Lightgallery";
// // Widget
// // import Widget from "./pages/Widget";
// /// Table
// import SortingTable from "../components/table/SortingTable/SortingTable";
// import FilteringTable from "../components/table/FilteringTable/FilteringTable";
// import BootstrapTable from "../components/table/BootstrapTable";
// /// Form
// import Element from "../components/Forms/Element/Element";
// import Wizard from "../components/Forms/Wizard/Wizard";
// import CkEditor from "../components/Forms/CkEditor/CkEditor";
// import Pickers from "../components/Forms/Pickers/Pickers";
// import FormValidation from "../components/Forms/FormValidation/FormValidation";

// // import { ThemeContext } from "../context/ThemeContext";
// import DataTable from "../components/table/DataTable";
// import Todo from "../pages/Todo";
// import Widget from "../pages/Widget";
import Bookings from "src/layouts/modules/booking/Bookings";
import BookingCalendar from "src/layouts/modules/booking/BookingCalendar";
import ViewBooking from "src/layouts/modules/booking/ViewBooking";
import Report from "src/layouts/modules/reports/Report";
import ListAgents from "src/layouts/modules/agents/ListAgents";
import ListTaxis from "src/layouts/modules/taxis/ListTaxis";
import ListDrivers from "src/layouts/modules/drivers/ListDrivers";
import ListHotels from "src/layouts/modules/hotels/ListHotels";
import ListCabVendors from "src/layouts/modules/cab_vendors/ListCabVendors";
import CompanySettingForm from "src/layouts/modules/settings/CompanySettingForm";
import ViewHotel from "src/layouts/modules/hotels/ViewHotel";
import ViewDriver from "src/layouts/modules/drivers/ViewDriver";
import ViewAgent from "src/layouts/modules/agents/ViewAgent";
import ViewCabVendor from "src/layouts/modules/cab_vendors/ViewCabVendor";
import ViewTaxi from "src/layouts/modules/taxis/ViewTaxi";

export const allroutes = [
  // Dashboard
  { url: "dashboard", component: <Home /> },
  // { url: "dashboard-dark", component: <DashboardDark /> },
  // { url: "core-hr", component: <CoreHr /> },
  // { url: "performance", component: <Performance /> },
  // { url: "project", component: <Projects /> },
  // { url: "task-summary", component: <TaskSummary /> },
  // { url: "blog-1", component: <HomeBlog /> },
  // { url: "manage-client", component: <ManageClient /> },
  // { url: "reports", component: <Report /> },
  // { url: "finance", component: <Finance /> },
  { url: "employee", component: <Employees /> },
  { url: "reports", component: <Report /> },
  { url: "bookings", component: <Bookings /> },
  { url: "bookings-calendar", component: <BookingCalendar /> },
  { url: "booking-view/:id", component: <ViewBooking /> },
  { url: "agents", component: <ListAgents /> },
  { url: "agents/view/:id", component: <ViewAgent /> },
  { url: "taxis", component: <ListTaxis /> },
  { url: "taxis/view/:id", component: <ViewTaxi /> },
  { url: "drivers", component: <ListDrivers /> },
  { url: "drivers/view/:id", component: <ViewDriver /> },
  { url: "hotels", component: <ListHotels /> },
  { url: "hotels/view/:id", component: <ViewHotel /> },
  { url: "cab-vendors", component: <ListCabVendors /> },
  { url: "cab-vendors/view/:id", component: <ViewCabVendor /> },
  { url: "settings", component: <CompanySettingForm /> },

  // { url: "task", component: <Task /> },

  // //Update Pages
  // { url: "svg-icon", component: <SvgIcons /> },

  ///Cms
  // { url: "content", component: <Content /> },
  // { url: "menu", component: <Menu /> },
  // { url: "email-template", component: <EmailTemplate /> },
  // { url: "blog", component: <CmsBlog /> },
  // { url: "add-content", component: <ContentAdd /> },
  // { url: "add-email", component: <AddMail /> },
  // { url: "add-blog", component: <AddBlog /> },
  // { url: "blog-category", component: <BlogCategory /> },

  ///AiKit
  // { url: "auto-write", component: <AutoWriter /> },
  // { url: "scheduled", component: <Scheduler /> },
  // { url: "repurpose", component: <Repurpose /> },
  // { url: "rss", component: <RSS /> },
  // { url: "chatbot", component: <Chatbot /> },
  // { url: "fine-tune-models", component: <FineTuneModels /> },
  // { url: "prompt", component: <AIMenu /> },
  // { url: "setting", component: <Settings /> },
  // { url: "import", component: <ImportExport /> },

  //Apps
  // { url: "contacts", component: <Contacts /> },
  // { url: "user", component: <User /> },
  // { url: "user-roles", component: <UserRoles /> },
  // { url: "add-role", component: <AddRole /> },
  // { url: "app-profile", component: <AppProfile /> },
  // { url: "app-profile-2", component: <AppProfile2 /> },
  // { url: "edit-profile", component: <EditProfile /> },
  // { url: "post-details", component: <PostDetails /> },
  // { url: "customer", component: <AppCustomer /> },
  // { url: "customer-profile", component: <CustomerProfile /> },

  // Apps
  // { url: "email-compose", component: <Compose /> },
  // { url: "email-inbox", component: <Inbox /> },
  // { url: "email-read", component: <Read /> },
  // { url: "app-calender", component: <Calendar /> },

  // Shop
  // { url: "ecom-product-grid", component: <ProductGrid /> },
  // { url: "ecom-product-list", component: <ProductList /> },
  // { url: "ecom-product-detail", component: <ProductDetail /> },
  // { url: "ecom-product-order", component: <ProductOrder /> },
  // { url: "ecom-checkout", component: <Checkout /> },
  // { url: "ecom-invoice", component: <Invoice /> },
  // { url: "ecom-customers", component: <Customers /> },
  // Chart
  // { url: "chart-sparkline", component: <SparklineChart /> },
  // { url: "chart-chartjs", component: <ChartJs /> },
  // { url: "chart-apexchart", component: <ApexChart /> },
  // { url: "chart-rechart", component: <RechartJs /> },
  // Bootstrap
  // { url: "ui-accordion", component: <UiAccordion /> },
  // { url: "ui-alert", component: <UiAlert /> },
  // { url: "ui-badge", component: <UiBadge /> },
  // { url: "ui-button", component: <UiButton /> },
  // { url: "ui-modal", component: <UiModal /> },
  // { url: "ui-button-group", component: <UiButtonGroup /> },
  // { url: "ui-list-group", component: <UiListGroup /> },
  // { url: "ui-card", component: <UiCards /> },
  // { url: "ui-carousel", component: <UiCarousel /> },
  // { url: "ui-dropdown", component: <UiDropDown /> },
  // { url: "ui-popover", component: <UiPopOver /> },
  // { url: "ui-progressbar", component: <UiProgressBar /> },
  // { url: "ui-tab", component: <UiTab /> },
  // { url: "ui-pagination", component: <UiPagination /> },
  // { url: "ui-typography", component: <UiTypography /> },
  // { url: "ui-grid", component: <UiGrid /> },
  // Plugin
  // { url: "uc-select2", component: <Select2 /> },
  // { url: "uc-sweetalert", component: <MainSweetAlert /> },
  // { url: "uc-toastr", component: <Toastr /> },
  // { url: "map-jqvmap", component: <JqvMap /> },
  // { url: "uc-lightgallery", component: <Lightgallery /> },
  // Widget
  // { url: "widget-basic", component: <Widget /> },
  // Form
  // { url: "form-element", component: <Element /> },
  // { url: "form-wizard", component: <Wizard /> },
  // { url: "form-ckeditor", component: <CkEditor /> },
  // { url: "form-pickers", component: <Pickers /> },
  // { url: "form-validation", component: <FormValidation /> },

  // table
  // { url: "table-filtering", component: <FilteringTable /> },
  // { url: "table-sorting", component: <SortingTable /> },
  // { url: "table-bootstrap-basic", component: <BootstrapTable /> },
  // { url: "table-data-table", component: <DataTable /> },
  // { url: "todo", component: <Todo /> },
];

const PrivateRoute = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.data);
  return !!isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
