import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT } from "src/utils/constants";

// export interface AgentViewData {
//   id: string;
// }

export interface AgentEditDataForm extends AgentDataForm {
  id: string;
}

export interface AgentDataForm {
  name: string;
  address: string;
  // pincode: string;
  city: string;
  // country: string;
  // experience: string;
  contact_number: string;
  email_id: string;
  alternate_number: string | null;
  employees_size: string;
  notes: string;
  company_name: string;
  // status: string;
}

export interface AgentData {
  id: string;
  name: string;
  address: string;
  // pincode: number;
  city: string;
  // country: string;
  // experience: string;
  contact_number: string;
  email_id: string;
  alternate_number: string;
  employees_size: string;
  notes: string;
  company_name: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
}

export interface AgentsState {
  data?: Pagination<AgentData>;
  status: API_STATUS;
  list: {
    data: AgentData[];
    status: API_STATUS;
  };
  view: {
    data?: AgentData;
    status: API_STATUS;
  };
}

const initialState: AgentsState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  list: {
    data: [],
    status: "idle",
  },
  view: {
    data: undefined,
    status: "idle",
  },
  status: "idle",
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    refreshAgentList: (state) => {
      state.status = "idle";
    },
    resetAgentView(state) {
      state.view.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAgents.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchAgents.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchAgents.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchListAgents.pending, (state, action) => {
      state.list.status = "pending";
    });
    builder.addCase(fetchListAgents.fulfilled, (state, action) => {
      state.list.status = "success";
      state.list.data = action.payload;
    });
    builder.addCase(fetchListAgents.rejected, (state, action) => {
      state.list.status = "error";
    });
    builder.addCase(addAgent.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(viewAgent.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(viewAgent.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(viewAgent.rejected, (state, action) => {
      state.view.status = "error";
    });
  },
});

export const fetchListAgents = createAsyncThunk<
  AgentData[],
  void,
  { state: RootState }
>("agents/fetchList", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<AgentData[]>,
      AxiosResponse<PartnerAPIResponseCover<AgentData[]>>
    >(`partners/agent/search/?format=json`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchAgents = createAsyncThunk<
  Pagination<AgentData>,
  { page: number; keyword: string },
  { state: RootState }
>("agents/fetch", async ({ page, keyword }, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<AgentData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<AgentData>>>
    >(
      `partners/agent?limit=${DATA_LIMIT}&keyword=${keyword}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`
    );
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const listAgents = createAsyncThunk<
  AgentData[],
  void,
  { state: RootState }
>("agents/list", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<AgentData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<AgentData>>>
    >("partners/agent/search/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const deleteAgent = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("agents/delete", async (agent_id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>>
    >(`partners/agent/${agent_id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const updateAgent = createAsyncThunk<
  { status: string },
  AgentEditDataForm,
  { state: RootState }
>("agents/update", async (data: AgentEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, AgentEditDataForm>,
      AgentEditDataForm
    >(`partners/agent/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const viewAgent = createAsyncThunk<
  AgentData,
  string,
  { state: RootState }
>("agents/view", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<AgentData>,
      AxiosResponse<APIResponseCover<AgentData>>
    >(`partners/agent/${id}?format=json`);
    // console.log(response.data);
    return response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addAgent = createAsyncThunk<
  { status: string },
  AgentDataForm,
  { state: RootState }
>("agents/add", async (data: AgentDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<{ id: string }>,
      AxiosResponse<APIResponseCover<{ id: string }>, AgentDataForm>,
      AgentDataForm
    >("partners/agent?format=json", data);
    // console.log(response.data.data.id);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshAgentList, resetAgentView } = agentsSlice.actions;

export default agentsSlice.reducer;
