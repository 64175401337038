/// Menu
import React, { useContext, useEffect, useReducer, useState } from "react";
// import Collapse from "react-bootstrap/Collapse";

/// Link
import { Link, useLocation } from "react-router-dom";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState: any, updatedState: any) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    // openMenuToggle,
    // menuToggle,
  } = useContext(ThemeContext);

  const location = useLocation();

  const [state, setState] = useReducer(reducer, initialState);
  useEffect(() => {}, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  // const handleMenuActive = (status: string) => {
  //   setState({ active: status });
  //   if (state.active === status) {
  //     setState({ active: "" });
  //   }
  // };
  // const handleSubmenuActive = (status: string) => {
  //   setState({ activeSubmenu: status });
  //   if (state.activeSubmenu === status) {
  //     setState({ activeSubmenu: "" });
  //   }
  // };
  // Menu dropdown list End

  /// Path
  const path = location.pathname; // window.location.pathname;
  const newPath = path.split("/");
  const finalPath = newPath[newPath.length - 1];

  useEffect(() => {
    MenuList.forEach((data) => {
      if (finalPath === data.to) {
        // if (menuToggle) {
        //   openMenuToggle();
        // }
        setState({ active: data.title, activeSubmenu: "" });
      }
      // data?.content?.forEach((item) => {
      //   if (finalPath === item.to) {
      //     setState({ active: data.title, activeSubmenu: "" });
      //   }
      //   if ("content" in item) {
      //     item?.content?.forEach((ele) => {
      //       if (path === ele.to) {
      //         setState({ activeSubmenu: item.title, active: data.title });
      //       }
      //     });
      //   }
      // });
    });
  }, [finalPath, path]);

  return (
    <div
      className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? // ? hideOnScroll > 120
            hideOnScroll
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                  key={index}
                >
                  {" "}
                  {false ? //     }} //       handleMenuActive(data.title); //     onClick={() => { // data.content.length > 0 ? //     className="has-arrow" //     to={"#"} //   <Link // <> // data.content &&
                  //   >
                  //     <div className="menu-icon">{data.iconStyle}</div>{" "}
                  //     <span className="nav-text">
                  //       {data.title}
                  //       {data.update && data.update.length > 0 ? (
                  //         <span className="badge badge-xs badge-danger ms-2">
                  //           {data.update}
                  //         </span>
                  //       ) : (
                  //         ""
                  //       )}
                  //     </span>
                  //   </Link>
                  //   <Collapse in={state.active === data.title ? true : false}>
                  //     <ul
                  //       className={`${
                  //         menuClass === "mm-collapse" ? "mm-show" : ""
                  //       }`}
                  //     >
                  //       {data?.content &&
                  //         data?.content?.map((subData, index) => {
                  //           return (
                  //             <li
                  //               key={index}
                  //               className={`${
                  //                 state.activeSubmenu === subData.title
                  //                   ? "mm-active"
                  //                   : ""
                  //               }`}
                  //             >
                  //               {"content" in subData &&
                  //               subData?.content &&
                  //               subData?.content?.length > 0 ? (
                  //                 <>
                  //                   <Link
                  //                     to={data?.to ?? "#"}
                  //                     className={
                  //                       subData.hasMenu ? "has-arrow" : ""
                  //                     }
                  //                     onClick={() => {
                  //                       handleSubmenuActive(subData.title);
                  //                     }}
                  //                   >
                  //                     {subData.title}
                  //                   </Link>
                  //                   <Collapse
                  //                     in={
                  //                       state.activeSubmenu === subData.title
                  //                         ? true
                  //                         : false
                  //                     }
                  //                   >
                  //                     <ul
                  //                       className={`${
                  //                         menuClass === "mm-collapse"
                  //                           ? "mm-show"
                  //                           : ""
                  //                       }`}
                  //                     >
                  //                       {subData?.content &&
                  //                         subData?.content?.map(
                  //                           (subSubData, ind) => {
                  //                             return (
                  //                               <li key={ind}>
                  //                                 <Link
                  //                                   className={`${
                  //                                     path === subSubData.to
                  //                                       ? "mm-active"
                  //                                       : ""
                  //                                   }`}
                  //                                   to={subSubData?.to ?? "#"}
                  //                                 >
                  //                                   {subSubData.title}
                  //                                 </Link>
                  //                               </li>
                  //                             );
                  //                           }
                  //                         )}
                  //                     </ul>
                  //                   </Collapse>
                  //                 </>
                  //               ) : (
                  //                 <Link
                  //                   to={subData?.to ?? "#"}
                  //                   className={`${
                  //                     subData.to === path ? "mm-active" : ""
                  //                   }`}
                  //                 >
                  //                   {subData.title}
                  //                 </Link>
                  //               )}
                  //             </li>
                  //           );
                  //         })}
                  //     </ul>
                  //   </Collapse>
                  // </>
                  null : (
                    <Link to={data?.to ?? "#"}>
                      <div className="menu-icon">{data.iconStyle}</div>{" "}
                      <span className="nav-text">{data.title}</span>
                      {/* {data.update && data.update.length > 0 ? (
                        <span className="badge badge-xs badge-danger ms-2">
                          {data.update}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
