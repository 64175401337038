import { useEffect, useState } from "react";
import { useAppDispatch } from "src/store/hooks";
import { AuthResponse } from "src/store/reducers/auth.d";
import { setAuthentication } from "src/store/reducers/auth";
import { LocalStorageKey } from "src/utils/constants";

export default function useAuthTokenHandler() {
  const dispatch = useAppDispatch();
  const [isAppLoading, setIsAppLoading] = useState(true);

  useEffect(() => {
    const tokenDetailsString = localStorage.getItem(LocalStorageKey);
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString) as AuthResponse;
      dispatch(setAuthentication(tokenDetails));
    }
    setIsAppLoading(false);
  }, [dispatch]);

  return isAppLoading;
}
