import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  DriverData,
  DriverDataForm,
  DriverEditDataForm,
  addDriver,
  refreshDriverList,
  updateDriver,
  viewDriver,
} from "src/store/reducers/drivers";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import {
  KeyRequired,
  LengthRequired,
  RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { fetchListCabVendors } from "src/store/reducers/cabVendors";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

type IDriverFormCanvas = {
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
  driverData?: DriverData;
};

// name: string;
// address: string;
// pincode: string;
// city: string;
// country: string;
// driver_license_number: string; //
// contact_number: string;
// alternate_number: string;
// cab_number: string; //
// cab_vendor_id: string; //
// rating: string; //
// status: string;
// notes: string; //
// const defaultDriverFormData = {
//   name: "",
//   // address: "",
//   // pincode: "",
//   city: "",
//   // country: "",
//   driver_license_number: "",
//   contact_number: "",
//   cab_number: "",
//   cab_vendor_id: "",
//   rating: "",
//   alternate_number: "",
//   // status: "",
//   notes: "",
// };

const DriverFormCanvas = ({
  Title,
  show,
  hide,
  editId,
  driverData,
}: IDriverFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cabVendorsList = useAppSelector((state) => state.cabVendors.list.data);
  // const cabVendorsListStatus = useAppSelector(
  //   (state) => state.cabVendors.list.status
  // );

  // const [driverData, setData] = useState<Omit<DriverDataForm, "id">>(
  //   defaultDriverFormData
  // );
  const {
    handleChange,
    setErrors,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      // role: driverData?.role,
      name: driverData?.name || "",
      // address: driverData?.address || "",
      // pincode: driverData?.pincode || "",
      city: driverData?.city || "",
      // country: driverData?.country || "",
      driver_license_number: driverData?.driver_license_number || "",
      contact_number: driverData?.contact_number || "",
      cab_number: driverData?.cab_number || "",
      cab_vendor_id: driverData?.cab_vendor_id || "",
      alternate_number: driverData?.alternate_number || "",
      rating: driverData?.rating || "",
      // status: driverData?.status,
      notes: driverData?.notes || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: StringLengthValidation("Name", 3, 30),
      // address: StringLengthValidation("Address", 5, 30),
      // pincode: KeyRequired("Pincode"),
      // country: StringLengthValidation("Country", 5, 30),
      // status: RangeRequired("Status", 0, 5),
      rating: RangeRequired("Rating", 1, 5, false),
      cab_number: StringLengthValidation("Cab number", 8, 12, false),
      // cab_vendor_id: KeyRequired("Cab vendor"),
      // notes: KeyRequired("Notes"),
      driver_license_number: StringLengthValidation(
        "Driving license number",
        5,
        30,
        false
      ),
      contact_number: LengthRequired("Contact number", 10),
      alternate_number: LengthRequired("Alternative contact number", 10, false),
      city: KeyRequired("City"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const finalValues: Omit<DriverDataForm, "id"> = { ...values };
      setPending(true);
      (["alternate_number", "cab_vendor_id", "rating"] as const).forEach(
        (k) => {
          if (!finalValues[k]) {
            finalValues[k] = null;
          }
        }
      );
      console.log(values);
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...finalValues, id: editId };
        editU(formValue);
      } else {
        setPending(true);
        // const formValue = { ...values, id: editId };
        addU(finalValues);
      }
    },
  });

  // useEffect(() => {
  //   console.log(errors);
  //   // console.log(values);
  //   // console.log(errors);
  // }, [errors]);

  const handleHide = useCallback(() => {
    // setData(defaultDriverFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<DriverDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addDriver(values)).unwrap();
        handleHide();
        navigate("/drivers");
        toast.success("Driver is added successfully");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  const editU = useCallback(
    async (values: DriverEditDataForm) => {
      try {
        await dispatch(updateDriver(values)).unwrap();
        toast.success("Driver is updated successfully");
        dispatch(refreshDriverList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewDriver(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //             // pincode: "", // data.pincode.toString(),
  //             // status: data.status.toString(),
  //             rating: data.rating.toString(),
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  // useEffect(() => {
  //   if (cabVendorsListStatus === "idle") {
  //     dispatch(fetchListCabVendors());
  //   }
  // }, [cabVendorsListStatus, dispatch]);

  useEffect(() => {
    resetForm();
    // setData(defaultDriverFormData);
    if (show) {
      dispatch(fetchListCabVendors());
    }
  }, [dispatch, resetForm, show]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="name"
                  labelText="Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.name}
                  touched={touched.name}
                  errors={errors.name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="cab_number"
                  labelText="Cab number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.cab_number}
                  touched={touched.cab_number}
                  errors={errors.cab_number}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Cab vendor"
                  placeholder="Cab vendor"
                  id={"cab_vendor_id"}
                  options={cabVendorsList.map((cabVendor) => ({
                    label: cabVendor.contact_person_name,
                    value: cabVendor.id,
                  }))}
                  touched={touched.cab_vendor_id}
                  errors={errors.cab_vendor_id}
                  handleChange={handleChange}
                  value={
                    cabVendorsList
                      .filter(
                        (cabVendor) => cabVendor.id === values.cab_vendor_id
                      )
                      ?.map((cabVendor) => ({
                        label: cabVendor.contact_person_name,
                        value: cabVendor.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="cab_vendor_id"
                  labelText="Cab vendor"
                  // required={true}
                  handleChange={handleChange}
                  value={values.cab_vendor_id}
                  touched={touched.cab_vendor_id}
                  errors={errors.cab_vendor_id}
                >
                  <option>Select cab vendor</option>
                  {cabVendorsList.map((cabVendor) => (
                    <option value={cabVendor.id} key={cabVendor.id}>
                      {cabVendor.company_name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="driver_license_number"
                  labelText="Driving license no"
                  // required={true}
                  handleChange={handleChange}
                  value={values.driver_license_number}
                  touched={touched.driver_license_number}
                  errors={errors.driver_license_number}
                />{" "}
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email_id"
                  labelText="Email"
                  required={true}
                  handleChange={handleChange}
                  value={values.email_id}
                  touched={touched.email_id}
                  errors={errors.email_id}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="pincode"
                  labelText="Pincode"
                  required={true}
                  handleChange={handleChange}
                  value={values.pincode}
                  touched={touched.pincode}
                  errors={errors.pincode}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="country"
                  labelText="Country"
                  required={true}
                  handleChange={handleChange}
                  value={values.country}
                  touched={touched.country}
                  errors={errors.country}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="address"
                  labelText="Address"
                  required={true}
                  handleChange={handleChange}
                  value={values.address}
                  touched={touched.address}
                  errors={errors.address}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="experience"
                  labelText="Experience"
                  required={true}
                  handleChange={handleChange}
                  value={values.experience}
                  touched={touched.experience}
                  errors={errors.experience}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_number"
                  labelText="Contact number"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_number}
                  touched={touched.contact_number}
                  errors={errors.contact_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="alternate_number"
                  labelText="Alternate number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.alternate_number}
                  touched={touched.alternate_number}
                  errors={errors.alternate_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="rating"
                  labelText="Rating"
                  // required={true}
                  handleChange={handleChange}
                  value={values.rating}
                  touched={touched.rating}
                  errors={errors.rating}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="employees_size"
                  labelText="Employees size"
                  required={true}
                  handleChange={handleChange}
                  value={values.employees_size}
                  touched={touched.employees_size}
                  errors={errors.employees_size}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="status"
                  labelText="Status"
                  required={true}
                  handleChange={handleChange}
                  value={values.status}
                  touched={touched.status}
                  errors={errors.status}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default DriverFormCanvas;
