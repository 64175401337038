export const LocalStorageKey = "userDetails";

export const DEFAULT_ERR_MESSAGE = "Please try again";

export enum UserRole {
  "Super Admin" = 1,
  Admin = 2,
  Manager = 4,
  Basic = 8,
}

export enum GainMargin {
  red = "red",
  green = "green",
}

export const UserRoleCSSLabel = {
  [UserRole["Super Admin"]]: "warning",
  [UserRole.Admin]: "primary",
  [UserRole.Manager]: "danger",
  [UserRole.Basic]: "secondary",
} as const;

export const GainMarginCSSLabel = {
  [GainMargin.red]: "danger",
  [GainMargin.green]: "success",
} as const;

export type API_STATUS = "pending" | "success" | "idle" | "error";

export const DATA_LIMIT = 20;
