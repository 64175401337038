import { useState } from "react";
import { Link } from "react-router-dom";
import {
  BookingData,
  BookingTransaction,
  // PartnerType,
  TransactionType,
} from "src/store/reducers/bookings";
import AddTransaction from "./AddTransaction";

interface ListBookingTransactionsProps {
  bookingId: string;
  transactionsData: BookingTransaction[];
  bookingData: BookingData;
}

function ListBookingTransactions({
  transactionsData,
  bookingData,
  bookingId,
}: ListBookingTransactionsProps) {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Transactions</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setShowAddModal(true)}
                >
                  + Add Transactions
                </Link>
              </div>
              <div className="card-body ">
                {transactionsData.length > 0 ? (
                  <div className="table-responsive active-projects">
                    <div
                      id="projects-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="projects-tbl"
                        className="table table-hover dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Created Date</th>
                            <th>Notes</th>
                            <th>Type</th>
                            <th>Partner type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionsData?.map((transactionData, index) => (
                            <tr key={transactionData.id}>
                              <td>{transactionData.amount}</td>
                              <td>
                                {new Date(
                                  transactionData.created_date
                                ).toLocaleString('en-IN')}
                              </td>
                              <td>{transactionData.notes}</td>
                              <td>
                                {transactionData.transaction_type
                                  ? TransactionType[
                                      transactionData.transaction_type
                                    ]
                                  : null}
                              </td>
                              <td>
                                {transactionData.agent ? (
                                  <span>
                                    <b> Agent : </b>
                                    {transactionData.agent?.name}
                                  </span>
                                ) : transactionData.driver ? (
                                  <span>
                                    <b> Driver : </b>
                                    {transactionData.driver?.name}
                                  </span>
                                ) : transactionData.hotel ? (
                                  <span>
                                    <b> Hotel : </b>
                                    {transactionData.hotel?.name}
                                  </span>
                                ) : transactionData.taxi ? (
                                  <span>
                                    <b> Taxi : </b>
                                    {transactionData.taxi.vehicle_name}
                                  </span>
                                ) : transactionData.cabvendor ? (
                                  <span>
                                    <b> Cab vendor : </b>
                                    {
                                      transactionData.cabvendor
                                        .contact_person_name
                                    }
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    &nbsp;
                    <strong>Add transactions for this booking </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTransaction
        bookingId={showAddModal && bookingId ? bookingId : ""}
        bookingData={bookingData}
        hide={() => setShowAddModal(false)}
        Title="Add Transaction"
      />
    </>
  );
}

export default ListBookingTransactions;
