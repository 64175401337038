import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./css/style.css";
import ForgotPassword from "./jsx/pages/ForgotPassword";
import Registration from "./jsx/pages/Registration";
import Login from "./jsx/pages/Login";
import PublicRoute from "./jsx/routes/PublicRoute";
import PrivateRoute, { allroutes } from "./jsx/routes/PrivateRoute";
import ScrollToTop from "./jsx/layouts/ScrollToTop";
import LockScreen from "./jsx/pages/LockScreen";
import Error400 from "./jsx/pages/Error400";
import Error403 from "./jsx/pages/Error403";
import Error404 from "./jsx/pages/Error404";
import Error500 from "./jsx/pages/Error500";
import Error503 from "./jsx/pages/Error503";
import MainLayout from "./jsx/MainLayout";
import useAuthTokenHandler from "./hooks/AuthHook";
import ResetPassword from "./jsx/pages/ResetPassword";

export default function App() {
  const isAppLoading = useAuthTokenHandler();

  if (isAppLoading) {
    return <p>Loading</p>;
  }
  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/register" element={<PublicRoute />}>
            <Route path="/register" element={<Registration />} />
          </Route>
          <Route path="/forgot-password" element={<PublicRoute />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/reset-password" element={<PublicRoute />}>
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          {/* <Route path="/recover-password" element={<PublicRoute />}>
            <Route path="/recover-password" element={<RecoverPassword />} />
          </Route> */}

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/page-lock-screen" element={<LockScreen />} />
            <Route path="/page-error-400" element={<Error400 />} />
            <Route path="/page-error-403" element={<Error403 />} />
            <Route path="/page-error-404" element={<Error404 />} />
            <Route path="/page-error-500" element={<Error500 />} />
            <Route path="/page-error-503" element={<Error503 />} />
            <Route element={<MainLayout />}>
              {allroutes.map((data, i) => (
                <Route
                  key={i}
                  // exact
                  path={`${data.url}`}
                  element={data.component}
                />
              ))}
              <Route path="" element={<Navigate to="/dashboard" />} />
              <Route path="/" element={<Navigate to="/dashboard" />} />
            </Route>
          </Route>
          <Route path="*" element={<Error400 />} />
          {/* <ProjectRoute /> */}
        </Routes>

        <ToastContainer
          autoClose={3000}
          draggable={false}
          position="top-right"
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
        <ScrollToTop />
      </BrowserRouter>
    </Suspense>
  );
}
