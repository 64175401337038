import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import {
  BookingDataForm,
  addBooking,
  nullValueKeysBooking,
} from "src/store/reducers/bookings";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import {
  KeyRequired,
  LengthRequired,
  RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { fetchListAgents } from "src/store/reducers/agents";
// import Select from "react-select";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

type IAddBookingCanvas = {
  Title: string;
  show: boolean;
  // editId: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddBookingCanvas = ({ Title, show, setShow }: IAddBookingCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const agentsList = useAppSelector((state) => state.agents.list.data);
  // const agentsListStatus = useAppSelector((state) => state.agents.list.status);

  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      customer_name: "",
      customer_number: "",
      email: "",
      arrival_date: "",
      departure_date: "",
      total_pax: "",
      total_children: "",
      total_amount: "",
      amount_received: "",
      next_payment_date: "",
      agent_commission: "",
      flight_tickets: "",
      travel_agent_id: "",
      customer_address: "",
      customer_gstn: "",
      notes: "",
    },
    validationSchema: Yup.object({
      arrival_date: KeyRequired("Arrival date"),
      departure_date: KeyRequired("Departure date"),
      next_payment_date: Yup.string(),
      // arrival_time: Yup.string().required("Arrival Time is required"),
      // checkin_date: Yup.string().required("Check in Date is required"),
      // check_out_date: Yup.string().required("Check out Date is required"),
      // payment_notification_date: Yup.string().required(
      //   "Payment notification Date is required"
      // ),
      customer_name: StringLengthValidation("Customer name", 3, 30, false),
      // arrival_place: Yup.string().required("Arrival place is required"),
      // driver_id: Yup.string().required("Driver id is required"),
      // vehicle_type: Yup.string().required("Vehicle type is required"),
      // vehicle_number: Yup.string().required("Vehicle number is required"),
      // trip_details: Yup.string().required("Trip details is required"),
      // travel_agent: Yup.string().required("Travel agent is required"),
      // hotel: Yup.string().required("Hotel is required"),
      // room_category: Yup.string().required("Room category is required"),
      agent_commission: RangeRequired("Agent commission", 0, 100, false),
      flight_tickets: Yup.string(),
      total_pax: Yup.number().positive().integer(),
      // total_children: Yup.number().positive().integer(),
      total_amount: Yup.number()
        .positive()
        .integer()
        .required("Total amount is required"),
      amount_received: Yup.number().positive().integer(),
      // cab_price: Yup.number().positive().integer(),
      // other_cab_expenses: Yup.number().positive().integer(),
      // hotel_price: Yup.number().positive().integer(),
      // advance_amount_paid: Yup.number().positive().integer(),
      customer_number: LengthRequired("Customer number", 10, false),
      email: Yup.string().email("Invalid email address"),
      customer_address: Yup.string(),
      customer_gstn: Yup.string(),
      notes: Yup.string(),
      // .required("Email address is required"),
      // travel_agent_id: KeyRequired("Travel Agent"),
      // role: Yup.string()
      //   .required("required")
      //   .oneOf(["1", "2", "3", "4"], "You must select country to reside in"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const finalValues: BookingDataForm = { ...values };
      setPending(true);
      nullValueKeysBooking.forEach((k) => {
        if (!finalValues[k]) {
          finalValues[k] = null;
        }
      });
      addU(finalValues);
    },
  });

  const addU = async (values: BookingDataForm) => {
    try {
      const data = await dispatch(addBooking(values)).unwrap();
      toast.success("Booking is added successfully");
      setShow(false);
      navigate(`/booking-view/${data.data}`);
    } catch (error: any) {
      console.log(error);
      toast.error("Saving booking failed, Please try again");
    } finally {
      setPending(false);
    }
  };

  // useEffect(() => {
  //   console.log(errors);
  //   console.log(touched);
  //   console.log(values);
  // }, [errors, touched, values]);

  useEffect(() => {
    resetForm();
    if (show) {
      dispatch(fetchListAgents());
    }
  }, [dispatch, resetForm, show]);

  // useEffect(() => {
  //   if (agentsListStatus === "idle") {
  //     dispatch(fetchListAgents());
  //   }
  // }, [agentsListStatus, dispatch]);
  // useEffect(() => console.log(values));

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h3 className="modal-title" id="#gridSystemModal">
          {Title}
        </h3>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShow(false)}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_name"
                  labelText="Customer name"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_name}
                  touched={touched.customer_name}
                  errors={errors.customer_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_number"
                  labelText="Customer number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_number}
                  touched={touched.customer_number}
                  errors={errors.customer_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email"
                  inputType="email"
                  labelText="Customer Email"
                  // required={true}
                  handleChange={handleChange}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="arrival_place"
                  labelText="Arrival place"
                  required={true}
                  handleChange={handleChange}
                  value={values.arrival_place}
                  touched={touched.arrival_place}
                  errors={errors.arrival_place}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="arrival_date"
                  labelText="Arrival date"
                  required={true}
                  handleChange={(date) => setFieldValue("arrival_date", date)}
                  value={values.arrival_date}
                  selectsStart
                  endDate={values.departure_date}
                  touched={touched.arrival_date}
                  errors={errors.arrival_date}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="arrival_time"
                  pickerType="time"
                  labelText="Arrival Time"
                  required={true}
                  handleChange={(date) => setFieldValue("arrival_time", date)}
                  value={values.arrival_time}
                  touched={touched.arrival_time}
                  errors={errors.arrival_time}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="departure_date"
                  labelText="Departure date"
                  required={true}
                  handleChange={(date) => setFieldValue("departure_date", date)}
                  value={values.departure_date}
                  selectsEnd
                  startDate={values.arrival_date}
                  touched={touched.departure_date}
                  errors={errors.departure_date}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="travel_agent"
                  labelText="Travel agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent}
                  touched={touched.travel_agent}
                  errors={errors.travel_agent}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="agent_commission"
                  labelText="Agent commision"
                  // required={true}
                  handleChange={handleChange}
                  value={values.agent_commission}
                  touched={touched.agent_commission}
                  errors={errors.agent_commission}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_pax"
                  labelText="Total pax"
                  // required={true}
                  handleChange={handleChange}
                  value={values.total_pax}
                  touched={touched.total_pax}
                  errors={errors.total_pax}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_children"
                  labelText="Total Children"
                  // required={true}
                  handleChange={handleChange}
                  value={values.total_children}
                  touched={touched.total_children}
                  errors={errors.total_children}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="total_amount"
                  labelText="Total amount"
                  required={true}
                  handleChange={handleChange}
                  value={values.total_amount}
                  touched={touched.total_amount}
                  errors={errors.total_amount}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="amount_received"
                  labelText="Amount received"
                  // required={true}
                  handleChange={handleChange}
                  value={values.amount_received}
                  touched={touched.amount_received}
                  errors={errors.amount_received}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="next_payment_date"
                  labelText="Next payment date"
                  // required={true}
                  handleChange={(date) =>
                    setFieldValue("next_payment_date", date)
                  }
                  value={values.next_payment_date}
                  touched={touched.next_payment_date}
                  errors={errors.next_payment_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_gstn"
                  labelText="Customer GSTN"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_gstn}
                  touched={touched.customer_gstn}
                  errors={errors.customer_gstn}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="customer_address"
                  labelText="Customer address"
                  // required={true}
                  handleChange={handleChange}
                  value={values.customer_address}
                  touched={touched.customer_address}
                  errors={errors.customer_address}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Travel Agent"
                  placeholder="Travel Agent"
                  id={"travel_agent_id"}
                  options={agentsList.map((agent) => ({
                    label: agent.name,
                    value: agent.id,
                  }))}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                  handleChange={handleChange}
                  value={
                    agentsList
                      .filter((user) => user.id === values.travel_agent_id)
                      ?.map((agent) => ({
                        label: agent.name,
                        value: agent.id,
                      }))?.[0] || ""
                  }
                />
                {/* <Select
                  placeholder="Select Travel Agent"
                  id="travel_agent_id"
                  options={agentsList.map((agent) => ({
                    label: agent.name,
                    value: agent.id,
                  }))}
                  value={
                    agentsList.filter(
                      (user) => user.id === values.travel_agent_id
                    )?.[0] || ""
                  }
                  onChange={(e) =>
                    handleChange({
                      target: { name: "travel_agent_id", value: e?.value },
                    })
                  }
                /> */}
                {/* <CustomSelectWithLabel
                  id="travel_agent_id"
                  labelText="Travel Agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent_id}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                >
                  <option>Select Travel Agent</option>
                  {agentsList.map((agent) => (
                    <option value={agent.id} key={agent.id}>
                      {agent.name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="travel_agent_id"
                  labelText="Travel Agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent_id}
                  touched={touched.travel_agent_id}
                  errors={errors.travel_agent_id}
                /> */}
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="flight_tickets"
                  labelText="Flight tickets"
                  // required={true}
                  handleChange={handleChange}
                  value={values.flight_tickets}
                  touched={touched.flight_tickets}
                  errors={errors.flight_tickets}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-xl-12">
                <h4>Hotel</h4>
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="hotel"
                  labelContent={{ text: "Hotel", required: true }}
                  handleChange={handleChange}
                  value={values.hotel}
                  touched={touched.hotel}
                  errors={errors.hotel}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="room_category"
                  labelContent={{ text: "Room category", required: true }}
                  handleChange={handleChange}
                  value={values.room_category}
                  touched={touched.room_category}
                  errors={errors.room_category}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="checkin_date"
                  labelContent={{ text: "Check in date", required: true }}
                  handleChange={(date) => setFieldValue("checkin_date", date)}
                  value={values.checkin_date}
                  touched={touched.checkin_date}
                  errors={errors.checkin_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="check_out_date"
                  labelContent={{ text: "Check out date", required: true }}
                  handleChange={(date) => setFieldValue("check_out_date", date)}
                  value={values.check_out_date}
                  touched={touched.check_out_date}
                  errors={errors.check_out_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="hotel_price"
                  labelContent={{ text: "Hotel price", required: true }}
                  handleChange={handleChange}
                  value={values.hotel_price}
                  touched={touched.hotel_price}
                  errors={errors.hotel_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="advance_amount_paid"
                  labelContent={{ text: "Advance amount paid", required: true }}
                  handleChange={handleChange}
                  value={values.advance_amount_paid}
                  touched={touched.advance_amount_paid}
                  errors={errors.advance_amount_paid}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="payment_notification_date"
                  labelContent={{
                    text: "Payment notification date",
                    required: true,
                  }}
                  handleChange={(date) =>
                    setFieldValue("payment_notification_date", date)
                  }
                  value={values.payment_notification_date}
                  touched={touched.payment_notification_date}
                  errors={errors.payment_notification_date}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <h4>Cab</h4>
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="driver_id"
                  labelContent={{ text: "Driver id", required: true }}
                  handleChange={handleChange}
                  value={values.driver_id}
                  touched={touched.driver_id}
                  errors={errors.driver_id}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="cab_price"
                  labelContent={{ text: "Cab price", required: true }}
                  handleChange={handleChange}
                  value={values.cab_price}
                  touched={touched.cab_price}
                  errors={errors.cab_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_number"
                  labelContent={{ text: "Vehicle number", required: true }}
                  handleChange={handleChange}
                  value={values.vehicle_number}
                  touched={touched.vehicle_number}
                  errors={errors.vehicle_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_type"
                  labelContent={{ text: "Vehicle type", required: true }}
                  handleChange={handleChange}
                  value={values.vehicle_type}
                  touched={touched.vehicle_type}
                  errors={errors.vehicle_type}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="other_cab_expenses"
                  labelContent={{ text: "Other cab expenses", required: true }}
                  handleChange={handleChange}
                  value={values.other_cab_expenses}
                  touched={touched.other_cab_expenses}
                  errors={errors.other_cab_expenses}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="trip_details"
                  labelContent={{ text: "Trip details", required: true }}
                  handleChange={handleChange}
                  value={values.trip_details}
                  touched={touched.trip_details}
                  errors={errors.trip_details}
                />
              </div> 
              </div>*/}
            {/* <div className="col-xl-6 mb-3">
                <CustomSelectWithLabel
                  id="role"
                  labelContent={{ text: "Role", required: true }}
                  handleChange={handleChange}
                  value={values.role}
                  touched={touched.role}
                  errors={errors.role}
                >
                  <>
                    <option>Select role</option>
                    <option value="1">{UserRole[1]}</option>
                    <option value="2">{UserRole[2]}</option>
                    <option value="4">{UserRole[4]}</option>
                    <option value="8">{UserRole[8]}</option>
                  </>
                </CustomSelectWithLabel>
              </div> */}
            <button
              type="submit"
              disabled={pending}
              className="btn btn-primary me-1"
            >
              Submit
            </button>
            <Link
              to={"#"}
              onClick={() => setShow(false)}
              className="btn btn-danger light ms-1"
            >
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default AddBookingCanvas;
