import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  CabVendorData,
  CabVendorDataForm,
  CabVendorEditDataForm,
  addCabVendor,
  refreshCabVendorList,
  updateCabVendor,
  // viewCabVendor,
} from "src/store/reducers/cabVendors";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import {
  EmailRequired,
  KeyRequired,
  LengthRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";

type ICabVendorFormCanvas = {
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
  cabVendorData?: CabVendorData;
};

// contact_person_name: string;
// email_id: string;
// company_name: string;
// contact_number: string;
// alternate_number: string;
// notes: string;
// const defaultCabVendorFormData = {
//   contact_person_name: "",
//   email_id: "",
//   company_name: "",
//   contact_number: "",
//   alternate_number: "",
//   notes: "",
//   city: "",
// };

const CabVendorFormCanvas = ({
  Title,
  show,
  hide,
  editId,
  cabVendorData,
}: ICabVendorFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  // const [cabVendorData, setData] = useState<Omit<CabVendorDataForm, "id">>(
  //   defaultCabVendorFormData
  // );
  const {
    handleChange,
    values,
    setErrors,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      contact_number: cabVendorData?.contact_number || "",
      alternate_number: cabVendorData?.alternate_number || "",
      email_id: cabVendorData?.email_id || "",
      contact_person_name: cabVendorData?.contact_person_name || "",
      company_name: cabVendorData?.company_name || "",
      notes: cabVendorData?.notes || "",
      city: cabVendorData?.city || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_name: StringLengthValidation("Company name", 5, 30, false),
      // notes: KeyRequired("Notes"),
      // city: KeyRequired("City"),
      contact_person_name: StringLengthValidation("Contact person name", 3, 30),
      contact_number: LengthRequired("Contact number", 10),
      alternate_number: LengthRequired("Alternate contact number", 10, false),
      email_id: EmailRequired("Email address", false),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);

      const finalValues: Omit<CabVendorDataForm, "id"> = { ...values };
      setPending(true);
      (["alternate_number"] as const).forEach((k) => {
        if (!finalValues[k]) {
          finalValues[k] = null;
        }
      });
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...finalValues, id: editId };
        editU(formValue);
      } else {
        setPending(true);
        // const formValue = { ...values, id: editId };
        addU(finalValues);
      }
    },
  });

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  const handleHide = useCallback(() => {
    // setData(defaultCabVendorFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<CabVendorDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addCabVendor(values)).unwrap();
        handleHide();
        toast.success("CabVendor is added successfully");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  const editU = useCallback(
    async (values: CabVendorEditDataForm) => {
      try {
        await dispatch(updateCabVendor(values)).unwrap();
        toast.success("CabVendor is updated successfully");
        dispatch(refreshCabVendorList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewCabVendor(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  useEffect(() => {
    resetForm();
    // setData(defaultCabVendorFormData);
  }, [resetForm, show]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_person_name"
                  labelText="Contact Person Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_person_name}
                  touched={touched.contact_person_name}
                  errors={errors.contact_person_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="company_name"
                  labelText="Company Name"
                  // required={true}
                  handleChange={handleChange}
                  value={values.company_name}
                  touched={touched.company_name}
                  errors={errors.company_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email_id"
                  inputType="email"
                  labelText="Email"
                  // required={true}
                  handleChange={handleChange}
                  value={values.email_id}
                  touched={touched.email_id}
                  errors={errors.email_id}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  // required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_number"
                  labelText="Contact number"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_number}
                  touched={touched.contact_number}
                  errors={errors.contact_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="alternate_number"
                  labelText="Alternate contact number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.alternate_number}
                  touched={touched.alternate_number}
                  errors={errors.alternate_number}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default CabVendorFormCanvas;
