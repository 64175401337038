// import { DEFAULT_ERR_MESSAGE } from "@app/utils/constants";
import {
  ForgotPasswordEmailForm,
  ForgotPasswordMobForm,
  RegistrationForm,
} from "src/store/reducers/auth.d";
import axios from "./axiosInstance";
import { DEFAULT_ERR_MESSAGE } from "src/utils/constants";
import { AxiosError } from "axios";
// import type {
//   ForgotPasswordEmailForm,
//   ForgotPasswordMobForm,
//   RegistrationForm,
// } from "@app/apis/registration";

export const registerAPI = async (data: RegistrationForm) => {
  try {
    return await axios.post("UsersApi/register/?format=json", data);
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(e?.response?.data?.detail ?? DEFAULT_ERR_MESSAGE);
    } else {
      throw new Error(DEFAULT_ERR_MESSAGE);
    }
  }
};

export const forgotPasswordAPI = async (
  data: ForgotPasswordMobForm | ForgotPasswordEmailForm
) => {
  try {
    return await axios.put("UsersApi/send-code/?format=json", data);
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(e?.response?.data?.detail ?? DEFAULT_ERR_MESSAGE);
    } else {
      throw new Error(DEFAULT_ERR_MESSAGE);
    }
  }
};
