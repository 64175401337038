import React from "react";

interface IPaginationText {
  currentPage: number;
  totalRecords: number;
  perPage: number;
}
const PaginationText = ({
  currentPage,
  totalRecords,
  perPage,
}: IPaginationText) => {
  return (
    <div className="dataTables_info">
      Showing {(currentPage - 1) * perPage + 1} to{" "}
      {totalRecords > currentPage * perPage
        ? currentPage * perPage
        : totalRecords}{" "}
      of {totalRecords} entries
    </div>
  );
};
export default PaginationText;
