import React, { useMemo } from "react";
import { Link } from "react-router-dom";

interface IPagination {
  currentPage: number;
  totalRecords: number;
  perPage: number;
  changePage:
    | React.Dispatch<React.SetStateAction<number>>
    | ((a: number) => void);
}
const Pagination = ({
  currentPage,
  totalRecords,
  perPage,
  changePage,
}: IPagination) => {
  const paginationNumber = useMemo(() => {
    return Array(Math.ceil(totalRecords / perPage))
      .fill(0)
      .map((_, i) => i + 1);
  }, [perPage, totalRecords]);

  return (
    <div
      className="dataTables_paginate paging_simple_numbers"
      id="example2_paginate"
    >
      <Link
        className="paginate_button previous disabled"
        to="#"
        onClick={() => currentPage > 1 && changePage(currentPage - 1)}
      >
        <i className="fa-solid fa-angle-left" />
      </Link>
      <span>
        {paginationNumber.map((number, i) => {
          return Math.abs(currentPage - number) <= 2 ? (
            <Link
              key={number}
              to="#"
              className={`paginate_button  ${
                currentPage === number ? "current" : ""
              } `}
              onClick={() => changePage(number)}
            >
              {number}
            </Link>
          ) : null;
        })}
      </span>
      <Link
        className="paginate_button next"
        to="#"
        onClick={() =>
          currentPage + 1 <= totalRecords && changePage(currentPage + 1)
        }
      >
        <i className="fa-solid fa-angle-right" />
      </Link>
    </div>
  );
};
export default Pagination;
