import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BookingCab } from "src/store/reducers/bookings";
import BookingCabFormCanvas from "./BookingCabFormCanvas";

interface ListBookingCabsProps {
  bookingId: string;
  bookingCabData: BookingCab[];
  setDeleteCabId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function ListBookingCabs({
  bookingCabData,
  setDeleteCabId,
  bookingId,
}: ListBookingCabsProps) {
  const [editCabId, setEditCabId] = useState<number | "Add">();
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Cabs</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setEditCabId("Add")}
                >
                  + Add Cab
                </Link>
              </div>
              <div className="card-body ">
                {bookingCabData.length > 0 ? (
                  <div className="table-responsive active-projects">
                    <div
                      id="projects-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="projects-tbl"
                        className="table table-hover dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Cab vendor</th>
                            <th>Driver</th>
                            <th>Driver No.</th>
                            <th>Total KM</th>
                            <th>Advance amount paid</th>
                            <th>Total Amount</th>
                            {/* <th>Paid Amount</th> */}
                            <th>Arrival date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookingCabData?.map((cabData, index) => (
                            <tr key={cabData.id}>
                              <td>{cabData.cab_vendor?.contact_person_name}</td>
                              <td>{cabData.driver?.name}</td>
                              <td>{cabData.driver?.contact_number}</td>
                              <td>{cabData.closing_kms - cabData.opening_kms}</td>
                              <td>{cabData.advance_amount_paid}</td>
                              <td>{cabData.cab_price}</td>
                              {/* <td>{cabData.cab_price}</td> */}
                              <td>
                                {new Date(cabData.arrival_time).toLocaleString('en-IN')}
                              </td>
                              <td>
                                <NavLink
                                  className="btn btn-primary shadow btn-xs sharp me-1"
                                  onClick={() => setEditCabId(index)}
                                  to={`#`}
                                  title="Edit"
                                >
                                  <i className={`fas fa-pen`} />
                                </NavLink>{" "}
                                <NavLink
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => {
                                    setDeleteCabId(cabData.id);
                                  }}
                                  to={`#`}
                                  title="Delete"
                                >
                                  <i className={`fas fa-x`} />
                                </NavLink>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    &nbsp;
                    <strong>Add cabs for this booking </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingCabFormCanvas
        editId={
          typeof editCabId === "undefined"
            ? ""
            : editCabId === "Add"
            ? "Add"
            : bookingCabData?.[editCabId]?.id
        }
        Title={typeof editCabId === "number" ? "Edit Cab" : "Add Cab"}
        bookingId={bookingId}
        data={
          typeof editCabId === "undefined"
            ? undefined
            : editCabId === "Add"
            ? undefined
            : bookingCabData?.[editCabId]
        }
        hide={() => setEditCabId(undefined)}
      />
    </>
  );
}

export default ListBookingCabs;
