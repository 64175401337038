import React from "react";
import { useNavigate } from "react-router-dom";
import { SVGICON } from "../../constant/theme";
import { logout } from "src/store/reducers/auth";
import { useAppDispatch } from "src/store/hooks";
import { refreshUserList } from "src/store/reducers/users";
import {
  resetBookingList,
  resetBookingView,
} from "src/store/reducers/bookings";
import {
  refreshDashboardData,
  refreshReportList,
} from "src/store/reducers/reports";
import { refreshAgentList } from "src/store/reducers/agents";
import { refreshDriverList } from "src/store/reducers/drivers";
import { refreshHotelList } from "src/store/reducers/hotels";
import { refreshCabVendorList } from "src/store/reducers/cabVendors";

export default function LogoutPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(logout());

    dispatch(refreshDashboardData());

    dispatch(refreshUserList());
    dispatch(resetBookingView());
    dispatch(resetBookingList());

    dispatch(refreshAgentList());
    dispatch(refreshDriverList());
    dispatch(refreshHotelList());
    dispatch(refreshCabVendorList());

    dispatch(refreshReportList());
    navigate("/login");
  }
  return (
    <button
      className="dropdown-item ai-icon ms-1 logout-btn"
      onClick={onLogout}
    >
      {SVGICON.Logout} <span className="ms-2">Logout </span>
    </button>
  );
}
