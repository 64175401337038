import { useContext, useMemo, useRef } from "react";
import { ThemeContext } from "src/context/ThemeContext";
import { useDocumentEvent } from "src/layouts/hooks/useDocumentEvent";

const Hamburger = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { openMenuToggle, menuToggle } = useContext(ThemeContext);

  const events = useMemo(() => {
    return [
      {
        type: "click",
        callback: (e: React.MouseEvent<HTMLDivElement>) => {
          if (ref.current && ref.current.contains(e.target)) {
            openMenuToggle();
            return;
          }
          if (menuToggle) {
            openMenuToggle();
          }
        },
      },
      // {
      //   type: "keydown",
      //   callback: (e: React.KeyboardEvent<HTMLDivElement>) => {
      //     if (e.key === "Escape") {
      //       if (menuToggle) {
      //         openMenuToggle();
      //       }
      //     }
      //   },
      // },
    ];
  }, [menuToggle, openMenuToggle]);

  useDocumentEvent(events);

  return (
    <div
      className="nav-control d-block d-lg-none d-xl-none"
      ref={ref}
      // onClick={() => {
      //   // setToggle(!toggle);
      //   openMenuToggle();
      //   // NavMenuToggle();
      // }}
    >
      <div className={`hamburger ${menuToggle ? "is-active" : ""}`}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </div>
  );
};

export default Hamburger;
