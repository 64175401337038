// import swal from "sweetalert";
// export function formatError(errorResponse: any) {
//   switch (errorResponse.error.message) {
//     case "EMAIL_EXISTS":
//       swal("Oops", "Email already exists", "error");
//       return "Email already exists";
//       break;
//     case "EMAIL_NOT_FOUND":
//       swal("Oops", "Email not found", "error", { text: "Try Again!" });
//       return "Email not found";
//       break;
//     case "INVALID_PASSWORD":
//       swal("Oops", "Invalid Password", "error", { text: "Try Again!" });
//       return "Invalid Password";
//       break;
//     case "USER_DISABLED":
//       return "User Disabled";
//       break;
//     default:
//       return "";
//   }
// }

import { AxiosError } from "axios";
import { DEFAULT_ERR_MESSAGE } from "./constants";

type MyExpectedResponseType = {
  data:
    | {
        data: string[];
      }
    | Record<string, string[]>
    | string;
};

export const readAPIErrorMessage = (
  e: AxiosError<MyExpectedResponseType>,
  defaultMessage = DEFAULT_ERR_MESSAGE
) => {
  return (
    e?.response?.data?.data?.data?.[0] ??
    e?.response?.data?.data ??
    defaultMessage
  );
};

export const FormikAPIErrorHandler = (
  error: string,
  setErrors?: any,
  toastError?: any
) => {
  try {
    const errorObj = JSON.parse(error);
    setErrors?.(errorObj);
    toastError?.("Please fix all the errors");
  } catch (e) {
    toastError?.(error || "Failed");
  }
};

export const throwErrApi = (e: any) => {
  const err = readAPIErrorMessage(e);
  if (typeof err === "string") {
    throw new Error(err);
  } else {
    throw JSON.stringify(err);
  }
};
