import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  TaxiData,
  TaxiDataForm,
  TaxiEditDataForm,
  TaxiHistoryProfile,
  addTaxi,
  addTaxiProfile,
  refreshTaxiList,
  resetTaxiView,
  updateTaxi,
  updateTaxiProfile,
  viewTaxi,
} from "src/store/reducers/taxis";
import {
  EmailRequired,
  IntegerRequired,
  KeyRequired,
  LengthRangeRequired,
  LengthRequired,
  // RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";

type ITaxiProfileFormCanvas = {
  // Title: string;
  // show: boolean;
  // editId: string;
  // hide: () => void;
  Title: string;
  taxiId: string;

  show: boolean;
  editId: string;
  hide: () => void;
  taxiProfileData?: TaxiHistoryProfile;
};

// vehicle_number: string;
// vehicle_name: string;
// vehicle_category: string;
// insurance_date: string;
// insurance_price: string;
// service_date: string;
// km_at_service: number;
// service_price: string;
// fitness: string;
// pollution_expiry_date: string;
// other_expenses: number;
// notes: string;
const defaultTaxiFormData = {
  vehicle_name: "",
  vehicle_number: "",
  // vehicle_category: "",
  // insurance_date: "",
  // insurance_price: "",
  // service_date: "",
  // km_at_service: "",
  // service_price: "",
  // fitness: "",
  // pollution_expiry_date: "",
  // other_expenses: "",
  notes: "",
  // status: "",
};

const TaxiProfileFormCanvas = ({
  Title,
  taxiId,
  show,
  hide,
  editId,
  taxiProfileData,
}: ITaxiProfileFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  // const [taxiData, setData] =
  //   useState<Omit<TaxiDataForm, "id">>(defaultTaxiFormData);
  const {
    handleChange,
    setErrors,
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      // role: taxiProfileData.role,
      //   vehicle_name: taxiProfileData?.vehicle_name || "",
      //   vehicle_number: taxiProfileData?.vehicle_number || "",
      //   vehicle_category: taxiProfileData?.vehicle_category,
      year: taxiProfileData?.year || "", //
      insurance_renewal_date: taxiProfileData?.insurance_renewal_date || "",
      insurance_date: taxiProfileData?.insurance_date || "",
      insurance_price: taxiProfileData?.insurance_price || "",
      insurance_company: taxiProfileData?.insurance_company || "",
      service_date: taxiProfileData?.service_date || "",
      service_details: taxiProfileData?.service_details || "",
      km_at_service: taxiProfileData?.km_at_service || "",
      service_price: taxiProfileData?.service_price || "",
      service_renewal_date: taxiProfileData?.service_renewal_date || "",
      fitness_date: taxiProfileData?.fitness_date || "",
      fitness_renewal_date: taxiProfileData?.fitness_renewal_date || "",
      fitness_price: taxiProfileData?.fitness_price || "",
      //   fitness: taxiProfileData?.fitness,
      pollution_cert_start_date:
        taxiProfileData?.pollution_cert_start_date || "",
      pollution_expiry_date: taxiProfileData?.pollution_expiry_date || "",
      pollution_cert_price: taxiProfileData?.pollution_cert_price || "",
      tyre_change_date: taxiProfileData?.tyre_change_date || "",
      tyre_change_price: taxiProfileData?.tyre_change_price || "",
      tyre_change_price_notes: taxiProfileData?.tyre_change_price_notes || "",
      km_running_at_tyre_change:
        taxiProfileData?.km_running_at_tyre_change || "",
      // other_expenses: taxiProfileData?.other_expenses || "",
      expenses: taxiProfileData?.other_expenses
        ? Object.keys(taxiProfileData?.other_expenses).map(
            (expenseName, index) => ({
              name: expenseName,
              amount: taxiProfileData?.other_expenses[expenseName],
            })
          )
        : [],
      notes: taxiProfileData?.notes || "",
      // status: taxiProfileData.status,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      insurance_price: IntegerRequired("Insurance price"),
      service_price: IntegerRequired("Service price"),
      fitness_price: IntegerRequired("Fitness price"),
      pollution_cert_price: IntegerRequired("Pollution cert price"),
      tyre_change_price: IntegerRequired("Tyre change price"),
      expenses: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .min(1, "Please enter expense name")
            .max(15, "Expense name can be of max 15 character")
            .required("Please enter expense name"),
          amount: IntegerRequired("Expense amount").required(
            "Please enter expense amount"
          ),
        })
      ),
      //   name: StringLengthValidation("Name", 3, 30),
      //   company_name: StringLengthValidation("Company name", 3, 30),
      //   address: StringLengthValidation("Address", 5, 30),
      //   // pincode: KeyRequired("Pincode"),
      //   // country: StringLengthValidation("Country", 3, 30),
      //   // status: RangeRequired("Status", 1, 5),
      //   employees_size: LengthRangeRequired("Employees size", 1, 3),
      //   // experience: LengthRangeRequired("Experience", 1, 7),
      //   contact_number: LengthRequired("Contact number", 10),
      //   alternate_number: LengthRequired("Alternative contact number", 10),
      //   city: KeyRequired("City"),
      //   notes: KeyRequired("Notes"),
      //   email_id: EmailRequired("Email address"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const fValues = {};
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          fValues[key] = values[key] ? values[key] : null;
        }
      }
      fValues["other_expenses"] = fValues.expenses.reduce((acc, val) => {
        acc[val.name] = Number(val.amount);
        return acc;
      }, {});
      delete fValues.expenses;
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...fValues, id: editId, taxi: taxiId };
        editU(formValue);
      } else {
        setPending(true);
        const formValue = { ...fValues, taxi: taxiId };
        addU(formValue);
      }
    },
  });

  const handleHide = useCallback(() => {
    // setData(defaultTaxiFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<TaxiDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addTaxiProfile(values)).unwrap();
        handleHide();
        toast.success("Travel Taxi is added successfully");
        dispatch(resetTaxiView());
        dispatch(refreshTaxiList());
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );
  const editU = useCallback(
    async (values: TaxiEditDataForm) => {
      try {
        await dispatch(updateTaxiProfile(values)).unwrap();
        toast.success("Travel Taxi is updated successfully");
        dispatch(resetTaxiView());
        dispatch(refreshTaxiList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewTaxi(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //             // pincode: data.pincode.toString(),
  //             // status: data.status.toString(),
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  useEffect(() => {
    resetForm();
    // setData(defaultTaxiFormData);
  }, [resetForm, show]);

  // useEffect(() => {
  //   console.log(errors);
  //   console.log(values);
  //   console.log(touched);
  // }, [values, errors, touched]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="year"
                  labelText="Year"
                  required={true}
                  handleChange={handleChange}
                  value={values.year}
                  touched={touched.year}
                  errors={errors.year}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="insurance_company"
                  labelText="Insurance company"
                  // required={true}
                  handleChange={handleChange}
                  value={values.insurance_company}
                  touched={touched.insurance_company}
                  errors={errors.insurance_company}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="insurance_date"
                  labelText="Insurance date"
                  // pickerType="YYYY-MM-DD"
                  // required={true}
                  // handleChange={handleChange}
                  handleChange={(date) => setFieldValue("insurance_date", date)}
                  value={values.insurance_date}
                  touched={touched.insurance_date}
                  errors={errors.insurance_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="insurance_renewal_date"
                  labelText="Insurance renewal date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("insurance_renewal_date", date)
                  }
                  value={values.insurance_renewal_date}
                  touched={touched.insurance_renewal_date}
                  errors={errors.insurance_renewal_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="insurance_price"
                  labelText="Insurance price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.insurance_price}
                  touched={touched.insurance_price}
                  errors={errors.insurance_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="service_date"
                  labelText="Service date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) => setFieldValue("service_date", date)}
                  value={values.service_date}
                  touched={touched.service_date}
                  errors={errors.service_date}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="service_details"
                  labelText="Service details"
                  // required={true}
                  handleChange={handleChange}
                  value={values.service_details}
                  touched={touched.service_details}
                  errors={errors.service_details}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="km_at_service"
                  labelText="KM at service"
                  // required={true}
                  handleChange={handleChange}
                  value={values.km_at_service}
                  touched={touched.km_at_service}
                  errors={errors.km_at_service}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="service_price"
                  labelText="Service price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.service_price}
                  touched={touched.service_price}
                  errors={errors.service_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="service_renewal_date"
                  labelText="Service renewal date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("service_renewal_date", date)
                  }
                  value={values.service_renewal_date}
                  touched={touched.service_renewal_date}
                  errors={errors.service_renewal_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="fitness_date"
                  labelText="Fitness date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) => setFieldValue("fitness_date", date)}
                  value={values.fitness_date}
                  touched={touched.fitness_date}
                  errors={errors.fitness_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="fitness_renewal_date"
                  labelText="Fitness renewal date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("fitness_renewal_date", date)
                  }
                  value={values.fitness_renewal_date}
                  touched={touched.fitness_renewal_date}
                  errors={errors.fitness_renewal_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="fitness_price"
                  labelText="Fitness price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.fitness_price}
                  touched={touched.fitness_price}
                  errors={errors.fitness_price}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="fitness"
                  labelText="Fitness"
                  // required={true}
                  handleChange={handleChange}
                  value={values.fitness}
                  touched={touched.fitness}
                  errors={errors.fitness}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="pollution_cert_start_date"
                  labelText="Pollution cert start date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("pollution_cert_start_date", date)
                  }
                  value={values.pollution_cert_start_date}
                  touched={touched.pollution_cert_start_date}
                  errors={errors.pollution_cert_start_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="pollution_expiry_date"
                  labelText="Pollution expiry date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("pollution_expiry_date", date)
                  }
                  value={values.pollution_expiry_date}
                  touched={touched.pollution_expiry_date}
                  errors={errors.pollution_expiry_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="pollution_cert_price"
                  labelText="Pollution cert price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.pollution_cert_price}
                  touched={touched.pollution_cert_price}
                  errors={errors.pollution_cert_price}
                />
              </div>

              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="tyre_change_date"
                  labelText="Tyre change date"
                  // required={true}
                  // pickerType="YYYY-MM-DD"
                  handleChange={(date) =>
                    setFieldValue("tyre_change_date", date)
                  }
                  value={values.tyre_change_date}
                  touched={touched.tyre_change_date}
                  errors={errors.tyre_change_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="tyre_change_price"
                  labelText="Tyre change price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.tyre_change_price}
                  touched={touched.tyre_change_price}
                  errors={errors.tyre_change_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="tyre_change_price_notes"
                  labelText="Tyre change price notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.tyre_change_price_notes}
                  touched={touched.tyre_change_price_notes}
                  errors={errors.tyre_change_price_notes}
                />
              </div>

              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="km_running_at_tyre_change"
                  labelText="KM running at tyre change"
                  // required={true}
                  handleChange={handleChange}
                  value={values.km_running_at_tyre_change}
                  touched={touched.km_running_at_tyre_change}
                  errors={errors.km_running_at_tyre_change}
                />
              </div>
              <div className="col-xl-6 mb-3"></div>

              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="other_expenses"
                  labelText="Other expenses"
                  // required={true}
                  handleChange={handleChange}
                  value={values.other_expenses}
                  touched={touched.other_expenses}
                  errors={errors.other_expenses}
                />
              </div> */}

              {values.expenses.map((expenseName, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="col-xl-6 mb-3">
                      <CustomInputWithLabel
                        id={`expenses.${index}.name`}
                        labelText={`Expense ${index + 1} name`}
                        // required={true}
                        handleChange={handleChange}
                        value={expenseName.name}
                        touched={touched?.expenses?.[index]?.name ?? false}
                        errors={errors?.expenses?.[index]?.name ?? false}
                      />
                    </div>
                    <div className="col-xl-4 mb-3">
                      <CustomInputWithLabel
                        id={`expenses.${index}.amount`}
                        labelText={`Expense ${index + 1} price`}
                        // required={true}
                        handleChange={handleChange}
                        value={expenseName.amount}
                        touched={touched?.expenses?.[index]?.amount ?? false}
                        errors={errors?.expenses?.[index]?.amount ?? false}
                      />
                    </div>
                    <div className="col-xl-2 text-end">
                      <label className="form-label">&nbsp;</label>
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "expenses",
                            values.expenses
                              .slice(0, index)
                              .concat(
                                values.expenses.slice(
                                  index + 1,
                                  values.expenses.length
                                )
                              )
                          );
                        }}
                        title={`Remove Expense ${index + 1}`}
                        className="btn btn-primary me-1"
                      >
                        Remove
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
              <div
                className="col-xl-12 text-center"
              >
                <button
                  type="button"
                  onClick={() => {
                    setFieldValue("expenses", [
                      ...values.expenses,
                      { name: "", amount: "" },
                    ]);
                  }}
                  title="Add more Expense"
                  className="btn btn-primary me-1"
                >
                  Add more
                </button>
              </div>

              {/* <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  // labelText="Notes"
                  labelText="Service detail"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div> */}
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default TaxiProfileFormCanvas;
