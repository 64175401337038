import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { UserDataForm, addUser } from "src/store/reducers/users";
import { useAppDispatch } from "src/store/hooks";
import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { UserRole } from "src/utils/constants";
import {
  EmailRequired,
  KeyRequired,
  LengthRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";

type IUserCanvas = {
  Title: string;
  show: boolean;
  // editId: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserCanvas = ({ Title, show, setShow }: IUserCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const { handleChange, values, handleSubmit, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        role: 0,
        first_name: "",
        last_name: "",
        // country_code: "",
        mobile_number: "",
        email: "",
        city: "",
      },
      validationSchema: Yup.object({
        first_name: StringLengthValidation("First name", 3, 30),
        last_name: StringLengthValidation("Last name", 3, 30),
        city: KeyRequired("City"),
        // country_code: Yup.number()
        //   .positive()
        //   .integer()
        //   .test(
        //     "minlen",
        //     "Country code must be 1 digits or more",
        //     (val) => val !== undefined && val.toString().length >= 1
        //   )
        //   .test(
        //     "maxlen",
        //     "Country code must be 3 digits or less",
        //     (val) => val !== undefined && val.toString().length <= 3
        //   )
        //   .required("Country code is required"),
        mobile_number: LengthRequired("Mobile number", 10),
        email: EmailRequired("Email address"),
        role: Yup.number()
          .typeError("Role is required")
          .required("Role is required")
          .oneOf(
            [
              // UserRole["Super Admin"],
              UserRole.Admin,
              UserRole.Basic,
              UserRole.Manager,
            ],
            "You must select role"
          ),
      }),
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        setPending(true);
        console.log(values);
        addU(values);
      },
    });

  const addU = async (values: UserDataForm) => {
    try {
      await dispatch(addUser(values)).unwrap();
      toast.success("User is added successfully");
      setShow(false);
      resetForm();
    } catch (error: any) {
      toast.error(error.message || "Failed");
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    resetForm();
  }, [resetForm, show]);
  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShow(false)}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="first_name"
                  labelText="First Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.first_name}
                  touched={touched.first_name}
                  errors={errors.first_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="last_name"
                  labelText="Last Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.last_name}
                  touched={touched.last_name}
                  errors={errors.last_name}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="country_code"
                  labelText="Country code"
                  required={true}
                  handleChange={handleChange}
                  value={values.country_code}
                  touched={touched.country_code}
                  errors={errors.country_code}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="mobile_number"
                  labelText="Mobile number"
                  required={true}
                  handleChange={handleChange}
                  value={values.mobile_number}
                  touched={touched.mobile_number}
                  errors={errors.mobile_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email"
                  labelText="Email"
                  required={true}
                  handleChange={handleChange}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomSelectWithLabel
                  id="role"
                  labelText="Role"
                  required={true}
                  handleChange={handleChange}
                  value={values.role}
                  touched={touched.role}
                  errors={errors.role}
                >
                  <>
                    <option>Select role</option>
                    {/* <option value="1">{UserRole[1]}</option> */}
                    <option value="2">{UserRole[2]}</option>
                    <option value="4">{UserRole[4]}</option>
                    <option value="8">{UserRole[8]}</option>
                  </>
                </CustomSelectWithLabel>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={() => setShow(false)}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default UserCanvas;
