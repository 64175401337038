import { Form } from "react-bootstrap";
import FormErrorFeedback from "./validations/FormErrorFeedback";
import Select from "react-select";

interface CustomReactSelectWithLabelProps {
  id: string;
  required?: boolean;
  labelText?: string;
  placeholder?: string;
  value?: any;
  touched?: boolean;
  errors?: string | string[];
  options: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  // setFieldTouched?: any;
}
const CustomReactSelectWithLabel = ({
  id,
  labelText,
  placeholder,
  required,
  handleChange,
  // setFieldTouched,
  value = "",
  touched,
  errors,
  options,
}: CustomReactSelectWithLabelProps) => (
  <>
    {labelText ? (
      <Form.Label htmlFor={id}>
        {labelText}
        {required ? <span className="text-danger">*</span> : null}
      </Form.Label>
    ) : null}
    {/* <Form.Select
      className="form-control"
      aria-label={labelText}
      id={id}
      value={value}
      onChange={handleChange}
      isValid={touched && !errors}
      isInvalid={touched && !!errors}
    >
      {children}
    </Form.Select> */}

    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor:
            touched && !!errors
              ? "var(--bs-form-invalid-border-color)"
              : touched && !errors
              ? "var(--bs-form-valid-border-color)"
              : state.isFocused
              ? "inherit"
              : "inherit",
        }),
      }}
      isClearable
      placeholder={placeholder ? "Select " + placeholder : undefined}
      className={
        touched && !!errors
          ? "is-invalid "
          : touched && !errors
          ? "is-valid "
          : ""
      }
      aria-label={labelText}
      id={id}
      // name={id}
      options={options}
      value={value}
      // onBlur={() => setFieldTouched?.(id, true)}
      // onInputChange={ () => 
      //   setFieldTouched?.(id, true, true)}
      onChange={(e) =>{
        // setFieldTouched?.(id, true);
        handleChange({
          target: { name: id, value: e?.value },
        })
      }
      }
      //   isValid={touched && !errors}
      //   isInvalid={touched && !!errors}
    />
    {touched && errors ? (
      <FormErrorFeedback id={id} label={labelText} errors={errors} />
    ) : null}
  </>
);

export default CustomReactSelectWithLabel;
