import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  deleteDriver,
  fetchDrivers,
  refreshDriverList,
  // listDrivers,
} from "src/store/reducers/drivers";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import ConfirmationModal from "src/layouts/modals/ConfirmationModal";
import { toast } from "react-toastify";
import DriverFormCanvas from "./DriverFormCanvas";
import { DATA_LIMIT } from "src/utils/constants";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import BottomPagination from "src/jsx/customComponents/pagination/BottomPagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";

const ListDrivers = () => {
  const dispatch = useAppDispatch();
  const driverList = useAppSelector((state) => state.drivers.data);
  const driverListStatus = useAppSelector((state) => state.drivers.status);
  const [editId, setEditId] = useState("");
  const [deleteDriverId, setDeleteDriverId] = useState<string>();
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  // const sort = 5;

  const [pending, setPending] = useState(false);

  const {
    handleChange,
    values,
    handleSubmit,
    // resetForm,
    // setFieldValue,
    // touched,
    // errors,
  } = useFormik({
    initialValues: {
      keyword: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      keyword: Yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      dispatch(fetchDrivers({ page, keyword: values.keyword }));
      setPending(false);
      // setSearchFormData(values);
    },
  });

  const retry = useCallback(() => {
    // dispatch(fetchDrivers(page));
    dispatch(fetchDrivers({ page, keyword: "" }));
  }, [dispatch, page]);

  const setPageAndFetch = useCallback(
    (page: number) => {
      setPage(page);
      retry();
    },
    [retry]
  );

  const cancelDelete = () => {
    setDeleteDriverId(undefined);
  };

  const confirmDelete = useCallback(async () => {
    if (deleteDriverId) {
      try {
        const data = await dispatch(deleteDriver(deleteDriverId)).unwrap();
        toast.success("Driver is deleted successfully");
        setDeleteDriverId(undefined);
        setPage(1);
        retry();
      } catch (e: any) {
        FormikAPIErrorHandler(e.message, undefined, toast.error);
      }
    }
  }, [deleteDriverId, dispatch, retry]);

  useEffect(() => {
    if (driverListStatus === "idle") {
      retry();
    }
  }, [retry, driverListStatus, page, values.keyword]);

  useEffect(() => {
    return () => {
      dispatch(refreshDriverList());
    };
  }, [dispatch]);

  return (
    <>
      {/* <MainPagetitle
        mainTitle="Drivers"
        pageTitle="Drivers"
        parentTitle="Home"
      /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <form onSubmit={handleSubmit}>
                <div className="row container-fluid">
                  <div className="col-xl-3 mb-3">
                    <CustomInputWithLabel
                      id="keyword"
                      placeholder={"Search"}
                      handleChange={handleChange}
                      value={values.keyword}
                    />
                  </div>
                  <div className="col-xl-2 mb-3">
                    <Button
                      className="form-control"
                      disabled={pending}
                      type="submit"
                    >
                      {pending ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Drivers</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setEditId("Add")}
                >
                  + Add Driver
                </Link>
              </div>
              <div className="card-body pb-sm-0">
                {driverListStatus === "success" ? (
                  driverList?.results && driverList?.results?.length > 0 ? (
                    <div className="table-responsive active-projects">
                      {/* <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                      <h4 className="heading mb-0">Drivers</h4>
                    </div> */}
                      <div
                        id="projects-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="projects-tbl"
                          className={
                            driverList?.count <= DATA_LIMIT
                              ? "table table-hover dataTable no-footer"
                              : "table table-hover dataTable no-footer mb-sm-0"
                          }
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Contact No.</th>
                              <th>City</th>
                              <th>Cab vendor</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {driverList?.results?.map((driver, index) => (
                              <tr key={driver.id}>
                                <td>{driver.name}</td>
                                <td>{driver.contact_number}</td>
                                <td>{driver.city}</td>
                                <td>
                                  {driver.cab_vendor?.contact_person_name || ""}
                                </td>
                                <td>
                                  <NavLink
                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                    to={`/drivers/view/${driver.id}`}
                                    title="View"
                                  >
                                    <i className={`fas fa-paper-plane`} />
                                    {/* <span>View</span> */}
                                  </NavLink>{" "}
                                  <NavLink
                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                    onClick={() => setEditId(driver.id)}
                                    to={"#"}
                                    title="Edit"
                                  >
                                    <i className={`fas fa-pen`} />
                                    {/* <span>Edit</span> */}
                                  </NavLink>{" "}
                                  <NavLink
                                    className="btn btn-danger shadow btn-xs sharp"
                                    onClick={() => {
                                      setDeleteDriverId(driver.id);
                                    }}
                                    to={`#`}
                                    title="Delete"
                                  >
                                    <i className={`fas fa-x`} />
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {driverList?.count ? (
                          <BottomPagination
                            count={driverList?.count}
                            page={page}
                            setPageAndFetch={setPageAndFetch}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>NA</strong>
                    </div>
                  )
                ) : driverListStatus === "pending" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : driverListStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={typeof deleteDriverId !== "undefined"}
        handleClose={cancelDelete}
        confirmClose={confirmDelete}
      />
      <DriverFormCanvas
        show={editId !== ""}
        hide={() => setEditId("")}
        editId={editId}
        Title={editId === "Add" ? "Add Driver" : "Edit Driver"}
        driverData={driverList?.results?.find((driver) => driver.id === editId)}
      />
    </>
  );
};

export default ListDrivers;
