import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import { APIResponseCover } from "./auth.d";
import { readAPIErrorMessage } from "src/utils/errorUtil";
import { API_STATUS } from "src/utils/constants";
import { BOOKING_STATUS } from "./bookings";

export type CalDataArr =
  | {
      id: null;
      name: null;
      arrival_date: null;
      departure_date: null;
    }
  | {
      id: string;
      name: string;
      arrival_date: string;
      departure_date: string;
    };

type CalData = Record<string, CalDataArr[]>;

export interface MonthReport {
  month: string;
  deals_closed: number;
  revenue: number;
  profit: number;
}

export interface UserReport {
  user_id: string;
  username: string;
  deals_closed: number;
  revenue: number;
  profit: number;
}

export interface CompanyReport {
  company_name: string;
  deals_closed: number;
  revenue: number;
  profit: number;
}

export interface ReportData {
  month_report: MonthReport[];
  user_report: UserReport[];
  company_report: CompanyReport[];
}

export interface StatusReport {
  status: Omit<BOOKING_STATUS, BOOKING_STATUS.CANCELLED>;
  count: number;
}

export interface Revenue {
  total_bookings: number;
  total_revenue: number;
  total_profit: number;
}
export interface DashboardData {
  status_report: StatusReport[];
  revenue: Revenue[];
}

export interface ReportsState {
  data?: ReportData;
  status: API_STATUS;
  dashboardDataObj: {
    data?: DashboardData;
    status: API_STATUS;
  };
  calDataObj: {
    data?: CalData;
    status: API_STATUS;
  };
}

const initialState: ReportsState = {
  data: undefined,
  status: "idle",
  dashboardDataObj: {
    data: undefined,
    status: "idle",
  },
  calDataObj: {
    data: undefined,
    status: "idle",
  },
};

export const usersSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    refreshReportList: (state) => {
      state.status = "idle";
    },
    refreshDashboardData: (state) => {
      state.dashboardDataObj.status = "idle";
    },
    refreshCalendarData: (state) => {
      state.dashboardDataObj.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchReports.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchReports.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchDashboardData.pending, (state, action) => {
      state.dashboardDataObj.status = "pending";
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.dashboardDataObj.status = "success";
      state.dashboardDataObj.data = action.payload;
    });
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      state.dashboardDataObj.status = "error";
    });

    builder.addCase(fetchCalData.pending, (state, action) => {
      state.calDataObj.status = "pending";
    });
    builder.addCase(fetchCalData.fulfilled, (state, action) => {
      state.calDataObj.status = "success";
      state.calDataObj.data = action.payload;
    });
    builder.addCase(fetchCalData.rejected, (state, action) => {
      state.calDataObj.status = "error";
    });
  },
});

export interface ReportFormData {
  start_date: string | null;
  end_date: string | null;
  user_id?: string;
}
export const fetchReports = createAsyncThunk<
  ReportData,
  ReportFormData | undefined,
  { state: RootState }
>("reports/fetch", async (data, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<ReportData>,
      AxiosResponse<APIResponseCover<ReportData>, ReportFormData>
    >("ReportsApi/business-report/?format=json", data);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

// export interface ReportHotelFormData {
//   start_date: string | null;
//   end_date: string | null;
//   hotel_id?: string;
// }

// export const fetchHotelReports = createAsyncThunk<
//   ReportData,
//   ReportHotelFormData | undefined,
//   { state: RootState }
// >("reports-hotel/fetch", async (data, thunkAPI) => {
//   try {
//     const response = await axiosInstance.post<
//       APIResponseCover<ReportData>,
//       AxiosResponse<APIResponseCover<ReportData>, ReportHotelFormData>
//     >("ReportsApi/hotel-booking-report/?format=json", data);
//     // console.log(response.data);
//     return thunkAPI.fulfillWithValue(response.data.data);
//   } catch (e: unknown) {
//     if (e instanceof AxiosError) {
//       throw new Error(readAPIErrorMessage(e));
//     } else if (e instanceof Error) {
//       throw new Error(e.message);
//     }
//     return thunkAPI.rejectWithValue(false);
//   }
// });

export const fetchDashboardData = createAsyncThunk<
  DashboardData,
  void,
  { state: RootState }
>("reports/dashboard", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<DashboardData>,
      AxiosResponse<APIResponseCover<DashboardData>>
    >("ReportsApi/dashboard-overview/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export interface CalFormData {
  from_date: string | undefined;
  to_date: string | undefined;
}

export const fetchCalData = createAsyncThunk<
  CalData,
  CalFormData | undefined,
  { state: RootState }
>("reports/cal", async (data, thunkAPI) => {
  try {
    // const data = {"from_date":"2024-01-01", "to_date":"2024-01-31"};
    const response = await axiosInstance.post<
      APIResponseCover<CalData>,
      AxiosResponse<APIResponseCover<CalData>, CalFormData>
    >("ReportsApi/list-booking-calendar/?format=json", data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshReportList, refreshDashboardData, refreshCalendarData } =
  usersSlice.actions;

export default usersSlice.reducer;
