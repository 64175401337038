import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  AgentData,
  AgentDataForm,
  AgentEditDataForm,
  addAgent,
  refreshAgentList,
  updateAgent,
  // viewAgent,
} from "src/store/reducers/agents";
import {
  EmailRequired,
  // KeyRequired,
  // LengthRangeRequired,
  LengthRequired,
  // RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";

type IAgentFormCanvas = {
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
  agentData?: AgentData;
};

// const defaultAgentFormData = {
//   name: "",
//   address: "",
//   // pincode: "",
//   city: "",
//   // country: "",
//   // experience: "",
//   contact_number: "",
//   email_id: "",
//   alternate_number: "",
//   employees_size: "",
//   notes: "",
//   company_name: "",
//   // status: "",
// };

export const nullValueKeysBooking = [
  "alternate_number",
  "employees_size",
] as const;

const AgentFormCanvas = ({
  Title,
  show,
  hide,
  editId,
  agentData,
}: IAgentFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  // const [agentData, setData] =
  //   useState<Omit<AgentDataForm, "id">>(defaultAgentFormData);
  const {
    handleChange,
    setErrors,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      // role: agentData?.role,
      name: agentData?.name || "",
      address: agentData?.address || "",
      // pincode: agentData?.pincode,
      city: agentData?.city || "",
      // country: agentData?.country,
      // experience: agentData?.experience,
      contact_number: agentData?.contact_number || "",
      email_id: agentData?.email_id || "",
      alternate_number: agentData?.alternate_number || "",
      employees_size: agentData?.employees_size || "",
      company_name: agentData?.company_name || "",
      notes: agentData?.notes || "",
      // status: agentData?.status,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: StringLengthValidation("Name", 3, 30),
      company_name: StringLengthValidation("Company name", 3, 30),
      address: StringLengthValidation("Address", 5, 1000, false),
      // pincode: KeyRequired("Pincode"),
      // country: StringLengthValidation("Country", 3, 30),
      // status: RangeRequired("Status", 1, 5),
      // employees_size: LengthRangeRequired("Employees size", 1, 3, false),
      // experience: LengthRangeRequired("Experience", 1, 7),
      contact_number: LengthRequired("Contact number", 10),
      alternate_number: LengthRequired("Alternative contact number", 10, false),
      // city: KeyRequired("City"),
      // notes: KeyRequired("Notes"),
      email_id: EmailRequired("Email address", false),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const finalValues: Omit<AgentDataForm, "id"> = { ...values };
      nullValueKeysBooking.forEach((k) => {
        if (!finalValues[k]) {
          finalValues[k] = null;
        }
      });
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...finalValues, id: editId };
        editU(formValue);
      } else {
        setPending(true);
        // const formValue = { ...values, id: editId };
        addU(finalValues);
      }
    },
  });

  const handleHide = useCallback(() => {
    // setData(defaultAgentFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<AgentDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addAgent(values)).unwrap();
        handleHide();
        toast.success("Travel Agent is added successfully");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  const editU = useCallback(
    async (values: AgentEditDataForm) => {
      try {
        await dispatch(updateAgent(values)).unwrap();
        toast.success("Travel Agent is updated successfully");
        dispatch(refreshAgentList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewAgent(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //             // pincode: data.pincode.toString(),
  //             // status: data.status.toString(),
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  useEffect(() => {
    resetForm();
    // setData(defaultAgentFormData);
  }, [resetForm, show]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="name"
                  labelText="Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.name}
                  touched={touched.name}
                  errors={errors.name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="company_name"
                  labelText="Company Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.company_name}
                  touched={touched.company_name}
                  errors={errors.company_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email_id"
                  labelText="Email"
                  // required={true}
                  handleChange={handleChange}
                  value={values.email_id}
                  touched={touched.email_id}
                  errors={errors.email_id}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="pincode"
                  labelText="Pincode"
                  required={true}
                  handleChange={handleChange}
                  value={values.pincode}
                  touched={touched.pincode}
                  errors={errors.pincode}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="experience"
                  labelText="Experience"
                  required={true}
                  handleChange={handleChange}
                  value={values.experience}
                  touched={touched.experience}
                  errors={errors.experience}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_number"
                  labelText="Contact number"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_number}
                  touched={touched.contact_number}
                  errors={errors.contact_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="alternate_number"
                  labelText="Alternate number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.alternate_number}
                  touched={touched.alternate_number}
                  errors={errors.alternate_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="employees_size"
                  labelText="Employees size"
                  // required={true}
                  handleChange={handleChange}
                  value={values.employees_size}
                  touched={touched.employees_size}
                  errors={errors.employees_size}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  // required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="country"
                  labelText="Country"
                  required={true}
                  handleChange={handleChange}
                  value={values.country}
                  touched={touched.country}
                  errors={errors.country}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="address"
                  labelText="Address"
                  // required={true}
                  handleChange={handleChange}
                  value={values.address}
                  touched={touched.address}
                  errors={errors.address}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="status"
                  labelText="Status"
                  required={true}
                  handleChange={handleChange}
                  value={values.status}
                  touched={touched.status}
                  errors={errors.status}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default AgentFormCanvas;
