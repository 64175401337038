import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT, GainMargin } from "src/utils/constants";
import { UserData } from "./users";

// export interface UserViewData {
//   uuid: string;
// }

// export interface UserEditDataForm {
//   uuid: string;
//   role: string;
//   first_name: string;
//   last_name: string;
//   country_code: string;
//   mobile_number: string;
//   email: string;
//   city: string;
// }

export enum TransactionType {
  "Inwards" = 1,
  "OutWards",
}

export enum PartnerType {
  "Agent" = 1,
  "Cab vendor",
  "Driver",
  "Hotel",
  "Taxi",
}

export interface BookingTransactForm {
  amount: string;
  transaction_type?: TransactionType;
  // partner_type?: PartnerType;
  agent?: string;
  driver?: string;
  taxi?: string;
  booking_hotel?: string; // hotel
  booking_cabvendor?: string; //cabvendor
  booking_id: string;
  notes: string;
}

export interface BookingHotelForm {
  // id: string;
  // hotel_id: string;
  hotel: string;
  room_category: string;
  checkin_date: string;
  checkout_date: string;
  hotel_price: string;
  advance_amount_paid: string;
  payment_notification_date: string | null;
  booking: string;
  meal_plan: string;
  notes: string;
}

export interface BookingHotelEditForm extends BookingHotelForm {
  id: string;
}

export interface BookingCabForm {
  // id: string;
  driver: string;
  cab_vendor: string;
  arrival_place: string;
  arrival_time: string;
  vehicle_number: string;
  vehicle_type: string;

  cab_price: string;
  other_cab_expenses: string;
  trip_details: string;
  opening_kms: string;
  closing_kms: string;
  // travel_agent: string;
  booking: string;
  dropping_place: string;
  taxi: string;
}
export interface BookingCabEditForm extends BookingCabForm {
  id: string;
}

export interface BookingDataEditForm {
  id: string;
  agent_commission: string | null;
  amount_received: string | null;
  arrival_date: string;
  customer_name: string;
  customer_number: string;
  email: string | null;
  departure_date: string;
  flight_tickets: string | null;
  next_payment_date: string | null;
  // profit: string;
  total_pax: string | null;
  total_children: string | null;
  total_amount: string;
  travel_agent_id: string;
}

// : Array<
//   keyof Pick<
//   BookingDataForm,
//   | "agent_commission"
//   | "amount_received"
//   | "email"
//   | "flight_tickets"
//   | "next_payment_date"
//   | "total_pax"
//   | "total_children"
// >
// >
export const nullValueKeysBooking = [
  "agent_commission",
  "amount_received",
  "email",
  "flight_tickets",
  "next_payment_date",
  "total_pax",
  "total_children",
] as const;

export interface BookingDataForm {
  // CAB
  // arrival_place: string;
  // arrival_time: string;
  // driver_id: string;
  // cab_price: string;
  // other_cab_expenses: string;
  // vehicle_type: string;
  // vehicle_number: string;
  // trip_details: string;

  // HOTEL
  // hotel: string;
  // room_category: string;
  // checkin_date: string;
  // check_out_date: string;
  // hotel_price: string;
  // advance_amount_paid: string;
  // payment_notification_date: string;

  agent_commission: string | null;
  amount_received: string | null;
  arrival_date: string;
  customer_name: string;
  customer_number: string;
  email: string | null;
  departure_date: string;
  flight_tickets: string | null;
  next_payment_date: string | null;
  // profit: string;
  total_pax: string | null;
  total_children: string | null;
  total_amount: string;
  travel_agent_id: string;
  customer_address: string;
  customer_gstn: string;
  notes: string;
}

export interface CabVendor {
  id: string;
  contact_person_name: string;
  email_id: string;
  company_name: string;
  city: any;
  contact_number: string;
  alternate_number: string;
  notes: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
}

export interface Taxi {
  id: string;
  vehicle_number: string;
  vehicle_name: string;
  notes: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
  agent: string;
}

export interface Driver {
  id: string;
  name: string;
  city: string;
  driver_license_number: string;
  contact_number: string;
  alternate_number: string;
  cab_number: string;
  rating: number;
  notes: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
  cab_vendor: string;
}

export interface Hotel {
  id: string;
  name: string;
  address: string;
  city: string;
  category: number;
  contact_number: string;
  notes: any;
  email_id: string;
  contact_person_name: string;
  reservation_contact_number: string;
  account_details: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
}

export interface TravelAgent {
  id: string;
  name: string;
  company_name: string;
  address: string;
  city: string;
  contact_number: string;
  email_id: string;
  alternate_number: string;
  employees_size: string;
  notes: string;
  status: number;
  created_date: string;
  updated_date: string;
  company: string;
  user: string;
}

export interface BookingCab {
  id: string;
  // driver_id: string;
  arrival_place: string;
  dropping_place: string;
  arrival_time: string;
  dropping_time: string;
  advance_amount_paid: string;
  cab_price: string;
  other_cab_expenses: string;
  vehicle_number: string;
  vehicle_type: string;
  trip_details: string;
  opening_kms: string;
  closing_kms: string;
  // cab_vendor_id: string;
  // created: string;
  // updated: string;
  // booking: string;
  // taxi_id: string;
  cab_vendor?: CabVendor;
  taxi?: Taxi;
  driver?: Driver;
}

export interface BookingHotel {
  id: string;
  hotel: Hotel;
  // hotel_id: string;
  room_category: string;
  checkin_date: string;
  checkout_date: string;
  hotel_price: string;
  advance_amount_paid: string;
  payment_notification_date: string | null;
  meal_plan: string;
  notes: string;
  created: string;
  updated: string;
  booking: string;
}

export enum BOOKING_STATUS {
  COMPLETED,
  UPCOMING,
  "IN PROGRESS",
  CANCELLED,
}

export enum BOOKING_STATUS_LABEL {
  "Completed",
  "Upcoming",
  "In Progress",
  "Cancelled",
}

export enum BOOKING_STATUS_LABEL_COLOR {
  "var(--secondary)",
  "var(--primary)",
  "#3AC977",
}

export interface BookingTransaction {
  id: string;
  // amount_received: number;
  amount: number;
  notes: string;
  transaction_type?: TransactionType;
  agent?: TravelAgent;
  cabvendor?: CabVendor;
  driver?: Driver;
  hotel?: Hotel;
  taxi?: Taxi;
  // partner_type?: PartnerType;
  created_date: string;
  updated_date: string;
  booking: string;
  company: string;
  user: string;
}

export interface BookingData {
  id: string;
  customer_name: string;
  customer_number: number;
  company: string;
  user: UserData;
  email: string | null;
  booking_date: string;
  arrival_date: string;
  departure_date: string;
  total_pax: number | null;
  total_children: string | null;
  total_amount: number;
  amount_received: number | null;
  next_payment_date: string | null;
  agent_commission: string | null;
  flight_tickets: string | null;
  profit: string;
  created: string;
  updated: string;
  status: number;
  gain_margin: number;
  gain_margin_colour: GainMargin;
  bookingcab_set: BookingCab[];
  booking_hotels: BookingHotel[];
  // travel_agent_id: string;
  travel_agent: TravelAgent;
  booking_transactions: BookingTransaction[];
  customer_address: string;
  customer_gstn: string;
  notes: string;
}

export interface BookingsTodayAndLatest {
  latest_ten_bookings: BookingData[];
  today_bookings: BookingData[];
}

export interface BookingsState {
  data?: Pagination<BookingData>;
  // data?: BookingData[];
  status: API_STATUS;
  view: {
    data?: BookingData;
    status: API_STATUS;
  };
}

const initialState: BookingsState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  status: "idle",
  view: {
    data: undefined,
    status: "idle",
  },
};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    resetBookingView(state) {
      state.view.status = "idle";
    },
    resetBookingList(state) {
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchBookings.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchBookings.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchBookings.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(addBooking.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(editBooking.fulfilled, (state, action) => {
      state.status = "idle";
      state.view.status = "idle";
    });
    builder.addCase(addHotelBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(editHotelBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(addCabBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(editCabBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(addBookingTransaction.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(fetchBookingDetail.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(fetchBookingDetail.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(fetchBookingDetail.rejected, (state, action) => {
      state.view.status = "error";
    });
    builder.addCase(deleteHotelBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
    builder.addCase(deleteCabBooking.fulfilled, (state, action) => {
      state.view.status = "idle";
      state.status = "idle";
    });
  },
});

export interface BookingSearchOption {
  // booking_id_: string
  filter_type?: string;
  start_date?: string;
  end_date?: string;
  driver_id?: string;
  taxi_id?: string;
  travel_agent_id?: string;
  cab_vendor_id?: string;
  hotel_id?: string;
  user_id?: string;
  gain_margin_colour?: "green" | "red";
  keyword?: string;
}

export const fetchPendingPaymentBookings = createAsyncThunk<
  BookingData[],
  void,
  { state: RootState }
>("bookings/pendingPayment", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<BookingData[]>,
      AxiosResponse<PartnerAPIResponseCover<BookingData[]>>,
      BookingSearchOption
    >(`ReportsApi/pending-payment-report/?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const fetchTodayBookings = createAsyncThunk<
  BookingsTodayAndLatest,
  void,
  { state: RootState }
>("bookings/today", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<BookingsTodayAndLatest>,
      AxiosResponse<PartnerAPIResponseCover<BookingsTodayAndLatest>>,
      BookingSearchOption
    >(`ReportsApi/today-booking-report/?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const fetchBookings = createAsyncThunk<
  Pagination<BookingData>,
  { page: number; filter?: BookingSearchOption },
  { state: RootState }
>("bookings/fetch", async ({ page = 1, filter = {} }, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<Pagination<BookingData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<BookingData>>>,
      BookingSearchOption
    >(
      `BookingApi/list-bookings/?limit=${DATA_LIMIT}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`,
      filter
    );
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const downloadBookingVoucher = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("bookings/fetch", async (id, thunkAPI) => {
  try {
    if (!id) {
      throw new Error("id missing");
    }
    const response = await axiosInstance.get<
      Blob
    >(`CompanySettings/travel-voucher/?booking_id=${id}`, {responseType: 'blob'});
    const href = window.URL.createObjectURL(response.data)
    return thunkAPI.fulfillWithValue(href);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchBookingsMaster = createAsyncThunk<
  Pagination<BookingData>,
  { page: number; filter?: BookingSearchOption, source: any },
  { state: RootState }
>("bookings/master-fetch", async ({ page = 1, filter = {}, source }, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<Pagination<BookingData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<BookingData>>>,
      BookingSearchOption
    >(
      `BookingApi/list-bookings/?limit=${DATA_LIMIT}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`,
      filter,
      {cancelToken: source.token}
    );
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const fetchBookingsByStatus = createAsyncThunk<
  BookingData[],
  { status: BOOKING_STATUS; page: number },
  { state: RootState }
>("bookings/status/fetch", async ({ status, page }, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<Pagination<BookingData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<BookingData>>>
    >(
      `BookingApi/list-bookings/?limit=${DATA_LIMIT}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`,
      { status }
    );
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const fetchBookingDetail = createAsyncThunk<
  BookingData,
  string | undefined,
  { state: RootState }
>("booking/fetch", async (id, thunkAPI) => {
  try {
    if (!id) {
      throw new Error("id missing");
    }
    const response = await axiosInstance.post<
      PartnerAPIResponseCover<Pagination<BookingData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<BookingData>>>
    >("BookingApi/list-bookings/?format=json", { booking_id: id });
    // console.log(response.data);
    if (response?.data?.data.results.length === 0) {
      return thunkAPI.rejectWithValue(false);
    }
    return thunkAPI.fulfillWithValue(response?.data?.data?.results?.[0]);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

// export const updateUser = createAsyncThunk(
//   "bookings/update",
//   async (data: UserEditDataForm, thunkAPI) => {
//     try {
//       const response = await axiosInstance.post<
//         APIResponseCover<Record<never, never>>,
//         AxiosResponse<APIResponseCover<Record<never, never>>, UserEditDataForm>,
//         UserEditDataForm
//       >("UsersApi/update-user/?format=json", {
//         ...data,
//         token: thunkAPI.getState().auth.data.token,
//       });
//       console.log(response.data);
//       return thunkAPI.fulfillWithValue({ status: "pending" });
//     } catch (e) {
//       if (e instanceof AxiosError) {
//         throw new Error(readAPIErrorMessage(e));
//       } else {
//         throw new Error(e.message);
//       }
//       // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
//       // console.log(e.response.data.data.data[0])
//     }
//   }
// );

// export const viewUser = createAsyncThunk(
//   "bookings/view",
//   async (data: UserViewData, thunkAPI) => {
//     try {
//       const response = await axiosInstance.post<
//         APIResponseCover<Record<never, never>>,
//         AxiosResponse<APIResponseCover<Record<never, never>>, UserViewData>,
//         UserViewData
//       >("UsersApi/view-user/?format=json", {
//         ...data,
//         token: thunkAPI.getState().auth.data.token,
//       });
//       console.log(response.data);
//       return thunkAPI.fulfillWithValue({ status: "pending" });
//     } catch (e) {
//       if (e instanceof AxiosError) {
//         throw new Error(readAPIErrorMessage(e));
//       } else {
//         throw new Error(e.message);
//       }
//       // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
//       // console.log(e.response.data.data.data[0])
//     }
//   }
// );

export const editBooking = createAsyncThunk<
  { status: string },
  BookingDataEditForm,
  { state: RootState }
>("bookings/edit", async (data: BookingDataEditForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        BookingDataEditForm
      >,
      BookingDataEditForm
    >("BookingApi/booking/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const addBooking = createAsyncThunk<
  { status: string; data: string },
  BookingDataForm,
  { state: RootState }
>("bookings/add", async (data: BookingDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<string>,
      AxiosResponse<APIResponseCover<string>, BookingDataForm>,
      BookingDataForm
    >("BookingApi/booking/?format=json", {
      // >("BookingApi/add-booking/?format=json", {
      ...data,
    });
    return thunkAPI.fulfillWithValue({
      status: "idle",
      data: response.data.data,
    });
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const addHotelBooking = createAsyncThunk<
  { status: string },
  BookingHotelForm,
  { state: RootState }
>("bookings/hotel", async (data: BookingHotelForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, BookingHotelForm>,
      BookingHotelForm
    >("BookingApi/booking-hotel/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const editHotelBooking = createAsyncThunk<
  { status: string },
  BookingHotelEditForm,
  { state: RootState }
>("bookings/hotelEdit", async (data: BookingHotelEditForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        BookingHotelEditForm
      >,
      BookingHotelEditForm
    >("BookingApi/booking-hotel/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const deleteHotelBooking = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("bookings/hotelDelete", async (hotel_id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        BookingHotelEditForm
      >,
      BookingHotelEditForm
    >(`BookingApi/booking-hotel/${hotel_id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const deleteCabBooking = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("bookings/cabDelete", async (hotel_id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        BookingHotelEditForm
      >,
      BookingHotelEditForm
    >(`BookingApi/booking-cab/${hotel_id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const addCabBooking = createAsyncThunk<
  { status: string },
  BookingCabForm,
  { state: RootState }
>("bookings/cab", async (data: BookingCabForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, BookingCabForm>,
      BookingCabForm
    >("BookingApi/booking-cab/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
      // throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const editCabBooking = createAsyncThunk<
  { status: string },
  BookingCabEditForm,
  { state: RootState }
>("bookings/cabEdit", async (data: BookingCabEditForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, BookingCabEditForm>,
      BookingCabEditForm
    >("BookingApi/booking-cab/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const addBookingTransaction = createAsyncThunk<
  { status: string },
  BookingTransactForm,
  { state: RootState }
>("bookings/addTransaction", async (data: BookingTransactForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        BookingTransactForm
      >,
      BookingTransactForm
    >("BookingApi/add-booking-transaction/?format=json", {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const { resetBookingView, resetBookingList } = bookingsSlice.actions;

export default bookingsSlice.reducer;
