import axios, { AxiosError } from "axios";
import { refreshAgentList } from "src/store/reducers/agents";
import { logout } from "src/store/reducers/auth";
import {
  resetBookingList,
  resetBookingView,
} from "src/store/reducers/bookings";
import { refreshCabVendorList } from "src/store/reducers/cabVendors";
import { refreshDriverList } from "src/store/reducers/drivers";
import { refreshHotelList } from "src/store/reducers/hotels";
import {
  refreshDashboardData,
  refreshReportList,
} from "src/store/reducers/reports";
import { refreshUserList } from "src/store/reducers/users";
let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

// Set config defaults when creating the instance
const instance = axios.create({
  // baseURL: "http://13.201.101.171:8000/",
  baseURL: "https://api.bookingbyte.com/",
});
instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.request.use(function (config) {
  const token = store.getState()?.auth?.data?.token;
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

instance.interceptors.response.use(
  (r) => r,
  (e: AxiosError) => {
    const token = store.getState()?.auth?.data?.token;
    if (
      token &&
      (e.response?.status === 401 || e.response?.data?.code === "401")
    ) {
      store.dispatch(logout());

      store.dispatch(refreshDashboardData());

      store.dispatch(refreshUserList());
      store.dispatch(resetBookingView());
      store.dispatch(resetBookingList());

      store.dispatch(refreshAgentList());
      store.dispatch(refreshDriverList());
      store.dispatch(refreshHotelList());
      store.dispatch(refreshCabVendorList());

      store.dispatch(refreshReportList());
    }
    return Promise.reject(e);
  }
);
// Alter defaults after instance has been created
//   instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
export default instance;
