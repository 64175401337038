import { Link, NavLink, useNavigate } from "react-router-dom";
import BookingByte from "src/layouts/svgIcons/BookingByte";
const LoginHeader = () => (
  <div className="logo-header">
    <Link to={"#"} className="logo">
      <BookingByte />
    </Link>
    {/* <Link to={"#"} className="logo">
      <img src={LogoWhite} alt="" className="width-230 dark-logo" />
    </Link> */}
  </div>
);

export default LoginHeader;
