import React, { createContext, useEffect, useReducer } from "react";
// import { dezThemeSet } from "./ThemeDemo";

type IStyle = { value: string; label: string };

type IStyleOptions =
  | "layoutOption"
  | "sideBarOption"
  | "backgroundOption"
  | "sidebarpositions"
  | "headerPositions"
  | "containerPosition"
  | "directionPosition"
  | "fontFamily";

// layout
const layoutOption: IStyle[] = [
  { value: "vertical", label: "Vertical" },
  { value: "horizontal", label: "Horizontal" },
];
const sideBarOption: IStyle[] = [
  { value: "compact", label: "Compact" },
  { value: "full", label: "Full" },
  { value: "mini", label: "Mini" },
  { value: "modern", label: "Modern" },
  { value: "overlay", label: "Overlay" },
  { value: "icon-hover", label: "Icon-hover" },
];
const backgroundOption: IStyle[] = [
  { value: "light", label: "Light" },
  { value: "dark", label: "Dark" },
];
const sidebarpositions: IStyle[] = [
  { value: "fixed", label: "Fixed" },
  { value: "static", label: "Static" },
];
const headerPositions: IStyle[] = [
  { value: "fixed", label: "Fixed" },
  { value: "static", label: "Static" },
];
const containerPosition: IStyle[] = [
  { value: "wide-boxed", label: "Wide Boxed" },
  { value: "boxed", label: "Boxed" },
  { value: "wide", label: "Wide" },
];
const colors: string[] = [
  "color_1",
  "color_2",
  "color_3",
  "color_4",
  "color_5",
  "color_6",
  "color_7",
  "color_8",
  "color_9",
  "color_10",
  "color_11",
  "color_12",
  //"color_13",
  //"color_14",
  //"color_15",
];
const directionPosition: IStyle[] = [
  { value: "ltr", label: "LTR" },
  { value: "rtl", label: "RTL" },
];
const fontFamily: IStyle[] = [
  { value: "poppins", label: "Poppins" },
  { value: "roboto", label: "Roboto" },
  { value: "nunito", label: "Nunito" },
  { value: "opensans", label: "Open Sans" },
  { value: "HelveticaNeue", label: "HelveticaNeue" },
];

interface ITheme {
  sideBarStyle: IStyle;
  sidebarposition: IStyle;
  headerposition: IStyle;
  sidebarLayout: IStyle;
  direction: IStyle;
  primaryColor: string;
  secondaryColor: string;
  navigationHader: string;
  haderColor: string;
  sidebarColor: string;
  background: IStyle;
  containerPositionSize: IStyle;
  iconHover: string;
  menuToggle: boolean;
  windowWidth: number;
  windowHeight: number;
}

type IStyleMethodsStr =
  | "changePrimaryColor"
  | "changeSecondaryColor"
  | "changeNavigationHader"
  | "chnageHaderColor"
  | "chnageSidebarColor";

type IStyleMethodsIstyle =
  | "changeSideBarStyle"
  | "changeSideBarPostion"
  | "changeHeaderPostion"
  | "changeSideBarLayout"
  | "changeDirectionLayout"
  | "changeContainerPosition"
  | "openMenuToggle"
  | "changeBackground";
type IStyleMethods = "openMenuToggle";

type ThemeContextType = ITheme & {
  [k in IStyleMethodsIstyle]: (name: IStyle) => void;
} & { [k in IStyleMethods]: () => void } & {
  [k in IStyleOptions]: IStyle[];
} & {
  [k in IStyleMethodsStr]: (name: string) => void;
} & {
  colors: string[];
};
const initialState: ITheme = {
  sideBarStyle: { value: "full", label: "Full" },
  sidebarposition: { value: "fixed", label: "Fixed" },
  headerposition: { value: "fixed", label: "Fixed" },
  sidebarLayout: { value: "vertical", label: "Vertical" },
  direction: { value: "ltr", label: "LTR" },
  primaryColor: "color_1",
  secondaryColor: "color_1",
  navigationHader: "color_4",
  haderColor: "color_4",
  sidebarColor: "color_1",
  background: { value: "light", label: "Light" },
  containerPositionSize: { value: "wide-boxed", label: "Wide Boxed" },
  iconHover: "",
  menuToggle: false,
  windowWidth: 0,
  windowHeight: 0,
};
export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
);

const reducer = (
  previousState: typeof initialState,
  updatedState: Partial<typeof initialState>
) => ({
  ...previousState,
  ...updatedState,
});

const ThemeContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    sideBarStyle,
    sidebarposition,
    headerposition,
    sidebarLayout,
    direction,
    primaryColor,
    secondaryColor,
    navigationHader,
    haderColor,
    sidebarColor,
    background,
    containerPositionSize,
    iconHover,
    menuToggle,
    windowWidth,
    windowHeight,
  } = state;

  //const [sideBarStyle, setSideBarStyle] = useState({ value: "full", label: "Full",});
  //const [sidebarposition, setSidebarposition] = useState({ value: "fixed",	label: "Fixed",});
  //const [headerposition, setHeaderposition] = useState({ value: "fixed", label: "Fixed", });
  //const [sidebarLayout, setSidebarLayout] = useState({ value: "vertical", label: "Vertical",});
  //const [direction, setDirection] = useState({ value: "ltr", label: "LTR" });
  //const [primaryColor, setPrimaryColor] = useState("color_1");
  //const [secondaryColor, setSecondaryColor] = useState("color_1");
  //const [navigationHader, setNavigationHader] = useState("color_3");
  //const [haderColor, setHaderColor] = useState("color_3");
  //const [sidebarColor, setSidebarColor] = useState("color_1");
  //const [iconHover, setIconHover] = useState(false);
  //const [menuToggle, setMenuToggle] = useState(false);
  //const [background, setBackground] = useState({ value: "light",	label: "Light",});
  //const [containerPosition_, setcontainerPosition_] = useState({value: "wide-boxed", label: "Wide Boxed",});
  const body: any = document.querySelector("body");
  //const [windowWidth, setWindowWidth] = useState(0);
  //const [windowHeight, setWindowHeight] = useState(0);

  const changePrimaryColor = (name: string) => {
    //setPrimaryColor(name);
    dispatch({ primaryColor: name });
    body.setAttribute("data-primary", name);
  };
  const changeSecondaryColor = (name: string) => {
    //setSecondaryColor(name);
    dispatch({ secondaryColor: name });
    body.setAttribute("data-secondary", name);
  };
  const changeNavigationHader = (name: string) => {
    //setNavigationHader(name);
    dispatch({ navigationHader: name });
    body.setAttribute("data-nav-headerbg", name);
  };
  const chnageHaderColor = (name: string) => {
    //setHaderColor(name);
    dispatch({ haderColor: name });
    body.setAttribute("data-headerbg", name);
  };
  const chnageSidebarColor = (name: string) => {
    //setSidebarColor(name);
    dispatch({ sidebarColor: name });
    body.setAttribute("data-sidebarbg", name);
  };
  const changeSideBarPostion = (name: IStyle) => {
    //setSidebarposition(name);
    dispatch({ sidebarposition: name });
    body.setAttribute("data-sidebar-position", name.value);
  };
  const changeDirectionLayout = (name: IStyle) => {
    //setDirection(name);
    dispatch({ direction: name });
    body.setAttribute("direction", name.value);
    let html: any = document.querySelector("html");
    html.setAttribute("dir", name.value);
    html.className = name.value;
  };
  const changeSideBarLayout = (name: IStyle) => {
    if (name.value === "horizontal") {
      if (sideBarStyle.value === "overlay") {
        dispatch({ sidebarLayout: name });
        body.setAttribute("data-layout", name.value);
        dispatch({ sideBarStyle: { value: "full", label: "Full" } });
        body.setAttribute("data-sidebar-style", "full");
      } else {
        dispatch({ sidebarLayout: name });
        body.setAttribute("data-layout", name.value);
      }
    } else {
      dispatch({ sidebarLayout: name });
      body.setAttribute("data-layout", name.value);
    }
  };
  const changeSideBarStyle = (name: IStyle) => {
    if (sidebarLayout.value === "horizontal") {
      if (name.value === "overlay") {
        alert("Sorry! Overlay is not possible in Horizontal layout.");
      } else {
        dispatch({ sideBarStyle: name });
        dispatch({ iconHover: name.value === "icon-hover" ? "_i-hover" : "" });
        body.setAttribute("data-sidebar-style", name.value);
      }
    } else {
      dispatch({ sideBarStyle: name });
      dispatch({ iconHover: name.value === "icon-hover" ? "_i-hover" : "" });
      body.setAttribute("data-sidebar-style", name.value);
    }
  };

  const changeHeaderPostion = (name: IStyle) => {
    dispatch({ headerposition: name });
    body.setAttribute("data-header-position", name.value);
  };

  const openMenuToggle = () => {
    // sideBarStyle.value === "overly"
    dispatch({ menuToggle: !menuToggle });
    // : dispatch({ menuToggle: false });
  };

  const changeBackground = (name: IStyle) => {
    body.setAttribute("data-theme-version", name.value);
    dispatch({ background: name });
  };

  const changeContainerPosition = (name: IStyle) => {
    dispatch({ containerPositionSize: name });
    body.setAttribute("data-container", name.value);
    name.value === "boxed" &&
      changeSideBarStyle({ value: "overlay", label: "Overlay" });
  };

  // const setDemoTheme = (theme, direction) => {
  //   var setAttr = {};

  //   var themeSettings = dezThemeSet[theme];

  //   body.setAttribute("data-typography", themeSettings.typography);

  //   setAttr.value = themeSettings.version;
  //   changeBackground(setAttr);

  //   setAttr.value = themeSettings.layout;
  //   changeSideBarLayout(setAttr);

  //   changePrimaryColor(themeSettings.primary);
  //   changeSecondaryColor(themeSettings.secondary);

  //   changeNavigationHader(themeSettings.navheaderBg);

  //   chnageHaderColor(themeSettings.headerBg);

  //   setAttr.value = themeSettings.sidebarStyle;
  //   changeSideBarStyle(setAttr);

  //   chnageSidebarColor(themeSettings.sidebarBg);

  //   setAttr.value = themeSettings.sidebarPosition;
  //   changeSideBarPostion(setAttr);

  //   setAttr.value = themeSettings.headerPosition;
  //   changeHeaderPostion(setAttr);

  //   setAttr.value = themeSettings.containerLayout;
  //   changeContainerPosition(setAttr);

  //   setAttr.value = direction;
  //   changeDirectionLayout(setAttr);
  // };

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.setAttribute("data-typography", "poppins");
      body.setAttribute("data-theme-version", "light");
      body.setAttribute("data-layout", "vertical");
      body.setAttribute("data-primary", "color_1");
      body.setAttribute("data-nav-headerbg", "color_4");
      body.setAttribute("data-headerbg", "color_4");
      body.setAttribute("data-sidebar-style", "overlay");
      body.setAttribute("data-sidebarbg", "color_1");
      body.setAttribute("data-secondary", "color_1");
      body.setAttribute("data-sidebar-position", "fixed");
      body.setAttribute("data-header-position", "fixed");
      body.setAttribute("data-container", "wide");
      body.setAttribute("direction", "ltr");
      let resizeWindow = () => {
        dispatch({ windowWidth: window.innerWidth });
        dispatch({ windowHeight: window.innerHeight });
        if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          body.setAttribute("data-sidebar-style", "mini");
        } else if (window.innerWidth <= 768) {
          body.setAttribute("data-sidebar-style", "overlay");
          body.setAttribute("data-layout", "vertical");
        } else {
          body.setAttribute("data-sidebar-style", "full");
        }

        // if(window.windowWidth > )
      };
      resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        // body,
        sideBarOption,
        layoutOption,
        backgroundOption,
        sidebarposition,
        headerPositions,
        containerPosition,
        directionPosition,
        fontFamily,
        primaryColor,
        secondaryColor,
        navigationHader,
        windowWidth,
        windowHeight,
        changePrimaryColor,
        changeSecondaryColor,
        changeNavigationHader,
        changeSideBarStyle,
        sideBarStyle,
        changeSideBarPostion,
        sidebarpositions,
        changeHeaderPostion,
        headerposition,
        changeSideBarLayout,
        sidebarLayout,
        changeDirectionLayout,
        changeContainerPosition,
        direction,
        colors,
        haderColor,
        chnageHaderColor,
        chnageSidebarColor,
        sidebarColor,
        iconHover,
        menuToggle,
        openMenuToggle,
        changeBackground,
        background,
        //containerPosition_,
        containerPositionSize,
        // setDemoTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
