import React from "react";
import { Form, FormGroup } from "react-bootstrap";

interface CustomInputProps {
  inputType?: "email" | "number" | "text" | "password";
  id: string;
  placeholder: string;
  value?: string | number;
  touched?: boolean;
  errors?: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const CustomInput = ({
  inputType = "text",
  id,
  placeholder,
  handleChange,
  //   name,
  value = "",
  touched,
  errors,
}: CustomInputProps) => {
  return (
    <FormGroup className="mb-3">
      <Form.Control
        id={id}
        name={id}
        type={inputType}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        isValid={touched && !errors}
        isInvalid={touched && !!errors}
      />
      {touched && errors ? (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      ) : null}
    </FormGroup>
  );
};
export default CustomInput;
