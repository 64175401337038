import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// import { fetchBookings } from "src/store/reducers/bookings";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import { EventSourceInput } from "@fullcalendar/core";
import { Button, Card, Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchCalData } from "src/store/reducers/reports";
// import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import { toast } from "react-toastify";

const BookingCalendar = () => {
  const dispatch = useAppDispatch();
  const calDataObj = useAppSelector((state) => state.reports.calDataObj.data);
  const calDataStatus = useAppSelector(
    (state) => state.reports.calDataObj.status
  );
  const [calData, setCalData] = useState<EventSourceInput | undefined>([]);
  const [pending, setPending] = useState(false);
  const [visibleRange, setVisibleRange] = useState<{
    start: string;
    end: string;
  }>();
  const [calRange, setCalRange] = useState<{
    start: string;
    end: string;
  }>();
  const location = useLocation();
  const calendarRef = useRef<FullCalendar | null>(null);

  //   const retry = useCallback(() => {
  //     dispatch(fetchBookings());
  //   }, [dispatch]);

  const {
    // handleChange,
    values,
    handleSubmit,
    // resetForm,
    setFieldValue,
    // touched,
    // errors,
  } = useFormik({
    initialValues: {
      from_date: calRange?.start,
      to_date: calRange?.end,
      // user_id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from_date: Yup.string(),
      to_date: Yup.string(),
      // user_id: Yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // const finalValues: BookingDataForm = { ...values };
      setPending(true);
      // nullValueKeysBooking.forEach((k) => {
      //   if (!finalValues[k]) {
      //     finalValues[k] = null;
      //   }
      // });
      setSearchFormData(values);
    },
  });

  const setSearchFormData = async (values: {
    from_date: string | undefined;
    to_date: string | undefined;
  }) => {
    try {
      await dispatch(fetchCalData(values)).unwrap();
      toast.success("Calendar Data updated successfully");
      // setShow(false);
      // navigate(`/booking-view/${data.data}`);
    } catch (error: any) {
      console.log(error);
      toast.error("Saving booking failed, Please try again");
    } finally {
      setPending(false);
    }
  };
  // useEffect(() => {
  //   console.log(errors);
  //   console.log(touched);
  //   console.log(values);
  // }, [errors, touched, values]);

  useEffect(() => {
    if (calDataStatus === "idle") {
      dispatch(fetchCalData());
    }
  }, [dispatch, calDataStatus]);

  useEffect(() => {
    if (calDataObj) {
      const dataArr: {
        overlap: boolean;
        id: string;
        title: string;
        start: string;
        endd: string;
        // created: string;
        allDay: boolean;
        backgroundColor?: string;
      }[] = [];
      const range: typeof visibleRange = { start: "", end: "" };
      const calObjLength = Object.entries(calDataObj)?.length;
      Object.entries(calDataObj)?.forEach((calData, index) => {
        if (index === 0) {
          range.start = calData?.[0] + " 00:00:00";
        } else if (index === calObjLength - 1) {
          range.end = calData?.[0] + " 23:59:59";
        }
        calData?.[1].forEach((data) => {
          if (!!data.id) {
            // console.log(dataArr.length % 2 ? "brown" : undefined);
            // console.log(dataArr.length);
            dataArr.push({
              overlap: false,
              id: data.id,
              title: data.name,
              start: data.arrival_date,
              endd: data.departure_date,
              // end: calData[1].departure_date,
              // created: calData?.[0],
              allDay: true,
              backgroundColor: dataArr.length % 2 ? "brown" : undefined,

              // url: `${location.pathname.replace(
              //   "bookings-calendar",
              //   "booking-view"
              // )}/${bookingData.id}`,
              // overlap: false,
              // id: bookingData.id,
              // title: bookingData.customer_name,
              // start: bookingData.arrival_date,
              // created: bookingData.created,
              // allDay: true,
              // backgroundColor: index % 2 ? "brown" : undefined,
            });
          }
        });
      });

      const calDa = dataArr.filter((data) => data.id || false);
      setCalData(calDa);
      if (range.start !== "") {
        setVisibleRange(range);
      }
      setCalRange((calRange) =>
        typeof calRange === "undefined" ||
        calRange.end === "" ||
        calRange.start === ""
          ? range
          : calRange
      );
    }
  }, [calDataObj]);

  return (
    <>
      {/* <MainPagetitle
        mainTitle="Calendar"
        pageTitle="Calendar"
        parentTitle="Home"
      /> */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Filter</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row container-fluid">
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="from_date"
                      placeholder={"From Date"}
                      handleChange={(date) => setFieldValue("from_date", date)}
                      // labelContent={{ text: "Start Date", required: true }}
                      // handleChange={handleChange}
                      value={values.from_date}
                      pickerType="YYYY-MM-DD"
                      // touched={touched.from_date}
                      // errors={errors.from_date}
                      selectsStart
                      endDate={values.to_date}
                      // startDate={values.from_date}
                    />{" "}
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="to_date"
                      placeholder={"To Date"}
                      handleChange={(date) => setFieldValue("to_date", date)}
                      // labelContent={{ text: "End Date", required: true }}
                      // handleChange={handleChange}
                      value={values.to_date}
                      pickerType="YYYY-MM-DD"
                      // touched={touched.to_date}
                      // errors={errors.to_date}
                      selectsEnd
                      startDate={values.from_date}
                      // endDate={values.to_date}
                    />
                  </div>
                  <div className="col-xl-3 mb-3">
                    {/* <CustomSelectWithLabel
                    id="user_id"
                    // placeholder={"Select User"}
                    // labelContent={{ text: "Select User" }}
                    handleChange={handleChange}
                    value={values.user_id}
                    touched={touched.user_id}
                    errors={errors.user_id}
                  />{" "} */}
                  </div>
                  <div className="col-xl-1 mb-3"></div>
                  <div className="col-xl-2 mb-3">
                    {/* <Form.Label>&nbsp;</Form.Label> */}
                    <Button
                      className="form-control"
                      disabled={pending}
                      type="submit"
                    >
                      {pending ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="animated fadeIn demo-app">
              <Row>
                {/* <Col lg={3}>
                  <Card>
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-intro-title mb-0">Calendar</h4>
                    </div>
                    <Card.Body>
                      <div id="external-events">
                        <p>Drag and drop your event or click in the calendar</p>
                         {events.map((event) => (
                          <div
                            className={`fc-event external-event light btn-success`}
                            // className={`fc-event external-event light btn-${event.style}`}
                            // data-class={`bg-${event.style}`}
                            title={event.customer_name}
                            data={event.id}
                            key={event.id}
                          >
                            <i className="fa fa-move" />
                            <span>{event.title}</span>
                          </div>
                        ))} 
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                {/* <a href="${location.pathname.replace(
                                  "bookings-calendar",
                                  "booking-view"
                                )}/${
                                  info.event._def.publicId
                                }"  class="text-white text-center"></a> */}
                <Col lg={12}>
                  <Card>
                    <Card.Body>
                      <div className="demo-app-calendar" id="mycalendartest">
                        {calData ? (
                          <FullCalendar
                            // visibleRange={visibleRange}
                            validRange={visibleRange}
                            // handleCustomRendering={() => {
                            //   console.log(
                            //     calendarRef?.current?.getApi().view.type
                            //   );
                            //   if (
                            //     calendarRef?.current?.getApi().view.type ===
                            //     "dayGridMonth"
                            //   ) {
                            //     setEventRange(3);
                            //   } else {
                            //     setEventRange(5);
                            //   }
                            // }}
                            ref={calendarRef}
                            events={calData}
                            // eventSources={calData}
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                            ]}
                            navLinks={false}
                            // navLinkWeekClick={() => {
                            //   console.log(
                            //     calendarRef?.current?.getApi().view.type
                            //   );
                            //   if (
                            //     calendarRef?.current?.getApi().view.type ===
                            //     "dayGridMonth"
                            //   ) {
                            //     setEventRange(3);
                            //   } else {
                            //     setEventRange(undefined);
                            //   }
                            // }}
                            initialView="dayGridMonth"
                            headerToolbar={{
                              start: "prev,next today",
                              center: "title",
                              end: "dayGridMonth,dayGridWeek,dayGridDay",
                            }}
                            // dayMaxEvents={eventRange}
                            // dayMaxEventRows={3}
                            eventDidMount={(info) => {
                              // console.log(info);
                              // <div class="row"><div class="col-lg-5 fw-normal">Booking Date</div><div class="col-lg-7">${new Date(
                              //   info.event._def.extendedProps.created
                              // ).toLocaleString('en-IN')}</div></div>
                              tippy(info.el, {
                                // followCursor: "initial",
                                trigger: "click",
                                touch: "hold",
                                allowHTML: true,
                                interactive: true,
                                content: `<a href="${location.pathname.replace(
                                  "bookings-calendar",
                                  "booking-view"
                                )}/${
                                  info.event._def.publicId
                                }" target="_blank" class="text-white text-center"><h4 class="text-white text-center">${
                                  info.event._def.title
                                }</h4></a>
                                    <div class="row"><div class="col-lg-6 fw-normal">Arrival Date</div><div class="col-lg-6">${info.event._instance?.range.start.toLocaleDateString('en-IN')}</div></div>
                                    <div class="row"><div class="col-lg-6 fw-normal">Departure Date</div><div class="col-lg-6">${new Date(info.event._def?.extendedProps.endd).toLocaleDateString('en-IN')}</div></div>`,
                                // content: `<h3 class="text-white">${
                                //   info.event._def.title
                                // }</h3>
                                //     <hr/>
                                //     <h5 class="text-white">Booking Date: ${new Date(
                                //       info.event._def.extendedProps.created
                                //     ).toLocaleString('en-IN')}</h5>
                                //     <hr/>
                                //     <p>Arrival Date: ${info.event._instance?.range.start.toLocaleString('en-IN')}</p>
                                //     <hr/>
                                //     <p>Departure Date: ${info.event._instance?.range.end.toLocaleString('en-IN')}</p>`,
                              });
                            }}
                            // eventMouseEnter={(mouseEnterInfo) => {
                            //   console.log(mouseEnterInfo);
                            // }}
                            // eventMouseLeave={(mouseEnterInfo) => {
                            //   console.log(mouseEnterInfo);
                            // }}
                            rerenderDelay={10}
                            // eventDurationEditable={false}
                            // editable={true}
                            // droppable={true}
                            // initialEvents={calData}
                            // ref={this.calendarComponentRef}
                            // weekends={this.state.calendarWeekends}
                            // events={this.state.calendarEvents}
                            // eventDrop={this.drop}
                            // drop={this.drop}
                            // eventReceive={this.eventReceive}

                            // eventClick={this.eventClick}
                            // selectable={true}
                          />
                        ) : null}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCalendar;
