import { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch } from "src/store/hooks";
import {
  BOOKING_STATUS,
  BOOKING_STATUS_LABEL,
  BookingData,
  fetchBookingsByStatus,
  fetchPendingPaymentBookings,
  fetchTodayBookings,
} from "src/store/reducers/bookings";
import { API_STATUS, GainMarginCSSLabel } from "src/utils/constants";
import AddBookingCanvas from "./AddBookingCanvas";

interface StatusWiseBookingListProps {
  status: BOOKING_STATUS | "Today" | "LatestTen" | "Payment Pending";
  theadClassName: string;
}

const StatusWiseBookingList = ({
  status,
  theadClassName,
}: StatusWiseBookingListProps) => {
  const [apiStatus, setApiStatus] = useState<API_STATUS>("idle");
  const [showAddModal, setShowAddModal] = useState(false);
  const [show, setShow] = useState(false);
  const [bookingList, setBookingList] = useState<BookingData[]>([]);

  const dispatch = useAppDispatch();
  const retry = useCallback(async () => {
    setApiStatus("pending");
    try {
      let data;
      if (status === "Today") {
        const fdata = await dispatch(fetchTodayBookings()).unwrap();
        data = fdata.today_bookings;
      } else if (status === "LatestTen") {
        const fdata = await dispatch(fetchTodayBookings()).unwrap();
        data = fdata.latest_ten_bookings;
      } else if (status === "Payment Pending") {
        data = await dispatch(fetchPendingPaymentBookings()).unwrap();
      } else {
        data = await dispatch(
          fetchBookingsByStatus({ status, page: 1 })
        ).unwrap();
      }
      setBookingList(data);
      setApiStatus("success");
    } catch (e) {
      setApiStatus("error");
    }
  }, [dispatch, status]);

  const toggleShow = useCallback(() => {
    setShow((show) => !show);
  }, []);

  useEffect(() => {
    if (apiStatus === "idle") {
      retry();
    }
  }, [dispatch, apiStatus, retry]);

  return (
    <>
      <div className="col-xl-12 col-xxl-12">
        <div className="card h-auto">
          <div className="card-header">
            <h4
              className="heading mb-0 link"
              role="button"
              onClick={toggleShow}
            >
              {show ? (
                <span className="btn-icon-start">
                  <i className="fa fa-minus color-info"></i>
                </span>
              ) : (
                <span className="btn-icon-start">
                  <i className="fa fa-plus color-info"></i>
                </span>
              )}
              {status in BOOKING_STATUS
                ? `${BOOKING_STATUS_LABEL[status]} Bookings`
                : status === "Today"
                ? "Today's Arrival"
                : status === "LatestTen"
                ? "Latest 10"
                : status}{" "}
            </h4>
            {status === "Today" ? (
              <Link
                to={"#"}
                className="btn btn-primary btn-sm"
                data-bs-toggle="offcanvas"
                onClick={() => setShowAddModal(true)}
              >
                + Add Booking
              </Link>
            ) : null}
          </div>
          <div className={!show ? "card-body d-none" : "card-body"}>
            {apiStatus === "success" ? (
              bookingList.length > 0 ? (
                <div className="table-responsive active-projects">
                  <div
                    id="projects-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="projects-tbl"
                      className="table table-hover dataTable no-footer mb-0"
                    >
                      <thead className={theadClassName}>
                        <tr>
                          <th>Name</th>
                          <th>Contact Number</th>
                          <th>Arrival Date</th>
                          <th>Total Amount</th>
                          {status === "Payment Pending" ? (
                            <>
                            <th>Pending Amount</th>
                            <th>Next payment date</th>
                            </>
                          ) : null}
                          <th>Cab driver</th>
                          <th>Margin</th>
                          <th className="text-center">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingList?.map((bookingData, index) => (
                          <tr key={bookingData.id}>
                            <td>{bookingData.customer_name}</td>
                            <td>{bookingData.customer_number}</td>
                            <td>
                              {new Date(
                                bookingData.arrival_date
                              ).toLocaleDateString('en-IN')}
                            </td>
                            <td>{bookingData.total_amount}</td>
                            {status === "Payment Pending" ? (
                              <>
                              <td>
                                {bookingData?.total_amount -
                                  bookingData?.amount_received}
                              </td>
                              <td>
                              {bookingData?.next_payment_date ? new Date(
                                bookingData?.next_payment_date
                              ).toLocaleDateString('en-IN') : null }
                              </td>
                              </>
                            ) : null}
                            <td>
                              {bookingData.bookingcab_set?.[0]?.driver?.name
                                ? bookingData.bookingcab_set?.[0].driver?.name
                                : null}
                            </td>
                            <td>
                              <span
                                className={
                                  "badge badge-rounded badge-" +
                                  GainMarginCSSLabel[
                                    bookingData.gain_margin_colour
                                  ]
                                }
                              >
                                {Number(bookingData.gain_margin).toFixed(2)}
                              </span>
                            </td>
                            <td className="text-center">
                              {/* <NavLink
                                  className=""
                                  onClick={() => setEditId(bookingData.id)}
                                  to={"#"}
                                  // to={`/users/edit/${userData.uuid}`}
                                  title="Edit"
                                >
                                  <i className={`fas fa-pen`} />}
                                </NavLink>{" "} */}
                              <NavLink
                                className="btn btn-warning shadow btn-xs sharp me-1"
                                to={`/booking-view/${bookingData.id}`}
                                target="_blank"
                                title="View"
                              >
                                <i className={`fas fa-paper-plane`} />
                                {/* <span>View</span> */}
                              </NavLink>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <PaginationText
                        currentPage={currentPage}
                        totalRecords={tableData.length}
                        perPage={sort}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalRecords={tableData.length}
                        perPage={sort}
                        changePage={setCurrentPage}
                      />
                    </div> */}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <strong>NA</strong>
                </div>
              )
            ) : apiStatus === "pending" ? (
              <div className="d-flex align-items-center">
                <strong>Loading...</strong>
                <div
                  className="spinner-border ms-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            ) : apiStatus === "error" ? (
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={retry}
                >
                  Retry
                </button>{" "}
                &nbsp;
                <strong onClick={retry}>Please try again </strong>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {status === "Today" ? (
        <AddBookingCanvas
          show={showAddModal}
          setShow={setShowAddModal}
          Title="Add Booking"
        />
      ) : null}
    </>
  );
};

export default StatusWiseBookingList;
