import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import logo from "../../images/logo/logo-full.png";
import LogoWhite from "../../images/logo/logofull-white.png";
import bg6 from "../../images/background/bg6.jpg";
import { useAppDispatch } from "src/store/hooks";
import {
  forgotPasswordReq,
  setForgotPasswordData,
} from "src/store/reducers/auth";
import CustomInput from "src/layouts/form/CustomInput";
import { LengthRequired } from "src/layouts/form/validations/Validation";
import LoginHeader from "src/layouts/modules/login/LoginHeader";

export default function ForgotPassword() {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const login = async (mobile_number: string) => {
    try {
      setPending(true);
      await dispatch(forgotPasswordReq({ mobile_number })).unwrap();
      dispatch(setForgotPasswordData({ mobile_number }));
      toast.success("OTP sent successfully. Set your password with OTP");
      navigate("/reset-password");
    } catch (error: any) {
      toast.error(error.message || "Failed");
    } finally {
      setPending(false);
    }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      mobile_number: "",
    },
    validationSchema: Yup.object({
      mobile_number: LengthRequired("Mobile number", 10),
      // Yup.number()
      //   .positive()
      //   .integer()
      //   .test(
      //     "minlen",
      //     "Mobile number must be 7 digits or more",
      //     (val) => val !== undefined && val.toString().length >= 7
      //   )
      //   .test(
      //     "maxlen",
      //     "Mobile number must be 12 digits or more",
      //     (val) => val !== undefined && val.toString().length <= 12
      //   )
      //   .required("Mobile number is required"),
    }),
    onSubmit: (values) => {
      login(values.mobile_number);
    },
  });

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ background: "#fff url(" + bg6 + ")", height: "100vh" }}
        >
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: "653px" }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    // dir: "ltr",
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <LoginHeader />
                      <div className="nav nav-tabs border-bottom-0">
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-personal"
                          >
                            <h3 className="form-title m-t0">Forgot Password</h3>
                            <div className="dz-separator-outer m-b5">
                              <div className="dz-separator bg-primary style-liner"></div>
                            </div>
                            <p>
                              Enter your mobile number for receiving next steps
                              for password reset.{" "}
                            </p>
                            <form
                              onSubmit={handleSubmit}
                              // className=" dz-form pb-3"
                            >
                              <CustomInput
                                id="mobile_number"
                                placeholder="Mobile number"
                                // inputType="number"
                                handleChange={handleChange}
                                value={values.mobile_number}
                                touched={touched.mobile_number}
                                errors={errors.mobile_number}
                              />

                              <div className="form-group text-left mb-5 ">
                                <Button
                                  type="submit"
                                  disabled={pending}
                                  // style={{ width: "100%" }}
                                  className="dz-xs-flex m-r5 "
                                >
                                  {pending ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                              </div>
                            </form>

                            <div className="text-center bottom p-1">
                              <NavLink
                                to="/login"
                                className="btn btn-primary button-md btn-block"
                              >
                                Login
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-footer">
                      <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                        <div className="col-lg-12 text-center">
                          <span>
                            {" "}
                            © Copyright by{" "}
                            <span
                              className={`heart ${
                                heartActive ? "" : "heart-blast"
                              }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span>
                            <a
                              href="https://www.dexignzone.com/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {" "}
                              DexignZone{" "}
                            </a>{" "}
                            All rights reserved.
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
