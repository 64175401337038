import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import {
  BookingHotel,
  BookingHotelEditForm,
  editHotelBooking,
} from "src/store/reducers/bookings";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import { KeyRequired } from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { fetchListHotels } from "src/store/reducers/hotels";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";

type IEditBookingHotelCanvas = {
  Title: string;
  editId: string;
  hide: () => void;
  data: BookingHotel;
};

const EditBookingHotelCanvas = ({
  Title,
  editId,
  hide,
  data,
}: IEditBookingHotelCanvas) => {
  const [pending, setPending] = useState(false);
  const hotelsList = useAppSelector((state) => state.hotels.list.data);
  const hotelsListStatus = useAppSelector((state) => state.hotels.list.status);
  useEffect(() => {
    if (!data) {
      hide();
    }
  }, [data, hide]);
  const dispatch = useAppDispatch();

  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    // setFieldTouched,
    touched,
    setErrors,
    errors,
  } = useFormik<{
    hotel: string | null;
    room_category: string;
    checkin_date: string;
    checkout_date: string;
    hotel_price: string;
    advance_amount_paid: string;
    payment_notification_date: string | null;
    meal_plan: string;
    notes: string;
}>({
    initialValues: {
      hotel: data?.hotel?.id || '',
      room_category: data?.room_category,
      checkin_date: data?.checkin_date,
      checkout_date: data?.checkout_date,
      hotel_price: data?.hotel_price,
      advance_amount_paid: data?.advance_amount_paid,
      payment_notification_date: data?.payment_notification_date || '',
      meal_plan: data?.meal_plan || '',
      notes: data?.notes || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      checkin_date: KeyRequired("Check in Date"),
      checkout_date: KeyRequired("Check out Date"),
      // payment_notification_date: KeyRequired("Payment notification Date"),
      hotel: KeyRequired("Hotel"),
      // room_category: KeyRequired("Room category"),
      hotel_price: Yup.number()
        .typeError("Hotel price must be an number")
        .required("Hotel price is required")
        .positive("Hotel price is required")
        .integer("Hotel price must be an integer"),
      // advance_amount_paid: Yup.number()
      //   .required("Advance amount paid is required")
      //   .typeError("Advance amount paid is required")
      //   .positive("Advance amount paid is required")
      //   .integer("Advance amount paid must be an integer"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      console.log(values);
      if (values.payment_notification_date === '') {
        values.payment_notification_date = null
      }
      editHotel({ ...values, id: editId, booking: data.booking });
    },
  });

  const editHotel = useCallback(
    async (values: BookingHotelEditForm) => {
      try {
        await dispatch(editHotelBooking(values)).unwrap();
        toast.success("Booking hotel is updated successfully");
        hide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, hide, setErrors]
  );


  // useEffect(() => {
  //   console.log(errors);
  //   console.log(touched);
  //   console.log(values);
  // }, [errors, touched, values]);

  // useEffect(() => {
  //   if (hotelsListStatus === "idle") {
  //     dispatch(fetchListHotels());
  //   }
  // }, [hotelsListStatus, dispatch]);

  useEffect(() => {
    resetForm();
    if (!!editId) {
      dispatch(fetchListHotels());
    }
  }, [resetForm, editId, dispatch]);
  return (
    <Offcanvas
      show={!!editId}
      onHide={hide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h3 className="modal-title" id="#gridSystemModal">
          {Title}
        </h3>
        <button type="button" className="btn-close" onClick={hide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className="col-xl-12">
                <h4>Hotel</h4>
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Hotel"
                  placeholder="Hotel"
                  id={"hotel"}
                  required
                  options={hotelsList.map((hotel) => ({
                    label: hotel.name,
                    value: hotel.id,
                  }))}
                  touched={touched.hotel}
                  // setFieldTouched={setFieldTouched}
                  errors={errors.hotel}
                  handleChange={handleChange}
                  value={
                    values.hotel ? 
                    hotelsList
                      .filter((hotel) => hotel.id === values.hotel)
                      ?.map((hotel) => ({
                        label: hotel.name,
                        value: hotel.id,
                      }))?.[0] || '' : ''
                  }
                />
                {/* <CustomSelectWithLabel
                  id="hotel"
                  labelText="Cab vendor"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel}
                  touched={touched.hotel}
                  errors={errors.hotel}
                >
                  <option>Select hotel</option>
                  {hotelsList.map((hotel) => (
                    <option value={hotel.id} key={hotel.id}>
                      {hotel.name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="hotel"
                  labelText="Hotel"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel}
                  touched={touched.hotel}
                  errors={errors.hotel}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="room_category"
                  labelText="Room category"
                  // required={true}
                  handleChange={handleChange}
                  value={values.room_category}
                  touched={touched.room_category}
                  errors={errors.room_category}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="checkin_date"
                  labelText="Check in date"
                  required={true}
                  handleChange={(date) => setFieldValue("checkin_date", date)}
                  value={values.checkin_date}
                  selectsStart
                  endDate={values.checkout_date}
                  touched={touched.checkin_date}
                  errors={errors.checkin_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="checkout_date"
                  labelText="Check out date"
                  required={true}
                  handleChange={(date) => setFieldValue("checkout_date", date)}
                  value={values.checkout_date}
                  touched={touched.checkout_date}
                  errors={errors.checkout_date}
                  selectsEnd
                  startDate={values.checkin_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="hotel_price"
                  labelText="Hotel price"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel_price}
                  touched={touched.hotel_price}
                  errors={errors.hotel_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="advance_amount_paid"
                  labelText="Advance amount paid"
                  // required={true}
                  handleChange={handleChange}
                  value={values.advance_amount_paid}
                  touched={touched.advance_amount_paid}
                  errors={errors.advance_amount_paid}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="payment_notification_date"
                  labelText="Payment notification date"
                  // required={true}
                  handleChange={(date) =>
                    setFieldValue("payment_notification_date", date)
                  }
                  value={values.payment_notification_date || ''}
                  touched={touched.payment_notification_date}
                  errors={errors.payment_notification_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="meal_plan"
                  labelText="Meal plan"
                  // required={true}
                  handleChange={handleChange}
                  value={values.meal_plan}
                  touched={touched.meal_plan}
                  errors={errors.meal_plan}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={pending}
              className="btn btn-primary me-1"
            >
              Submit
            </button>
            <Link to={"#"} onClick={hide} className="btn btn-danger light ms-1">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default EditBookingHotelCanvas;
