import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { fetchUsers } from "src/store/reducers/users";
import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import { UserRole, UserRoleCSSLabel } from "src/utils/constants";
// import PaginationText from "src/jsx/customComponents/pagination/PaginationText";
// import Pagination from "src/jsx/customComponents/pagination/Pagination";
import UserCanvas from "./UsersCanvas";
import UserEditCanvas from "./UsersEditCanvas";
import ConfirmationModal from "src/layouts/modals/ConfirmationModal";
import { deleteUser } from "src/apis/UserApi";
import { toast } from "react-toastify";
// import InviteCustomer from "../../constant/InviteCustomer";

const Employees = () => {
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector((state) => state.auth.data?.user_id);
  // const loggedUser = useAppSelector(
  //   (state) => state.auth.data?.companies?.[0]?.uuid
  // );
  const [deleteUserId, setDeleteUserId] = useState<string>();
  const userList = useAppSelector((state) => state.users.data);
  const userListStatus = useAppSelector((state) => state.users.status);
  const [showEmployeModal, setShowEmployeModal] = useState(false);
  const [editId, setEditId] = useState("");
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  // const [currentPage, setCurrentPage] = useState(0);
  // const sort = 5;

  const retry = useCallback(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const cancelDelete = () => {
    setDeleteUserId(undefined);
  };

  const confirmDelete = useCallback(async () => {
    if (deleteUserId) {
      try {
        const data = await deleteUser(deleteUserId);
        toast.success("User is deleted successfully");
        retry();
        setDeleteUserId(undefined);
      } catch (e) {
        toast.error("Please try again");
      }
    }
  }, [deleteUserId, retry]);

  useEffect(() => {
    if (userListStatus === "idle") {
      retry();
    }
  }, [retry, userListStatus]);

  return (
    <>
      {/* <MainPagetitle mainTitle="Users" pageTitle="Users" parentTitle="Home" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Users</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setShowEmployeModal(true)}
                >
                  + Add User
                </Link>
              </div>
              <div className="card-body ">
                {userListStatus === "success" ? (
                  userList && userList.length > 0 ? (
                    <div className="table-responsive active-projects">
                      {/* <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                      <h4 className="heading mb-0">Users</h4>
                    </div> */}
                      <div
                        id="projects-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="projects-tbl"
                          className="table table-hover dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              {/* <th>Created Date</th> */}
                              <th>Mobile number</th>
                              <th>Email</th>
                              <th>Role</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userList?.map((userData, index) => (
                              <tr key={userData.uuid}>
                                <td>
                                  {userData.first_name} {userData.last_name}
                                </td>
                                {/* <td>
                                  {new Date(userData.created).toLocaleString('en-IN')}
                                </td> */}
                                <td>{userData.mobile_number}</td>
                                <td>{userData.email}</td>
                                <td>
                                  <span
                                    className={
                                      "badge badge-rounded badge-" +
                                      UserRoleCSSLabel[userData.role]
                                    }
                                  >
                                    {UserRole[userData.role]}
                                  </span>
                                </td>
                                <td>
                                  {loggedUser === userData.user_id ||
                                  userData.role !== UserRole["Super Admin"] ? (
                                    <NavLink
                                      className="btn btn-primary shadow btn-xs sharp me-1"
                                      onClick={() => setEditId(userData.uuid)}
                                      to={"#"}
                                      title="Edit"
                                    >
                                      <i className={`fas fa-pen`} />
                                      {/* <span>Edit</span> */}
                                    </NavLink>
                                  ) : null}{" "}
                                  {loggedUser !== userData.user_id ||
                                  userData.role !== UserRole["Super Admin"] ? (
                                    <NavLink
                                      className="btn btn-danger shadow btn-xs sharp"
                                      onClick={() => {
                                        setDeleteUserId(userData.uuid);
                                      }}
                                      to={`#`}
                                      title="Delete"
                                    >
                                      <i className={`fas fa-x`} />
                                    </NavLink>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <PaginationText
                        currentPage={currentPage}
                        totalRecords={tableData.length}
                        perPage={sort}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalRecords={tableData.length}
                        perPage={sort}
                        changePage={setCurrentPage}
                      />
                    </div> */}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>NA</strong>
                    </div>
                  )
                ) : userListStatus === "pending" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : userListStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserCanvas
        show={showEmployeModal}
        setShow={setShowEmployeModal}
        Title="Add User"
      />
      <UserEditCanvas
        show={editId !== ""}
        hide={() => setEditId("")}
        editId={editId}
        Title="Edit User"
      />
      <ConfirmationModal
        show={typeof deleteUserId !== "undefined"}
        handleClose={cancelDelete}
        confirmClose={confirmDelete}
      />
    </>
  );
};

export default Employees;
