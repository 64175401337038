import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormErrorFeedback from "./validations/FormErrorFeedback";

import { registerLocale } from  "react-datepicker";
import enIN from 'date-fns/locale/en-IN';
registerLocale('en-IN', enIN)

{/* <DatePicker
  locale="es"
/> */}

interface CustomDatePickerWithLabelProps {
  id: string;
  required?: boolean;
  labelText?: string;
  value?: string | number;
  placeholder?: string;
  showTimeSelect?: boolean;
  touched?: boolean;
  errors?: string | string[];
  pickerType?: "date" | "time" | "YYYY-MM-DD" | "datetime";
  handleChange: (e: string) => void;
  selectsStart?: boolean;
  startDate?: string | number;
  selectsEnd?: boolean;
  endDate?: string | number;
}

const DateCustomInput = forwardRef<HTMLInputElement, any>(
  (
    { value, onClick, touched, errors, id, labelText, onChange, placeholder },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          id={id}
          placeholder={placeholder}
          name={id}
          onClick={onClick}
          onChange={onChange}
          value={value}
          isValid={touched && !errors}
          isInvalid={touched && !!errors}
          autoComplete="off"
        />
        {touched && errors ? (
          <FormErrorFeedback id={id} label={labelText} errors={errors} />
        ) : null}
      </>
    );
  }
);

const CustomDatePickerWithLabel = ({
  id,
  labelText,
  required,
  handleChange,
  placeholder,
  value = "",
  pickerType = "date",
  showTimeSelect = false,
  touched,
  errors,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
}: CustomDatePickerWithLabelProps) => {
  const dateFormat =
    pickerType === "time"
      ? "hh:mm aa"
      : pickerType === "YYYY-MM-DD"
      ? "yyyy-MM-dd"
      : pickerType === "datetime"
      ? "dd/MM/yyyy hh:mm aa"
      : "dd/MM/yyyy";

      // dateFormat="yyyy/MM/dd"
  return (
    <>
      {labelText ? (
        <Form.Label htmlFor={id}>
          {labelText}
          {required ? <span className="text-danger">*</span> : null}
        </Form.Label>
      ) : null}
      <DatePicker
        locale='en-IN'
        customInput={
          <DateCustomInput
            labelText={labelText}
            touched={touched}
            errors={errors}
          />
        }
        placeholderText={placeholder}
        name={id}
        id={id}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate && startDate !== "" ? new Date(startDate) : null}
        endDate={endDate && endDate !== "" ? new Date(endDate) : null}
        minDate={
          selectsEnd && startDate && startDate !== ""
            ? new Date(startDate)
            : null
        }
        maxDate={
          selectsStart && endDate && endDate !== "" ? new Date(endDate) : null
        }
        showTimeSelect={showTimeSelect || pickerType === "time"}
        showTimeSelectOnly={pickerType === "time"}
        isClearable
        selected={value !== "" ? new Date(value) : null}
        onChange={(date, e) => {
          if (!date) {
            handleChange("");
          } else {
            // console.log(date);
            // console.log(date.toISOString());
            // console.log(date?.toUTCString());
            const offset = date.getTimezoneOffset();
            const ndate = new Date(date.getTime() - offset * 60 * 1000);
            if (typeof dateFormat === "undefined") {
              handleChange(ndate.toISOString());
            } else if (dateFormat === "yyyy-MM-dd") {
              handleChange(ndate.toISOString().split("T")[0]);
            } else if (dateFormat === "dd/MM/yyyy") {
              handleChange(ndate.toISOString().split("T")[0]);
            } else if (dateFormat === 'dd/MM/yyyy hh:mm aa') {
              handleChange(date.toISOString());
            }
          }
        }}
        dateFormat={dateFormat}
      />
    </>
  );
};
export default CustomDatePickerWithLabel;
