import * as Yup from "yup";

export const StringLengthValidation = (
  key: string,
  min: number,
  max: number,
  required = true
) => {
  const yupConfig = Yup.string()
    .min(min, "Must be " + min + " characters or more")
    .max(max, "Must be " + max + " characters or less");
  if (required) {
    return yupConfig.required(key + " is required");
  }
  return yupConfig;
};

const phoneRegExp = /^(0|[1-9]\d*)$$/;

export const LengthRequired = (
  key: string,
  length: number,
  required = true
) => {
  const yupConfig = Yup.string()
    .matches(phoneRegExp, key + " is not valid")
    .length(length, key + " must be exactly " + length + " characters");
  if (required) {
    return yupConfig.required(key + " is required");
  }
  return yupConfig;
};

export const KeyRequired = (key: string) =>
  Yup.string().required(key + " is required");

export const EmailRequired = (key: string = "Email", required = true) => {
  const yupConfig = Yup.string().email(key + " is invalid");
  if (required) {
    return yupConfig.required(key + " is required");
  }
  return yupConfig;
};

// export const IntegerRequired = (key?: string) =>
//   Yup.number().positive()
// const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$$/;
export const IntegerRequired = (key?: string) => Yup.number()
  .typeError('The amount must be a number')
  .positive(
    "The amount should be a positive number")
  .test(
    "is-decimal",
    "The amount should be a decimal with maximum two digits after comma",
    (val: any) => {
      if (val != undefined) {
        return patternTwoDigisAfterComma.test(val);
      }
      return true;
    }
  );

export const LengthRangeRequired = (
  key: string,
  min: number,
  max: number,
  required = true
) => {
  const yupConfig = Yup.number()
    .positive()
    .integer()
    .test(
      "minlen",
      key + " must be " + min + " digits or more",
      (val) => val !== undefined && val.toString().length >= min
    )
    .test(
      "maxlen",
      key + " must be " + max + " digits or more",
      (val) => val !== undefined && val.toString().length <= max
    );
  if (required) {
    return yupConfig.required(key + " is required");
  }
  return yupConfig;
};

export const RangeRequired = (
  key: string,
  min: number,
  max: number,
  required = true
) => {
  const yupConfig = Yup.number()
    .moreThan(min - 1, key + " must be in range " + min + "-" + max)
    .lessThan(max + 1, key + " must be in range " + min + "-" + max);
  if (required) {
    return yupConfig.required(key + " is required");
  }
  return yupConfig;
};
