import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT } from "src/utils/constants";

export interface CabVendorDataForm {
  contact_person_name: string;
  email_id: string;
  company_name: string;
  contact_number: string;
  alternate_number?: string | null;
  notes: string;
  city: string;
}

export interface CabVendorEditDataForm extends CabVendorDataForm {
  id: string;
}

export interface CabVendorData {
  id: string;
  contact_person_name: string;
  email_id: string;
  company_name: string;
  contact_number: string;
  alternate_number: string;
  notes: string;
  city: string;
  created_date: string;
  updated_date: string;
}

export interface CabVendorsState {
  data?: Pagination<CabVendorData>; // CabVendorData[];
  status: API_STATUS;
  list: {
    // data: Pick<CabVendorData, "id" | "company_name" | "contact_person_name">[];
    data: CabVendorData[];
    status: API_STATUS;
  };
  view: {
    data?: CabVendorData;
    status: API_STATUS;
  };
}

const initialState: CabVendorsState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  list: {
    data: [],
    status: "idle",
  },
  view: {
    data: undefined,
    status: "idle",
  },
  status: "idle",
};

export const cabVendorsSlice = createSlice({
  name: "cabVendors",
  initialState,
  reducers: {
    refreshCabVendorList: (state) => {
      state.status = "idle";
    },
    resetCabVendorView(state) {
      state.view.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCabVendors.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchCabVendors.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchCabVendors.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchListCabVendors.pending, (state, action) => {
      state.list.status = "pending";
    });
    builder.addCase(fetchListCabVendors.fulfilled, (state, action) => {
      state.list.status = "success";
      state.list.data = action.payload;
    });
    builder.addCase(fetchListCabVendors.rejected, (state, action) => {
      state.list.status = "error";
    });
    builder.addCase(addCabVendor.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(viewCabVendor.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(viewCabVendor.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(viewCabVendor.rejected, (state, action) => {
      state.view.status = "error";
    });
  },
});

export const fetchListCabVendors = createAsyncThunk<
  CabVendorData[],
  void,
  { state: RootState }
>("cab_vendors/fetchList", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<CabVendorData[]>,
      AxiosResponse<PartnerAPIResponseCover<CabVendorData[]>>
    >(`partners/cab_vendors/search/?format=json`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchCabVendors = createAsyncThunk<
  Pagination<CabVendorData>,
  { page: number; keyword: string },
  { state: RootState }
>("cab_vendors/fetch", async ({ page, keyword }, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<CabVendorData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<CabVendorData>>>
    >(
      `partners/cab_vendors?limit=${DATA_LIMIT}&keyword=${keyword}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`
    );
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const listCabVendors = createAsyncThunk<
  CabVendorData[],
  void,
  { state: RootState }
>("cab_vendors/list", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<CabVendorData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<CabVendorData>>>
    >("partners/cab_vendors/search/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const deleteCabVendor = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("cab_vendors/delete", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>>
    >(`partners/cab_vendors/${id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const updateCabVendor = createAsyncThunk<
  { status: string },
  CabVendorEditDataForm,
  { state: RootState }
>("cab_vendors/update", async (data: CabVendorEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<
        APIResponseCover<Record<never, never>>,
        CabVendorEditDataForm
      >,
      CabVendorEditDataForm
    >(`partners/cab_vendors/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const viewCabVendor = createAsyncThunk<
  CabVendorData,
  string,
  { state: RootState }
>("cab_vendors/view", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<CabVendorData>,
      AxiosResponse<APIResponseCover<CabVendorData>>
    >(`partners/cab_vendors/${id}?format=json`);
    // console.log(response.data);
    return response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addCabVendor = createAsyncThunk<
  { status: string },
  CabVendorDataForm,
  { state: RootState }
>("cab_vendors/add", async (data: CabVendorDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, CabVendorDataForm>,
      CabVendorDataForm
    >("partners/cab_vendors?format=json", data);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshCabVendorList, resetCabVendorView } =
  cabVendorsSlice.actions;

export default cabVendorsSlice.reducer;
