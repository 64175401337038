import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/store/hooks";

const PublicRoute = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.data);
  return !!isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
