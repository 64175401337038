import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  HotelData,
  HotelDataForm,
  HotelEditDataForm,
  addHotel,
  refreshHotelList,
  updateHotel,
  viewHotel,
} from "src/store/reducers/hotels";
import {
  EmailRequired,
  KeyRequired,
  LengthRequired,
  RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
// import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";

type IHotelFormCanvas = {
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
  hotelData?: HotelData;
};

// "name":"Grand Arc 2",
// "address":"shahjahnpur chinnor",
// "country":"india",
// "pincode":242001,
// "category":3, //S
// "contact_number":"7042720161",
// "status":1,
// "check_in_time":"12:00", //
// "check_out_time":"11:00", //
// "city":"shahjahnpur",
// "email_id":"vishal@gmail.com", //S
// "contact_person_name":"Vishal", //S
// "reservation_contact_number":"7042720160", //S
// "account_details":"abcn123213" //
// const defaultHotelFormData = {
//   name: "",
//   address: "",
//   // pincode: "",
//   city: "",
//   // country: "",
//   contact_number: "",
//   reservation_contact_number: "",
//   // status: "",
//   category: "",
//   location: "",
//   // check_in_time: "",
//   // check_out_time: "",
//   email_id: "",
//   contact_person_name: "",
//   account_details: "",
//   notes: "",
// };

const HotelFormCanvas = ({
  Title,
  show,
  hide,
  editId,
  hotelData,
}: IHotelFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  // const [hotelData, setData] =
  //   useState<Omit<HotelDataForm, "id">>(defaultHotelFormData);
  const {
    handleChange,
    setErrors,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      // role: hotelData?.role,
      name: hotelData?.name || "",
      address: hotelData?.address || "",
      // pincode: hotelData?.pincode,
      city: hotelData?.city || "",
      // country: hotelData?.country,
      contact_number: hotelData?.contact_number || "",
      reservation_contact_number: hotelData?.reservation_contact_number || "",
      // status: hotelData?.status,
      category: hotelData?.category || "",
      location: hotelData?.location || "",
      // check_in_time: hotelData?.check_in_time,
      // check_out_time: hotelData?.check_out_time,
      email_id: hotelData?.email_id || "",
      contact_person_name: hotelData?.contact_person_name || "",
      account_details: hotelData?.account_details || "",
      notes: hotelData?.notes || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: StringLengthValidation("Name", 3, 30),
      address: StringLengthValidation("Address", 5, 1000),
      // pincode: KeyRequired("Pincode"),
      // check_in_time: KeyRequired("Check in time"),
      // check_out_time: KeyRequired("Check out time"),
      // country: StringLengthValidation("Country", 5, 30),
      // status: RangeRequired("Status", 0, 5),
      // category: RangeRequired("Category", 1, 5),
      // account_details: KeyRequired("Account details"),
      contact_person_name: StringLengthValidation("Contact person", 3, 30),
      contact_number: LengthRequired("Contact number", 10),
      reservation_contact_number: LengthRequired(
        "Reservation contact number",
        10,
        false
      ),
      // city: KeyRequired("City"),
      email_id: EmailRequired("Email address", false),
      // notes: KeyRequired("Notes"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...values, id: editId };
        editU(formValue);
      } else {
        setPending(true);
        // const formValue = { ...values, id: editId };
        addU(values);
      }
    },
  });

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  const handleHide = useCallback(() => {
    // setData(defaultHotelFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<HotelDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addHotel(values)).unwrap();
        handleHide();
        toast.success("Hotel is added successfully");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  const editU = useCallback(
    async (values: HotelEditDataForm) => {
      try {
        await dispatch(updateHotel(values)).unwrap();
        toast.success("Hotel is updated successfully");
        dispatch(refreshHotelList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewHotel(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //             // pincode: data.pincode.toString(),
  //             // status: data.status.toString(),
  //             category: data.category.toString(),
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  useEffect(() => {
    resetForm();
    // setData(defaultHotelFormData);
  }, [resetForm, show]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="name"
                  labelText="Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.name}
                  touched={touched.name}
                  errors={errors.name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email_id"
                  inputType="email"
                  labelText="Email"
                  // required={true}
                  handleChange={handleChange}
                  value={values.email_id}
                  touched={touched.email_id}
                  errors={errors.email_id}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_person_name"
                  labelText="Contact Person Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_person_name}
                  touched={touched.contact_person_name}
                  errors={errors.contact_person_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="contact_number"
                  labelText="Contact number"
                  required={true}
                  handleChange={handleChange}
                  value={values.contact_number}
                  touched={touched.contact_number}
                  errors={errors.contact_number}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email_id"
                  labelText="Email"
                  required={true}
                  handleChange={handleChange}
                  value={values.email_id}
                  touched={touched.email_id}
                  errors={errors.email_id}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="check_in_time"
                  labelText="Check in time"
                  // required={true}
                  handleChange={handleChange}
                  value={values.check_in_time}
                  touched={touched.check_in_time}
                  errors={errors.check_in_time}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="check_out_time"
                  labelText="Check out time"
                  // required={true}
                  handleChange={handleChange}
                  value={values.check_out_time}
                  touched={touched.check_out_time}
                  errors={errors.check_out_time}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="pincode"
                  labelText="Pincode"
                  required={true}
                  handleChange={handleChange}
                  value={values.pincode}
                  touched={touched.pincode}
                  errors={errors.pincode}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="country"
                  labelText="Country"
                  required={true}
                  handleChange={handleChange}
                  value={values.country}
                  touched={touched.country}
                  errors={errors.country}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="address"
                  labelText="Address"
                  required={true}
                  handleChange={handleChange}
                  value={values.address}
                  touched={touched.address}
                  errors={errors.address}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="location"
                  labelText="Location"
                  // required={true}
                  handleChange={handleChange}
                  value={values.location}
                  touched={touched.location}
                  errors={errors.location}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  // required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="experience"
                  labelText="Experience"
                  required={true}
                  handleChange={handleChange}
                  value={values.experience}
                  touched={touched.experience}
                  errors={errors.experience}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="reservation_contact_number"
                  labelText="Reservation contact number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.reservation_contact_number}
                  touched={touched.reservation_contact_number}
                  errors={errors.reservation_contact_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="category"
                  labelText="Category"
                  // required={true}
                  handleChange={handleChange}
                  value={values.category}
                  touched={touched.category}
                  errors={errors.category}
                />
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="employees_size"
                  labelText="Employees size"
                  required={true}
                  handleChange={handleChange}
                  value={values.employees_size}
                  touched={touched.employees_size}
                  errors={errors.employees_size}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="status"
                  labelText="Status"
                  required={true}
                  handleChange={handleChange}
                  value={values.status}
                  touched={touched.status}
                  errors={errors.status}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="account_details"
                  labelText="Account details"
                  // required={true}
                  handleChange={handleChange}
                  value={values.account_details}
                  touched={touched.account_details}
                  errors={errors.account_details}
                />{" "}
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default HotelFormCanvas;
