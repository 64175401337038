import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT } from "src/utils/constants";

// export interface TaxiViewData {
//   id: string;
// }

export interface TaxiEditDataForm extends TaxiDataForm {
  id: string;
}

export interface TaxiDataForm {
  id: string;
  vehicle_number: string;
  vehicle_name: string;
  vehicle_category: string;
  insurance_date: string;
  insurance_price: string;
  service_date: string;
  km_at_service: string;
  service_price: string;
  fitness: string;
  pollution_expiry_date: string;
  other_expenses: string;
  notes: string;
  // status: string;
}

// export interface TaxiData {
//   id: string;
//   vehicle_number: string;
//   vehicle_name: string;
//   vehicle_category: string;
//   insurance_date: string;
//   insurance_price: string;
//   service_date: string;
//   km_at_service: number;
//   service_price: string;
//   fitness: string;
//   pollution_expiry_date: string;
//   other_expenses: number;
//   notes: string;
//   created_date: string;
//   updated_date: string;
//   company: string;
//   user: string;
// }

export interface TaxiData {
  id: string;
  vehicle_number: string;
  vehicle_name: string;
  notes: string;
  taxihistoryprofile_set: TaxiHistoryProfile[];
}

export interface TaxiHistoryProfile {
  id: string;
  total_maintenance_cost: string;
  year: number;
  insurance_renewal_date: string;
  insurance_date: string;
  insurance_price?: string;
  insurance_company: string;
  service_date: string;
  service_details: string;
  km_at_service: string;
  service_price: string;
  service_renewal_date: string;
  fitness_date: string;
  fitness_renewal_date: string;
  fitness_price: string;
  pollution_cert_start_date: string;
  pollution_expiry_date: string;
  pollution_cert_price: string;
  tyre_change_date: string;
  tyre_change_price: string;
  tyre_change_price_notes: string;
  km_running_at_tyre_change: string;
  other_expenses: string;
  notes: string;
  created_date: string;
  updated_date: string;
  taxi: string;
}

export interface TaxisState {
  data?: Pagination<TaxiData>;
  status: API_STATUS;
  list: {
    data: TaxiData[];
    status: API_STATUS;
  };
  view: {
    data?: TaxiData;
    status: API_STATUS;
  };
}

const initialState: TaxisState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  list: {
    data: [],
    status: "idle",
  },
  view: {
    data: undefined,
    status: "idle",
  },
  status: "idle",
};

export const taxisSlice = createSlice({
  name: "taxis",
  initialState,
  reducers: {
    refreshTaxiList: (state) => {
      state.status = "idle";
    },
    resetTaxiView(state) {
      state.view.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTaxis.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchTaxis.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchTaxis.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchListTaxis.pending, (state, action) => {
      state.list.status = "pending";
    });
    builder.addCase(fetchListTaxis.fulfilled, (state, action) => {
      state.list.status = "success";
      state.list.data = action.payload;
    });
    builder.addCase(fetchListTaxis.rejected, (state, action) => {
      state.list.status = "error";
    });
    builder.addCase(addTaxi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(viewTaxi.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(viewTaxi.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(viewTaxi.rejected, (state, action) => {
      state.view.status = "error";
    });
  },
});

export const fetchListTaxis = createAsyncThunk<
  TaxiData[],
  void,
  { state: RootState }
>("taxis/fetchList", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<TaxiData[]>,
      AxiosResponse<PartnerAPIResponseCover<TaxiData[]>>
    >(`partners/taxi/search/?format=json`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchTaxis = createAsyncThunk<
  Pagination<TaxiData>,
  { page: number; keyword: string },
  { state: RootState }
>("taxis/fetch", async ({ page, keyword }, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<TaxiData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<TaxiData>>>
    >(
      `partners/taxi?limit=${DATA_LIMIT}&keyword=${keyword}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`
    );
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const listTaxis = createAsyncThunk<
  TaxiData[],
  void,
  { state: RootState }
>("taxis/list", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<TaxiData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<TaxiData>>>
    >("partners/taxi/search/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const deleteTaxi = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("taxis/delete", async (taxi_id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>>
    >(`partners/taxi/${taxi_id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const updateTaxi = createAsyncThunk<
  { status: string },
  TaxiEditDataForm,
  { state: RootState }
>("taxis/update", async (data: TaxiEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, TaxiEditDataForm>,
      TaxiEditDataForm
    >(`partners/taxi/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const viewTaxi = createAsyncThunk<
  TaxiData,
  string,
  { state: RootState }
>("taxis/view", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<TaxiData>,
      AxiosResponse<APIResponseCover<TaxiData>>
    >(`partners/taxi/${id}?format=json`);
    // console.log(response.data);
    return response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addTaxi = createAsyncThunk<
  { status: string },
  TaxiDataForm,
  { state: RootState }
>("taxis/add", async (data: TaxiDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<{ id: string }>,
      AxiosResponse<APIResponseCover<{ id: string }>, TaxiDataForm>,
      TaxiDataForm
    >("partners/taxi?format=json", data);
    // console.log(response.data.data.id);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addTaxiProfile = createAsyncThunk<
  { status: string },
  TaxiDataForm,
  { state: RootState }
>("taxis/add", async (data: TaxiDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<{ id: string }>,
      AxiosResponse<APIResponseCover<{ id: string }>, TaxiDataForm>,
      TaxiDataForm
    >("partners/taxi/history_profile/?format=json", data);
    // console.log(response.data.data.id);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const updateTaxiProfile = createAsyncThunk<
  { status: string },
  TaxiEditDataForm,
  { state: RootState }
>("taxis/update", async (data: TaxiEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, TaxiEditDataForm>,
      TaxiEditDataForm
    >(`partners/taxi/history_profile/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshTaxiList, resetTaxiView } = taxisSlice.actions;

export default taxisSlice.reducer;
