import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import { APIResponseCover } from "./auth.d";
import { readAPIErrorMessage } from "src/utils/errorUtil";
import { API_STATUS, UserRole } from "src/utils/constants";

export interface UsersState {
  data?: UserData[];
  status: API_STATUS;
}

export interface UserViewData {
  uuid: string;
}

export interface UserEditDataForm {
  uuid: string;
  role: UserRole;
  first_name: string;
  last_name: string;
  // country_code: string;
  mobile_number: string;
  email: string;
  city: string;
}

export interface UserDataForm {
  role: UserRole;
  first_name: string;
  last_name: string;
  // country_code: string;
  mobile_number: string;
  email: string;
  city: string;
}

export interface UserData {
  uuid: string;
  company_id: string;
  user_id: string;
  role: UserRole;
  mobile_number: string;
  is_active: number;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  last_logged_in: string;
  city?: string;
  // created:        Date;
  // updated:        Date;
  created: string;
  updated: string;
}

export interface UserData {
  uuid: string;
  company_id: string;
  user_id: string;
  role: UserRole;
  is_active: number;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  last_logged_in: string;
  city?: string;
  // created:        Date;
  // updated:        Date;
  created: string;
  updated: string;
}

export interface ViewUserData {
  city: string;
  // country_code: number;
  email: string;
  first_name: string;
  id: string;
  last_logged_in: string;
  last_name: string;
  mobile_number: number;
  role: UserRole;
  uuid: string;
}

const initialState: UsersState = {
  data: [],
  status: "idle",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // setAuthentication: (state: any, { payload }: any) => {
    //   state.authentication = payload;
    // },
    refreshUserList: (state) => {
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.status = "idle";
    });
  },
});

export const fetchUsers = createAsyncThunk<
  UserData[],
  void,
  { state: RootState }
>("users/fetch", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<UserData[]>,
      AxiosResponse<APIResponseCover<UserData[]>>
    >("UsersApi/list-users/?format=json", {
      token: thunkAPI.getState().auth?.data?.token,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

// export const updateUser = createAsyncThunk<
//   { status: string },
//   UserEditDataForm,
//   { state: RootState }
// >("users/update", async (data: UserEditDataForm, thunkAPI) => {
//   try {
//     const response = await axiosInstance.post<
//       APIResponseCover<Record<never, never>>,
//       AxiosResponse<APIResponseCover<Record<never, never>>, UserEditDataForm>,
//       UserEditDataForm
//     >("UsersApi/update-user/?format=json", {
//       ...data,
//       token: thunkAPI.getState().auth?.data?.token,
//     });
//     console.log(response.data);
//     return thunkAPI.fulfillWithValue({ status: "pending" });
//   } catch (e) {
//     if (e instanceof AxiosError) {
//       throw new Error(readAPIErrorMessage(e));
//     } else {
//       throw new Error(e.message);
//     }
//   }
// });

// export const viewUser = createAsyncThunk<
//   { status: string },
//   UserViewData,
//   { state: RootState }
// >("users/view", async (data: UserViewData, thunkAPI) => {
//   try {
//     const response = await axiosInstance.post<
//       APIResponseCover<Record<never, never>>,
//       AxiosResponse<APIResponseCover<Record<never, never>>, UserViewData>,
//       UserViewData
//     >("UsersApi/view-user/?format=json", {
//       ...data,
//       token: thunkAPI.getState().auth?.data?.token,
//     });
//     console.log(response.data);
//     return thunkAPI.fulfillWithValue({ status: "pending" });
//   } catch (e) {
//     if (e instanceof AxiosError) {
//       throw new Error(readAPIErrorMessage(e));
//     } else {
//       throw new Error(e.message);
//     }
//   }
// });

export const addUser = createAsyncThunk<
  { status: string },
  UserDataForm,
  { state: RootState }
>("users/add", async (data: UserDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, UserDataForm>,
      UserDataForm
    >("UsersApi/add-user/?format=json", data);
    console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshUserList } = usersSlice.actions;

export default usersSlice.reducer;
