import React from "react";
import { Form } from "react-bootstrap";
import FormErrorFeedback from "./validations/FormErrorFeedback";

interface CustomInputFileWithLabelProps {
  inputType?: "email" | "number" | "text" | "password" | "file";
  id: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  labelText?: string;
//   value?: string | number;
  touched?: boolean;
  errors?: string | string[];
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const CustomInputFileWithLabel = ({
  inputType = "text",
  id,
  placeholder,
  labelText,
  readOnly,
  required,
  handleChange,
//   value = "",
  touched,
  errors,
}: CustomInputFileWithLabelProps) => {
  return (
    <>
      {labelText ? (
        <Form.Label htmlFor={id}>
          {labelText}
          {required ? <span className="text-danger">*</span> : null}
        </Form.Label>
      ) : null}
      <Form.Control
        id={id}
        readOnly={readOnly}
        placeholder={placeholder}
        name={id}
        type={inputType}
        onChange={handleChange}
        // value={value}
        isValid={touched && !errors}
        isInvalid={touched && !!errors}
      />
      {touched && errors ? (
        <FormErrorFeedback id={id} label={labelText} errors={errors} />
      ) : null}
    </>
  );
};
export default CustomInputFileWithLabel;
