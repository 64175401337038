import React, { ReactElement, ReactNode } from "react";
interface BookingDetailItemProps {
  title: string;
  value: ReactElement | string | number | null;
  hr?: boolean;
}
const BookingDetailItem = ({
  title,
  value,
}: // hr = true,
BookingDetailItemProps) =>
  value ? (
    <li>
      <span>{title}</span>
      <p>{value}</p>
    </li>
  ) : null;
// <>
//   <div className="row">
//     <div className="col-lg-6 col-md-6 col-sm-6 fw-normal">{title} :</div>
//     <div className="col-lg-6 col-md-6 col-sm-6">{value}</div>
//   </div>
//   {hr ? <hr className="m-1" /> : null}
// </>

export default BookingDetailItem;
