import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { useAppDispatch } from "src/store/hooks";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import {
  TaxiData,
  TaxiDataForm,
  TaxiEditDataForm,
  addTaxi,
  refreshTaxiList,
  updateTaxi,
  viewTaxi,
} from "src/store/reducers/taxis";
import {
  EmailRequired,
  KeyRequired,
  LengthRangeRequired,
  LengthRequired,
  // RangeRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";

type ITaxiFormCanvas = {
  // Title: string;
  // show: boolean;
  // editId: string;
  // hide: () => void;
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
  taxiData?: TaxiData;
};

// vehicle_number: string;
// vehicle_name: string;
// vehicle_category: string;
// insurance_date: string;
// insurance_price: string;
// service_date: string;
// km_at_service: number;
// service_price: string;
// fitness: string;
// pollution_expiry_date: string;
// other_expenses: number;
// notes: string;
const defaultTaxiFormData = {
  vehicle_name: "",
  vehicle_number: "",
  // vehicle_category: "",
  // insurance_date: "",
  // insurance_price: "",
  // service_date: "",
  // km_at_service: "",
  // service_price: "",
  // fitness: "",
  // pollution_expiry_date: "",
  // other_expenses: "",
  notes: "",
  // status: "",
};

const TaxiFormCanvas = ({
  Title,
  show,
  hide,
  editId,
  taxiData,
}: ITaxiFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  // const [taxiData, setData] =
  //   useState<Omit<TaxiDataForm, "id">>(defaultTaxiFormData);
  const {
    handleChange,
    setErrors,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      // role: taxiData.role,
      vehicle_name: taxiData?.vehicle_name || "",
      vehicle_number: taxiData?.vehicle_number || "",
      // vehicle_category: taxiData.vehicle_category,
      // insurance_date: taxiData.insurance_date,
      // insurance_price: taxiData.insurance_price,
      // service_date: taxiData.service_date,
      // km_at_service: taxiData.km_at_service,
      // service_price: taxiData.service_price,
      // fitness: taxiData.fitness,
      // pollution_expiry_date: taxiData.pollution_expiry_date,
      // other_expenses: taxiData.other_expenses,
      notes: taxiData?.notes || "",
      // status: taxiData.status,
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //   name: StringLengthValidation("Name", 3, 30),
    //   company_name: StringLengthValidation("Company name", 3, 30),
    //   address: StringLengthValidation("Address", 5, 30),
    //   // pincode: KeyRequired("Pincode"),
    //   // country: StringLengthValidation("Country", 3, 30),
    //   // status: RangeRequired("Status", 1, 5),
    //   employees_size: LengthRangeRequired("Employees size", 1, 3),
    //   // experience: LengthRangeRequired("Experience", 1, 7),
    //   contact_number: LengthRequired("Contact number", 10),
    //   alternate_number: LengthRequired("Alternative contact number", 10),
    //   city: KeyRequired("City"),
    //   notes: KeyRequired("Notes"),
    //   email_id: EmailRequired("Email address"),
    // }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (editId !== "Add") {
        setPending(true);
        const formValue = { ...values, id: editId };
        editU(formValue);
      } else {
        setPending(true);
        // const formValue = { ...values, id: editId };
        addU(values);
      }
    },
  });

  const handleHide = useCallback(() => {
    // setData(defaultTaxiFormData);
    // resetForm();
    hide();
  }, [hide]);

  const addU = useCallback(
    async (values: Omit<TaxiDataForm, "id">) => {
      // console.log(values);
      try {
        await dispatch(addTaxi(values)).unwrap();
        handleHide();
        toast.success("Travel Taxi is added successfully");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  const editU = useCallback(
    async (values: TaxiEditDataForm) => {
      try {
        await dispatch(updateTaxi(values)).unwrap();
        toast.success("Travel Taxi is updated successfully");
        dispatch(refreshTaxiList());
        handleHide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, handleHide, setErrors]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (editId && editId !== "Add") {
  //       try {
  //         const data = await dispatch(viewTaxi(editId)).unwrap();
  //         if (data) {
  //           setData({
  //             ...data,
  //             // pincode: data.pincode.toString(),
  //             // status: data.status.toString(),
  //           });
  //         }
  //       } catch (error: any) {
  //         toast.error(error.message || "Failed");
  //         hide();
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, editId, hide]);

  useEffect(() => {
    resetForm();
    // setData(defaultTaxiFormData);
  }, [resetForm, show]);

  return (
    <Offcanvas
      show={show}
      onHide={handleHide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={handleHide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_name"
                  labelText="Vehicle Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.vehicle_name}
                  touched={touched.vehicle_name}
                  errors={errors.vehicle_name}
                />{" "}
              </div>

              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_number"
                  labelText="Vehicle number"
                  required={true}
                  handleChange={handleChange}
                  value={values.vehicle_number}
                  touched={touched.vehicle_number}
                  errors={errors.vehicle_number}
                />{" "}
              </div>
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_category"
                  labelText="Vehicle category"
                  required={true}
                  handleChange={handleChange}
                  value={values.vehicle_category}
                  touched={touched.vehicle_category}
                  errors={errors.vehicle_category}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="insurance_date"
                  labelText="Insurance date"
                  required={true}
                  handleChange={handleChange}
                  value={values.insurance_date}
                  touched={touched.insurance_date}
                  errors={errors.insurance_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="insurance_price"
                  labelText="Insurance price"
                  required={true}
                  handleChange={handleChange}
                  value={values.insurance_price}
                  touched={touched.insurance_price}
                  errors={errors.insurance_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="service_date"
                  labelText="Service date"
                  required={true}
                  handleChange={handleChange}
                  value={values.service_date}
                  touched={touched.service_date}
                  errors={errors.service_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="km_at_service"
                  labelText="KM at service"
                  required={true}
                  handleChange={handleChange}
                  value={values.km_at_service}
                  touched={touched.km_at_service}
                  errors={errors.km_at_service}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="service_price"
                  labelText="Service price"
                  required={true}
                  handleChange={handleChange}
                  value={values.service_price}
                  touched={touched.service_price}
                  errors={errors.service_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="fitness"
                  labelText="Fitness"
                  required={true}
                  handleChange={handleChange}
                  value={values.fitness}
                  touched={touched.fitness}
                  errors={errors.fitness}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="pollution_expiry_date"
                  labelText="Pollution expiry date"
                  required={true}
                  handleChange={handleChange}
                  value={values.pollution_expiry_date}
                  touched={touched.pollution_expiry_date}
                  errors={errors.pollution_expiry_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="other_expenses"
                  labelText="Other expenses"
                  required={true}
                  handleChange={handleChange}
                  value={values.other_expenses}
                  touched={touched.other_expenses}
                  errors={errors.other_expenses}
                />
              </div> */}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="status"
                  labelText="Status"
                  required={true}
                  handleChange={handleChange}
                  value={values.status}
                  touched={touched.status}
                  errors={errors.status}
                />
              </div> */}
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={handleHide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default TaxiFormCanvas;
