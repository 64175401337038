// import React from react;
type IRectIcon = {
  fillColor?: string;
};
const RectIcon = ({ fillColor = "#3AC977" }: IRectIcon) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="10" rx="3" fill={fillColor} />
    </svg>
  );
};

export default RectIcon;
