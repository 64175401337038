import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  deleteTaxi,
  fetchTaxis,
  refreshTaxiList,
  // listTaxis,
} from "src/store/reducers/taxis";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import ConfirmationModal from "src/layouts/modals/ConfirmationModal";
import { toast } from "react-toastify";
import TaxiFormCanvas from "./TaxiFormCanvas";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import BottomPagination from "src/jsx/customComponents/pagination/BottomPagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";

const ListTaxis = () => {
  const dispatch = useAppDispatch();
  const taxiList = useAppSelector((state) => state.taxis.data);
  const taxiListStatus = useAppSelector((state) => state.taxis.status);
  const [editId, setEditId] = useState("");
  const [deleteTaxiId, setDeleteTaxiId] = useState<string>();
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  // const sort = 5;

  const [pending, setPending] = useState(false);

  const {
    handleChange,
    values,
    handleSubmit,
    // resetForm,
    // setFieldValue,
    // touched,
    // errors,
  } = useFormik({
    initialValues: {
      keyword: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      keyword: Yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      dispatch(fetchTaxis({ page, keyword: values.keyword }));
      setPending(false);
      // setSearchFormData(values);
    },
  });

  const retry = useCallback(() => {
    dispatch(fetchTaxis({ page, keyword: "" }));
  }, [dispatch, page]);

  const cancelDelete = () => {
    setDeleteTaxiId(undefined);
  };

  const confirmDelete = useCallback(async () => {
    if (deleteTaxiId) {
      try {
        const data = await dispatch(deleteTaxi(deleteTaxiId)).unwrap();
        toast.success("Taxi is deleted successfully");
        setDeleteTaxiId(undefined);
        setPage(1);
        retry();
      } catch (e: any) {
        FormikAPIErrorHandler(e.message, undefined, toast.error);
        // toast.error("Please try again");
      }
    }
  }, [deleteTaxiId, dispatch, retry]);

  useEffect(() => {
    if (taxiListStatus === "idle") {
      retry();
    }
  }, [retry, taxiListStatus]);

  useEffect(() => {
    return () => {
      dispatch(refreshTaxiList());
    };
  }, [dispatch]);

  const setPageAndFetch = useCallback(
    (page: number) => {
      setPage(page);
      retry();
    },
    [retry]
  );

  return (
    <>
      {/* <MainPagetitle mainTitle="Taxis" pageTitle="Taxis" parentTitle="Home" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <form onSubmit={handleSubmit}>
                <div className="row container-fluid">
                  <div className="col-xl-3 mb-3">
                    <CustomInputWithLabel
                      id="keyword"
                      placeholder={"Search"}
                      handleChange={handleChange}
                      value={values.keyword}
                    />
                  </div>
                  <div className="col-xl-2 mb-3">
                    <Button
                      className="form-control"
                      disabled={pending}
                      type="submit"
                    >
                      {pending ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Taxis</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setEditId("Add")}
                >
                  + Add Taxis
                </Link>
              </div>
              <div className="card-body ">
                {taxiListStatus === "success" ? (
                  taxiList?.results && taxiList?.results.length > 0 ? (
                    <div className="table-responsive active-projects">
                      {/* <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                      <h4 className="heading mb-0">Taxis</h4>
                    </div> */}
                      <div
                        id="projects-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="projects-tbl"
                          className="table table-hover dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Vehicle number</th>
                              {/* <th>Status</th> */}
                              {/* <th>Category</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {taxiList?.results?.map((taxi, index) => (
                              <tr key={taxi.id}>
                                <td>{taxi.vehicle_name}</td>
                                <td>{taxi.vehicle_number}</td>
                                {/* <td>
                                  <span className="tag tag-success">
                                    {UserRole[taxi.status]}
                                  </span>
                                </td> */}
                                {/* <td>{taxi.vehicle_category}</td> */}
                                <td>
                                  <NavLink
                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                    to={`/taxis/view/${taxi.id}`}
                                    title="View"
                                  >
                                    <i className={`fas fa-paper-plane`} />
                                    {/* <span>View</span> */}
                                  </NavLink>{" "}
                                  <NavLink
                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                    onClick={() => setEditId(taxi.id)}
                                    to={"#"}
                                    title="Edit"
                                  >
                                    <i className={`fas fa-pen`} />
                                    {/* <span>Edit</span> */}
                                  </NavLink>{" "}
                                  <NavLink
                                    className="btn btn-danger shadow btn-xs sharp"
                                    onClick={() => {
                                      setDeleteTaxiId(taxi.id);
                                    }}
                                    to={`#`}
                                    title="Delete"
                                  >
                                    <i className={`fas fa-x`} />
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <BottomPagination
                          count={taxiList?.count}
                          page={page}
                          setPageAndFetch={setPageAndFetch}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>NA</strong>
                    </div>
                  )
                ) : taxiListStatus === "pending" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : taxiListStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={typeof deleteTaxiId !== "undefined"}
        handleClose={cancelDelete}
        confirmClose={confirmDelete}
      />
      <TaxiFormCanvas
        show={editId !== ""}
        hide={() => setEditId("")}
        editId={editId}
        Title={editId === "Add" ? "Add Taxi" : "Edit Taxi"}
        taxiData={taxiList?.results?.find((taxi) => taxi.id === editId)}
      />
    </>
  );
};

export default ListTaxis;
