import { Form } from "react-bootstrap";

interface FormErrorFeedbackProps {
  id: string;
  label?: string;
  errors?: string | string[];
}

const viewError = (errors?: string | string[]) => {
  if (typeof errors == "string") {
    return errors;
  } else if (Array.isArray(errors)) {
    return errors[0];
  }
};

const FormErrorFeedback = ({ id, label, errors }: FormErrorFeedbackProps) => (
  <Form.Control.Feedback type="invalid">
    {label ? viewError(errors)?.replace(id, label) : viewError(errors)}
  </Form.Control.Feedback>
);

export default FormErrorFeedback;
