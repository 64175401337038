import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// import MainPagetitle from "../../../jsx/layouts/MainPagetitle";
import { Bar } from "react-chartjs-2";
import { Button, Card, Col, Row } from "react-bootstrap";
import "chart.js/auto";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchReports, refreshReportList } from "src/store/reducers/reports";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import { toast } from "react-toastify";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { fetchUsers } from "src/store/reducers/users";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

const monthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Report = () => {
  const dispatch = useAppDispatch();

  const [userArr, setUserArr] = useState<{ label: string; value: string }[]>(
    []
  );
  // const [calRange, setCalRange] = useState<{
  //   start: string;
  //   end: string;
  // }>();
  const [pending, setPending] = useState(false);
  const reportDataStatus = useAppSelector((state) => state.reports.status);
  const month_report = useAppSelector(
    (state) => state.reports.data?.month_report
  );
  const user_report = useAppSelector(
    (state) => state.reports.data?.user_report
  );
  const company_report = useAppSelector(
    (state) => state.reports.data?.company_report
  );

  const month_report_data = month_report?.map((data) => {
    return monthName[new Date(data.month).getMonth()];
  });
  const month_report_deals_closed = month_report?.map(
    (data) => data.deals_closed
  );
  const month_profit = month_report?.map((data) => data.profit);
  const month_revenue = month_report?.map((data) => data.revenue);
  // if (month_report) {
  //   console.log(month_report);
  // }

  const retry = useCallback(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  const fetchUserDataArr = useCallback(
    async (fetchMethod: any, label = "name", value = "id") => {
      try {
        const data = await dispatch(fetchMethod()).unwrap();
        setUserArr(
          data.map((vendor: any) => ({
            label: vendor[label],
            value: vendor[value],
          }))
        );
      } catch (error: any) {
        // FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        // setPending(false);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    (async function () {
      await fetchUserDataArr(fetchUsers, "first_name", "uuid");
    })();
  }, [dispatch, fetchUserDataArr]);
  const {
    handleChange,
    values,
    handleSubmit,
    // resetForm,
    setFieldValue,
    // touched,
    // errors,
  } = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      user_id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      start_date: Yup.string(),
      end_date: Yup.string(),
      user_id: Yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const finalValues = { ...values };
      setPending(true);
      Object.keys(finalValues).forEach((k) => {
        if (k !== "user_id" && !finalValues[k]) {
          finalValues[k] = null;
        } else if (k === "user_id" && !finalValues[k]) {
          delete finalValues[k];
        }
      });
      setSearchFormData(finalValues);
    },
  });

  const setSearchFormData = async (values: {
    start_date: string | null;
    end_date: string | null;
    user_id: string | undefined;
  }) => {
    try {
      await dispatch(fetchReports(values)).unwrap();
      toast.success("Report Data updated successfully");
    } catch (error: any) {
      console.log(error);
      toast.error("Report filtering failed, Please try again");
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    if (reportDataStatus === "idle") {
      retry();
    }
    // return () => {
    //   console.log(reportDataStatus);
    //   if (!["idle", "error", "pending"].includes(reportDataStatus)) {
    //     dispatch(refreshReportList());
    //   }
    // };
  }, [retry, reportDataStatus]);

  useEffect(
    () => () => {
      dispatch(refreshReportList());
    },
    [dispatch]
  );

  const data = useMemo(() => {
    const lengthF =
      month_report_data?.length < 4 ? 0.2 * month_report_data?.length : 0.8;
    // if (!Array.isArray(month_profit) || !Array.isArray(month_revenue)) {
    //   return {};
    // }
    return {
      defaultFontFamily: "Poppins",
      labels: month_report_data,
      datasets: [
        {
          label: "Revenue",
          data: month_revenue,
          // borderColor: "rgba(250, 56, 64,1)",
          // borderWidth: 2,
          // backgroundColor: "transparent",
          // pointBackgroundColor: "rgba(250, 56, 64,1)",
          // backgroundColor: "#FF5E5E",
          // pointBackgroundColor: "#FF5E5E",
          backgroundColor: "#34348f",
          pointBackgroundColor: "#34348f",
          tension: 0.4,
          // maxBarThickness: 90,
          categoryPercentage: lengthF,
        },
        {
          label: "Profits",
          data: month_profit,
          // borderColor: "rgba(44, 44, 44,1)",
          // borderWidth: 2,
          backgroundColor: "#3AC977",
          pointBackgroundColor: "#3AC977",
          // hoverBackgroundColor: "rgba(44, 44, 44, 1)",
          // backgroundColor: "rgba(149, 105, 255, 1)",
          // hoverBackgroundColor: "rgba(149, 105, 255, 1)",
          tension: 0.4,
          // maxBarThickness: 90,
          categoryPercentage: lengthF,
        },
      ],
    };
  }, [month_profit, month_report_data, month_revenue]);

  const footer = useCallback(
    (tooltipItems: any) => {
      return (
        "Deals closed: " +
        month_report_deals_closed?.[tooltipItems[0].dataIndex]
      );
    },
    [month_report_deals_closed]
  );

  function round(num: number, pre = 7) {
    if (!pre) pre = 0;
    var pow = Math.pow(10, pre);
    return Math.round(num * pow) / pow;
  }
  const options = useMemo(() => {
    if (!Array.isArray(month_profit) || !Array.isArray(month_revenue)) {
      return {};
    }
    return {
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          interaction: {
            intersect: false,
            mode: "index",
          },
          callbacks: {
            footer: footer,
          },
        },
      },
      scales: {
        y: {
          min: 0, // Math.min.apply(Math, [...month_profit, ...month_revenue]),
          max: round(Math.max.apply(Math, [...month_profit, ...month_revenue])),
          ticks: {
            beginAtZero: true,
            padding: 0,
          },
          grid: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },

        x: {
          ticks: {
            padding: 0,
          },
          grid: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    };
  }, [footer, month_profit, month_revenue]);

  return (
    <>
      {/* <MainPagetitle
        mainTitle="Reports"
        pageTitle="Reports"
        parentTitle="Home"
      /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Filter</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row container-fluid">
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="start_date"
                      placeholder={"Start Date"}
                      handleChange={(date) => setFieldValue("start_date", date)}
                      // labelContent={{ text: "Start Date", required: true }}
                      // handleChange={handleChange}
                      value={values.start_date}
                      pickerType="YYYY-MM-DD"
                      // touched={touched.start_date}
                      // errors={errors.start_date}
                      selectsStart
                      endDate={values.end_date}
                      // startDate={values.start_date}
                    />{" "}
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomDatePickerWithLabel
                      id="end_date"
                      placeholder={"End Date"}
                      handleChange={(date) => setFieldValue("end_date", date)}
                      // labelContent={{ text: "End Date", required: true }}
                      // handleChange={handleChange}
                      value={values.end_date}
                      pickerType="YYYY-MM-DD"
                      // touched={touched.end_date}
                      // errors={errors.end_date}
                      selectsEnd
                      startDate={values.start_date}
                      // endDate={values.end_date}
                    />
                  </div>
                  <div className="col-xl-3 mb-3">
                    <CustomReactSelectWithLabel
                      placeholder="User"
                      id={"user_id"}
                      options={userArr}
                      handleChange={handleChange}
                      value={
                        userArr.find((user) => user.value === values.user_id) ||
                        ""
                      }
                    />
                    {/* <CustomSelectWithLabel
                      id="user_id"
                      handleChange={handleChange}
                      value={values.user_id}
                    >
                      <option value={""}>Select User</option>
                      {userArr.map((agent) => (
                        <option value={agent.value} key={agent.value}>
                          {agent.label}
                        </option>
                      ))}
                    </CustomSelectWithLabel> */}
                  </div>
                  <div className="col-xl-1 mb-3"></div>
                  <div className="col-xl-2 mb-3">
                    {/* <Form.Label>&nbsp;</Form.Label> */}
                    <Button
                      className="form-control"
                      disabled={pending}
                      type="submit"
                    >
                      {pending ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Reports</h4>
              </div>
              <div className="card-body ">
                <Bar data={data} options={options} height={150} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-body ">
                {reportDataStatus === "success" ? (
                  user_report && user_report.length > 0 ? (
                    <div className="table-responsive active-projects">
                      {/* <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                      <h4 className="heading mb-0">Hotels</h4>
                    </div> */}
                      <div
                        id="projects-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="projects-tbl"
                          className="table table-hover dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Deal Closed</th>
                              <th>Revenue</th>
                              <th>Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={"company_report"}>
                              <td>{company_report?.[0].company_name || "Company"}</td>
                              <td>{company_report?.[0].deals_closed}</td>
                              <td>{company_report?.[0].revenue}</td>
                              <td>{company_report?.[0].profit}</td>
                            </tr>
                            {user_report?.map((userReport, index) => (
                              <tr key={userReport.user_id}>
                                <td>{userReport.username}</td>
                                <td>{userReport.deals_closed}</td>
                                <td>{userReport.revenue}</td>
                                <td>{userReport.profit}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>NA</strong>
                    </div>
                  )
                ) : reportDataStatus === "pending" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : reportDataStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
