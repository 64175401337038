import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import {
  BookingCabEditForm,
  editCabBooking,
  BookingCab,
  BookingCabForm,
  addCabBooking,
} from "src/store/reducers/bookings";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import { KeyRequired } from "src/layouts/form/validations/Validation";
import { fetchListDrivers } from "src/store/reducers/drivers";
import { fetchListCabVendors } from "src/store/reducers/cabVendors";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import { fetchListTaxis } from "src/store/reducers/taxis";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

type IBookingCabFormCanvas =
  | {
      Title: string;
      editId: "Add" | "";
      bookingId: string;
      hide: () => void;
      data: undefined;
    }
  | {
      Title: string;
      editId: Exclude<string, "Add" | "">;
      bookingId: string;
      hide: () => void;
      data: BookingCab;
    };

const BookingCabFormCanvas = ({
  Title,
  editId,
  bookingId,
  hide,
  data,
}: IBookingCabFormCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const cabVendorsList = useAppSelector((state) => state.cabVendors.list.data);
  const cabVendorsListStatus = useAppSelector(
    (state) => state.cabVendors.list.status
  );
  const taxisList = useAppSelector((state) => state.taxis.list.data);
  const taxisListStatus = useAppSelector((state) => state.taxis.list.status);
  const driversList = useAppSelector((state) => state.drivers.list.data);
  const driversListStatus = useAppSelector(
    (state) => state.drivers.list.status
  );

  const initialValues = data
    ? {
        cab_vendor: data?.cab_vendor?.id,
        driver: data?.driver?.id || "",
        arrival_place: data?.arrival_place || "",
        arrival_time: data?.arrival_time,
        vehicle_number: data?.vehicle_number,
        vehicle_type: data?.vehicle_type,
        trip_details: data?.trip_details,
        //   travel_agent: data?.travel_agent,
        cab_price: data?.cab_price,
        other_cab_expenses: data?.other_cab_expenses || "",
        dropping_place: data?.dropping_place,
        dropping_time: data?.dropping_time,
        opening_kms: data?.opening_kms,
        closing_kms: data?.closing_kms,
        taxi: data?.taxi?.id,
        advance_amount_paid: data?.advance_amount_paid || "",
      }
    : {
        cab_vendor: "",
        driver: "",
        arrival_place: "",
        arrival_time: "",
        vehicle_number: "",
        vehicle_type: "",
        trip_details: "",
        // travel_agent: "",
        cab_price: "",
        other_cab_expenses: "",
        dropping_place: "",
        dropping_time: "",
        opening_kms: "",
        closing_kms: "",
        taxi: "",
        advance_amount_paid: "",
      };

  // const navigate = useNavigate();
  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    errors,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      arrival_time: KeyRequired("Arrival Time"),
      // arrival_place: KeyRequired("Arrival place"),
      // driver: KeyRequired("Driver"),
      // vehicle_type: KeyRequired("Vehicle type"),
      // vehicle_number: KeyRequired("Vehicle number"),
      // trip_details: KeyRequired("Trip details"),
      // cab_vendor: KeyRequired("Cab vendor"),
      //   travel_agent: Yup.string().required("Travel agent is required"),
      cab_price: Yup.number().positive().integer(),
      other_cab_expenses: Yup.number().positive().integer(),
      advance_amount_paid: Yup.number().positive().integer(),
      // dropping_place: KeyRequired("Dropping place"),
      // taxi: KeyRequired("Taxi"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const finalValues: Omit<BookingCabForm, "booking"> = { ...values };
      setPending(true);
      ["cab_vendor", "taxi", "driver", "cab_price", "advance_amount_paid", "dropping_time"].forEach((k) => {
        if (!finalValues[k]) {
          finalValues[k] = null;
        }
      });
      // console.log(values);
      if (editId !== "" && editId !== "Add") {
        editBookingCab({ ...finalValues, id: editId, booking: bookingId });
      } else if (editId === "Add") {
        addBookingCab({ ...finalValues, booking: bookingId });
      }
    },
  });

  const addBookingCab = useCallback(
    async (values: BookingCabForm) => {
      try {
        await dispatch(addCabBooking(values)).unwrap();
        toast.success("Booking Cab is added successfully");
        hide();
        // setShow(false);
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, hide, setErrors]
  );

  const editBookingCab = useCallback(
    async (values: BookingCabEditForm) => {
      try {
        await dispatch(editCabBooking(values)).unwrap();
        toast.success("Booking Cab is updated successfully");
        hide();
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, hide, setErrors]
  );

  useEffect(() => {
    resetForm();
    if (!!editId) {
      dispatch(fetchListCabVendors());
      dispatch(fetchListDrivers());
      dispatch(fetchListTaxis());
    }
  }, [resetForm, editId, dispatch]);

  // useEffect(() => {
  //   if (cabVendorsListStatus === "idle") {
  //     dispatch(fetchListCabVendors());
  //   }
  // }, [cabVendorsListStatus, dispatch]);

  // useEffect(() => {
  //   if (driversListStatus === "idle") {
  //     dispatch(fetchListDrivers());
  //   }
  // }, [driversListStatus, dispatch]);

  // useEffect(() => {
  //   if (taxisListStatus === "idle") {
  //     dispatch(fetchListTaxis());
  //   }
  // }, [taxisListStatus, dispatch]);

  return (
    <Offcanvas
      show={!!editId}
      onHide={hide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h3 className="modal-title" id="#gridSystemModal">
          {Title}
        </h3>
        <button type="button" className="btn-close" onClick={hide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className="col-xl-12">
                <h4>Cab</h4>
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Cab vendor"
                  placeholder="Cab vendor"
                  id={"cab_vendor"}
                  touched={touched.cab_vendor}
                  errors={errors.cab_vendor}
                  options={cabVendorsList.map((cabVendor) => ({
                    label: cabVendor.contact_person_name,
                    value: cabVendor.id,
                  }))}
                  handleChange={handleChange}
                  value={
                    cabVendorsList
                      .filter((cabVendor) => cabVendor.id === values.cab_vendor)
                      ?.map((cabVendor) => ({
                        label: cabVendor.contact_person_name,
                        value: cabVendor.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="cab_vendor"
                  labelText="Cab vendor"
                  // required={true}
                  handleChange={handleChange}
                  value={values.cab_vendor}
                  touched={touched.cab_vendor}
                  errors={errors.cab_vendor}
                >
                  <option>Select cab vendor</option>
                  {cabVendorsList.map((cabVendor) => (
                    <option value={cabVendor.id} key={cabVendor.id}>
                      {cabVendor.contact_person_name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="cab_vendor"
                  labelText="Cab vendor"
                  required={true}
                  handleChange={handleChange}
                  value={values.cab_vendor}
                  touched={touched.cab_vendor}
                  errors={errors.cab_vendor}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="arrival_place"
                  labelText="Arrival place"
                  // required={true}
                  handleChange={handleChange}
                  value={values.arrival_place}
                  touched={touched.arrival_place}
                  errors={errors.arrival_place}
                />
              </div>{" "}
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="arrival_time"
                  //   pickerType="time"
                  labelText="Arrival Time"
                  required={true}
                  showTimeSelect={true}
                  pickerType="datetime"
                  handleChange={(date) => setFieldValue("arrival_time", date)}
                  value={values.arrival_time}
                  touched={touched.arrival_time}
                  errors={errors.arrival_time}
                />
              </div>{" "}
              {/* <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="travel_agent"
                  labelText="Travel agent"
                  required={true}
                  handleChange={handleChange}
                  value={values.travel_agent}
                  touched={touched.travel_agent}
                  errors={errors.travel_agent}
                />
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Taxi"
                  placeholder="Taxi"
                  id={"taxi"}
                  options={taxisList.map((taxi) => ({
                    label: taxi.vehicle_name,
                    value: taxi.id,
                  }))}
                  touched={touched.taxi}
                  errors={errors.taxi}
                  handleChange={handleChange}
                  value={
                    taxisList
                      .filter((taxi) => taxi.id === values.taxi)
                      ?.map((taxi) => ({
                        label: taxi.vehicle_name,
                        value: taxi.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="taxi"
                  labelText="Taxi"
                  // required={true}
                  handleChange={handleChange}
                  value={values.taxi}
                  touched={touched.taxi}
                  errors={errors.taxi}
                >
                  <option>Select Taxi</option>
                  {taxisList.map((taxi) => (
                    <option value={taxi.id} key={taxi.id}>
                      {taxi.vehicle_name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="taxi"
                  labelText="Taxi"
                  required={true}
                  handleChange={handleChange}
                  value={values.taxi}
                  touched={touched.taxi}
                  errors={errors.taxi}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Driver"
                  placeholder="Driver"
                  id={"driver"}
                  options={driversList.map((driver) => ({
                    label: driver.name,
                    value: driver.id,
                  }))}
                  handleChange={handleChange}
                  touched={touched.driver}
                  errors={errors.driver}
                  value={
                    driversList
                      .filter((driver) => driver.id === values.driver)
                      ?.map((driver) => ({
                        label: driver.name,
                        value: driver.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="driver"
                  labelText="Driver"
                  // required={true}
                  handleChange={handleChange}
                  value={values.driver}
                  touched={touched.driver}
                  errors={errors.driver}
                >
                  <option>Select Driver</option>
                  {driversList.map((driver) => (
                    <option value={driver.id} key={driver.id}>
                      {driver.name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="driver"
                  labelText="Driver"
                  required={true}
                  handleChange={handleChange}
                  value={values.driver}
                  touched={touched.driver}
                  errors={errors.driver}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="cab_price"
                  labelText="Cab price"
                  // required={true}
                  handleChange={handleChange}
                  value={values.cab_price}
                  touched={touched.cab_price}
                  errors={errors.cab_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="advance_amount_paid"
                  labelText="Advance amount paid"
                  // required={true}
                  handleChange={handleChange}
                  value={values.advance_amount_paid}
                  touched={touched.advance_amount_paid}
                  errors={errors.advance_amount_paid}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_number"
                  labelText="Vehicle number"
                  // required={true}
                  handleChange={handleChange}
                  value={values.vehicle_number}
                  touched={touched.vehicle_number}
                  errors={errors.vehicle_number}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="vehicle_type"
                  labelText="Vehicle type"
                  // required={true}
                  handleChange={handleChange}
                  value={values.vehicle_type}
                  touched={touched.vehicle_type}
                  errors={errors.vehicle_type}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="other_cab_expenses"
                  labelText="Other cab expenses"
                  // required={true}
                  handleChange={handleChange}
                  value={values.other_cab_expenses}
                  touched={touched.other_cab_expenses}
                  errors={errors.other_cab_expenses}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="dropping_place"
                  labelText="Dropping place"
                  // required={true}
                  handleChange={handleChange}
                  value={values.dropping_place}
                  touched={touched.dropping_place}
                  errors={errors.dropping_place}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="dropping_time"
                  //   pickerType="time"
                  labelText="Dropping Time"
                  required={true}
                  // pickerType="YYYY-MM-DD"
                  showTimeSelect={true}
                  pickerType="datetime"
                  handleChange={(date) => setFieldValue("dropping_time", date)}
                  value={values.dropping_time}
                  touched={touched.dropping_time}
                  errors={errors.dropping_time}
                />
              </div>{" "}
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="opening_kms"
                  labelText="Opening KMs"
                  // required={true}
                  handleChange={handleChange}
                  value={values.opening_kms}
                  touched={touched.opening_kms}
                  errors={errors.opening_kms}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="closing_kms"
                  labelText="Closing KMs"
                  // required={true}
                  handleChange={handleChange}
                  value={values.closing_kms}
                  touched={touched.closing_kms}
                  errors={errors.closing_kms}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="trip_details"
                  labelText="Trip details"
                  // required={true}
                  handleChange={handleChange}
                  value={values.trip_details}
                  touched={touched.trip_details}
                  errors={errors.trip_details}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={pending}
              className="btn btn-primary me-1"
            >
              Submit
            </button>
            <Link to={"#"} onClick={hide} className="btn btn-danger light ms-1">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default BookingCabFormCanvas;
