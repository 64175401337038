import { DATA_LIMIT } from "src/utils/constants";
import PaginationText from "./PaginationText";
import Pagination from "./Pagination";

interface IBottomPagination {
  count: number;
  page: number;
  setPageAndFetch: (page: number) => void;
}
const BottomPagination = ({
  count,
  page,
  setPageAndFetch,
}: IBottomPagination) => {
  return count <= DATA_LIMIT ? null : (
    <div className="d-sm-flex text-center justify-content-between align-items-center">
      <PaginationText
        currentPage={page}
        totalRecords={count}
        perPage={DATA_LIMIT}
      />
      <Pagination
        currentPage={page}
        totalRecords={count}
        perPage={DATA_LIMIT}
        changePage={setPageAndFetch}
      />
    </div>
  );
};
export default BottomPagination;
