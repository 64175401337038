import React, { useState } from "react";
import { Form, FormGroup, InputGroup } from "react-bootstrap";

interface CustomInputPasswordProps {
//   inputType?: "password";
  id: string;
  placeholder: string;
  value?: string | number;
  touched?: boolean;
  errors?: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const CustomInputPassword = ({
//   inputType = "password",
  id,
  placeholder,
  handleChange,
  //   name,
  value = "",
  touched,
  errors,
}: CustomInputPasswordProps) => {
    const [showPass, setShowPass] = useState(false);
  return (
    <InputGroup className="mb-3">
      <Form.Control
        id={id}
        name={id}
        type={showPass ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        isValid={touched && !errors}
        isInvalid={touched && !!errors}
      />
      <InputGroup.Text id="basic-addon2" onClick={() => { setShowPass(!showPass) }}>{showPass ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</InputGroup.Text>
      {touched && errors ? (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      ) : null}
    </InputGroup>
  );
};
export default CustomInputPassword;
