import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import logo from "../../images/logo/logo-full.png";
import LogoWhite from "../../images/logo/logofull-white.png";
import bg6 from "../../images/background/bg6.jpg";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { loginReq } from "src/store/reducers/auth";
import CustomInput from "src/layouts/form/CustomInput";
import {
  StringLengthValidation,
  LengthRequired,
} from "src/layouts/form/validations/Validation";
import LoginHeader from "src/layouts/modules/login/LoginHeader";
import CustomInputPassword from "src/layouts/form/CustomInputPassword";

export default function Login() {
  const authStatus = useAppSelector((state) => state.auth.status);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const login = async (mobile_number: string, password: string) => {
    try {
      await dispatch(loginReq({ mobile_number, password })).unwrap();
      toast.success("Login successful!");
      navigate("/");
    } catch (error: any) {
      toast.error(error.message || "Failed");
    }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      mobile_number: "",
      password: "",
    },
    validationSchema: Yup.object({
      mobile_number: LengthRequired("Mobile number", 10),
      password: StringLengthValidation("Password", 6, 30),
    }),
    onSubmit: (values) => {
      login(values.mobile_number, values.password);
    },
  });
  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ background: "#fff url(" + bg6 + ")", height: "100vh" }}
        >
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: "653px" }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    // dir: "ltr",
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <LoginHeader />
                      {/* 
                      <div className="logo-header">
                        <Link to={"#"} className="logo">
                          <img
                            src={logo}
                            alt=""
                            className="width-230 light-logo"
                          />
                        </Link>
                        <Link to={"#"} className="logo">
                          <img
                            src={LogoWhite}
                            alt=""
                            className="width-230 dark-logo"
                          />
                        </Link>
                      </div> 
                      */}
                      <div className="nav nav-tabs border-bottom-0">
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-personal"
                          >
                            <h3 className="form-title m-t0">Login</h3>
                            <div className="dz-separator-outer m-b5">
                              <div className="dz-separator bg-primary style-liner"></div>
                            </div>
                            <p>Enter your mobile number and your password. </p>
                            <form
                              onSubmit={handleSubmit}
                              // className=" dz-form pb-3"
                            >
                              <CustomInput
                                id="mobile_number"
                                placeholder="Mobile number"
                                // inputType="number"
                                handleChange={handleChange}
                                value={values.mobile_number}
                                touched={touched.mobile_number}
                                errors={errors.mobile_number}
                              />
                              <CustomInputPassword
                                id="password"
                                // inputType="password"
                                placeholder="Password"
                                handleChange={handleChange}
                                value={values.password}
                                touched={touched.password}
                                errors={errors.password}
                              />

                              <div className="form-group text-left mb-5 ">
                                <Button
                                  type="submit"
                                  disabled={authStatus === "pending"}
                                  // style={{ width: "100%" }}
                                  className="dz-xs-flex m-r5 "
                                >
                                  {authStatus === "pending" ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    "Login"
                                  )}
                                </Button>
                                {/* <span className="form-check d-inline-block ms-2 ">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="check1"
                                    name="example1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="check1"
                                  >
                                    Remember me
                                  </label>
                                </span> */}
                              </div>
                            </form>
                            <div className="text-center bottom p-1">
                              <NavLink
                                to="/register"
                                className="btn btn-primary button-md btn-block"
                              >
                                Create an account
                              </NavLink>
                            </div>

                            <div className="text-center bottom p-1">
                              <NavLink
                                to="/forgot-password"
                                className="btn btn-primary button-md btn-block"
                              >
                                Forgot Password
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-footer">
                      <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                        <div className="col-lg-12 text-center">
                          <span>
                            {" "}
                            © Copyright by{" "}
                            <span
                              className={`heart ${
                                heartActive ? "" : "heart-blast"
                              }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span>
                            <a
                              href="https://www.dexignzone.com/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {" "}
                              DexignZone{" "}
                            </a>{" "}
                            All rights reserved.
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
