import React, { useCallback, useEffect, useState } from "react";
import { Link, redirect, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// import ConfirmationModal from "src/layouts/modals/ConfirmationModal";
// import { toast } from "react-toastify";
import BookingDetailItemIcon from "../booking/BookingDetailItemIcon";
import { resetTaxiView, viewTaxi } from "src/store/reducers/taxis";
import ListTaxiProfile from "./ListTaxiProfile";
import BookingDetailItem from "../booking/BookingDetailItem";
import TaxiFormCanvas from "./TaxiFormCanvas";
import ViewTaxiProfile from "./ViewTaxiProfile";

const ViewTaxi = () => {
  const dispatch = useAppDispatch();

  const { id: taxiId } = useParams();

  const taxiViewStatus = useAppSelector((state) => state.taxis.view.status);
  const taxiData = useAppSelector((state) => state.taxis.view.data);
  // const [deleteProfileId, setDeleteProfileId] = useState<string>();
  const [editId, setEditId] = useState("");
  // const [checkedList, setCheckedList] = useState<number[]>([]);
  // const [currentPage, setCurrentPage] = useState(0);
  // const sort = 5;

  useEffect(() => {
    if (!taxiId) {
      redirect("/taxis");
    }
  }, [taxiId]);

  const retry = useCallback(() => {
    dispatch(viewTaxi(taxiId));
  }, [taxiId, dispatch]);

  useEffect(() => {
    if (taxiViewStatus === "idle") {
      retry();
    }
  }, [taxiViewStatus, retry]);

  useEffect(() => {
    if (taxiData?.id !== taxiId) {
      dispatch(resetTaxiView());
    }
  }, [taxiData?.id, taxiId, dispatch]);

  // const confirmDelete = useCallback(async () => {
  //   if (deleteProfileId) {
  //     try {
  //       dispatch(deleteHotelBooking(deleteProfileId));
  //       toast.success("Hotel deleted successfully");
  //     } catch (e) {
  //       toast.error("Please try again");
  //     } finally {
  //       setDeleteProfileId(undefined);
  //     }
  //   }
  //   // setShowConfirmation(false);
  // }, [deleteProfileId, dispatch]);

  // const cancelDelete = () => {
  //   setDeleteProfileId(undefined);
  // };

  return (
    <>
      {/* <MainPagetitle
        mainTitle="View Booking"
        pageTitle="View Booking"
        parentTitle="Home"
      /> */}
      <div className="container-fluid mh-auto">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {taxiViewStatus === "success" && taxiData ? (
                  <div className="row">
                    <div className="col-xl-3 col-lg-3  col-md-3 col-sm-3">
                      <div className="about-me">
                        <ul>
                          <BookingDetailItemIcon
                            title={"Vehicle name"}
                            icon={"taxi"}
                            value={taxiData.vehicle_name}
                          />
                          <BookingDetailItemIcon
                            icon={"hashtag"}
                            title={"Vehicle number"}
                            value={taxiData.vehicle_number}
                          />
                        </ul>
                      </div>
                      <div className="d-flex mt-4 justify-content-end">
                        <Link
                          to={"#"}
                          onClick={() => setEditId(taxiData.id)}
                          className="modal-btn btn btn-primary btn-sm  ms-2"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          Edit
                        </Link>
                        {/* <button
                          type="button"
                          className="modal-btn btn btn-primary btn-sm  ms-2 "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          <span>Edit </span>
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9  col-md-9 col-sm-9">
                      <div className="product-detail-content">
                        <div className=" pr">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                <BookingDetailItem
                                  title={"Notes"}
                                  value={taxiData.notes}
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : taxiViewStatus === "pending" ||
                  taxiViewStatus === "idle" ? (
                  <div className="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div
                      className="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : taxiViewStatus === "error" ? (
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={retry}
                    >
                      Retry
                    </button>{" "}
                    &nbsp;
                    <strong onClick={retry}>Please try again </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* review */}
        </div>
      </div>
      {taxiData ? (
        <>
          {/* <ListTaxiProfile
            taxiProfileData={taxiData.taxihistoryprofile_set}
            // setDeleteProfileId={setDeleteProfileId}
            taxiId={taxiId}
          /> */}
          <ViewTaxiProfile
            taxiProfileData={taxiData.taxihistoryprofile_set}
            // setDeleteProfileId={setDeleteProfileId}
            taxiId={taxiId}
          />
          <TaxiFormCanvas
            show={editId !== ""}
            hide={() => setEditId("")}
            editId={editId}
            Title={editId === "Add" ? "Add Taxi" : "Edit Taxi"}
            taxiData={taxiData}
          />
          {/* <ConfirmationModal
            show={typeof deleteProfileId !== "undefined"}
            handleClose={cancelDelete}
            confirmClose={confirmDelete}
          /> */}
        </>
      ) : null}
    </>
  );
};

export default ViewTaxi;
