import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
import {
  KeyRequired,
  LengthRequired,
} from "src/layouts/form/validations/Validation";
import {
  SettingDataForm,
  fetchSetting,
  setSetting,
} from "src/store/reducers/settings";
import CustomInputFileWithLabel from "src/layouts/form/CustomInputFileWithLabel";

const CompanySettingForm = () => {
  const settingsStatus = useAppSelector((state) => state.settings.status);
  const settingsData = useAppSelector((state) => state.settings.data);
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    errors,
    // setErrors,
  } = useFormik({
    initialValues: {
      company_name: settingsData?.company_name || "",
      address: settingsData?.address || "",
      contact_number: settingsData?.contact_number || "",
      email_id: settingsData?.email_id || "",
      gst_number: settingsData?.gst_number || "",
      // logo:settingsData?.logo || '', // Upload a image
      inclusions: settingsData?.inclusions || "",
      exclusions: settingsData?.exclusions || "",
      cancellation_policy: settingsData?.cancellation_policy || "",
      terms_and_conditions: settingsData?.terms_and_conditions || "",
      gain_margin: settingsData?.gain_margin || "",
      gst: settingsData?.gst || "",
      pan_number: settingsData?.pan_number || "",
      tan_number: settingsData?.tan_number || "",
      website: settingsData?.website || "",
      alternate_number: settingsData?.alternate_number || "",
      email_notifications: settingsData?.email_notifications || "",
      logo_img: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      contact_number: LengthRequired("Customer number", 10, false),
      email_id: Yup.string().email("Invalid email address"),
      logo_img: Yup.mixed()
        .test(
          "fileSize",
          "File Size is too large",
          (value) => {
            if (typeof value === 'undefined') return true
            return value.size <= 2097152 // 2MB
          }
        )
        .test("fileType", "Unsupported File Format", (value) => {            
          if (typeof value === 'undefined') return true
          return ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type)
        }
        ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      // console.log(values);
      // alert("Functionality pending");
      // toast.success("Settings saved successfully");
      setS(values);
    },
  });

  const setS = async (values: SettingDataForm) => {
    console.log(values);
    const fValues = {};
    try {
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          fValues[key] = values[key] ? values[key] : null;
        }
      }
      // console.log(JSON.stringify(fValues));
      if (typeof fValues['email_notifications'] === 'object') {
        fValues['email_notifications'] = fValues['email_notifications'].includes("1") ? "1" : "0"
      }
      const data = await dispatch(setSetting(fValues)).unwrap();
      toast.success("Settings updated successfully");
    } catch (error: any) {
      console.log(error);
      toast.error("Saving settings failed, Please try again");
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    console.log(errors);
    console.log(touched);
    console.log(values);
  }, [errors, touched, values]);

  useEffect(() => {
    if (settingsStatus === "idle") {
      dispatch(fetchSetting());
    }
  }, [settingsStatus, dispatch]);

  return (
    <>
      {/* <MainPagetitle mainTitle="Hotels" pageTitle="Hotels" parentTitle="Home" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Settings</h4>
              </div>
              <div className="card-body ">
                <div className="container-fluid">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="row">
                      {/* <div className="col-xl-12">
                <h4>Cab</h4>
              </div> */}
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="company_name"
                          labelText="Company name"
                          // required={true}
                          handleChange={handleChange}
                          value={values.company_name}
                          touched={touched.company_name}
                          errors={errors.company_name}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="gst_number"
                          labelText="Gst no."
                          // required={true}
                          handleChange={handleChange}
                          value={values.gst_number}
                          touched={touched.gst_number}
                          errors={errors.gst_number}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="pan_number"
                          labelText="PAN no."
                          // required={true}
                          handleChange={handleChange}
                          value={values.pan_number}
                          touched={touched.pan_number}
                          errors={errors.pan_number}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="tan_number"
                          labelText="TAN no."
                          // required={true}
                          handleChange={handleChange}
                          value={values.tan_number}
                          touched={touched.tan_number}
                          errors={errors.tan_number}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="contact_number"
                          labelText="Contact number"
                          // required={true}
                          handleChange={handleChange}
                          value={values.contact_number}
                          touched={touched.contact_number}
                          errors={errors.contact_number}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="email_id"
                          labelText="Email"
                          // required={true}
                          handleChange={handleChange}
                          value={values.email_id}
                          touched={touched.email_id}
                          errors={errors.email_id}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomTextAreaWithLabel
                          id="exclusions"
                          labelText="Exclusions"
                          // required={true}
                          handleChange={handleChange}
                          value={values.exclusions}
                          touched={touched.exclusions}
                          errors={errors.exclusions}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomTextAreaWithLabel
                          id="inclusions"
                          labelText="Inclusions"
                          // required={true}
                          handleChange={handleChange}
                          value={values.inclusions}
                          touched={touched.inclusions}
                          errors={errors.inclusions}
                        />
                      </div>
                      {/* <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="logo"
                          labelText="Logo"
                          inputType="file"
                          // required={true}
                          handleChange={handleChange}
                          value={values.logo ? "" : ""}
                          touched={touched.logo}
                          errors={errors.logo}
                        />
                      </div> */}
                      <div className="col-xl-6 mb-3">
                        <CustomInputFileWithLabel
                          id="logo_img"
                          labelText="Logo Image"
                          inputType="file"
                          // required={true}
                          handleChange={(event) => {
                            const files = event.target.files;
                            if (files.length > 0) {
                              const myFiles = files[0];
                              setFieldValue("logo_img", myFiles);
                            }
                          }}
                          // value={values.logo_img ? values.logo_img : undefined}
                          touched={touched.logo_img}
                          errors={errors.logo_img}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="gain_margin"
                          labelText="Gain margin %"
                          // required={true}
                          handleChange={handleChange}
                          value={values.gain_margin}
                          touched={touched.gain_margin}
                          errors={errors.gain_margin}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="gst"
                          labelText="GST %"
                          // required={true}
                          handleChange={handleChange}
                          value={values.gst}
                          touched={touched.gst}
                          errors={errors.gst}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="website"
                          labelText="Website"
                          // required={true}
                          handleChange={handleChange}
                          value={values.website}
                          touched={touched.website}
                          errors={errors.website}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <CustomInputWithLabel
                          id="alternate_number"
                          labelText="Alternate number"
                          // required={true}
                          handleChange={handleChange}
                          value={values.alternate_number}
                          touched={touched.alternate_number}
                          errors={errors.alternate_number}
                        />
                      </div>

                      <span className="form-check d-inline-block ms-2 ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={handleChange}
                          checked={values.email_notifications == 1}
                          value={1}
                          id="email_notifications"
                          name="email_notifications"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="email_notifications"
                        >
                          Email notifications
                        </label>
                      </span>
                      <div className="col-xl-12 mb-3">
                        <CustomTextAreaWithLabel
                          id="address"
                          labelText="Address"
                          // required={true}
                          handleChange={handleChange}
                          value={values.address}
                          touched={touched.address}
                          errors={errors.address}
                        />
                      </div>
                      <div className="col-xl-12 mb-3">
                        <CustomTextAreaWithLabel
                          id="cancellation_policy"
                          labelText="Cancellation policy"
                          // required={true}
                          handleChange={handleChange}
                          value={values.cancellation_policy}
                          touched={touched.cancellation_policy}
                          errors={errors.cancellation_policy}
                        />
                      </div>
                      <div className="col-xl-12 mb-3">
                        <CustomTextAreaWithLabel
                          id="terms_and_conditions"
                          labelText="Terms & conditions"
                          // required={true}
                          handleChange={handleChange}
                          value={values.terms_and_conditions}
                          touched={touched.terms_and_conditions}
                          errors={errors.terms_and_conditions}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={pending}
                      className="btn btn-primary me-1"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySettingForm;
