import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { ViewUserData, refreshUserList } from "src/store/reducers/users";
import { useAppDispatch } from "src/store/hooks";
import { EditUserForm, editUser, viewUser } from "src/apis/UserApi";
import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { UserRole } from "src/utils/constants";
import {
  EmailRequired,
  KeyRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";

type IUserEditCanvas = {
  Title: string;
  show: boolean;
  editId: string;
  hide: () => void;
};

const UserEditCanvas = ({ Title, show, hide, editId }: IUserEditCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  const [userData, setData] = useState<ViewUserData>({
    role: UserRole.Basic,
    first_name: "",
    last_name: "",
    // country_code: 0,
    mobile_number: 0,
    email: "",
    city: "",
    id: "",
    uuid: "",
    last_logged_in: "",
  });
  const { handleChange, values, handleSubmit, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        role: userData.role,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        city: userData.city,
        mobile_number: userData.mobile_number,
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        first_name: StringLengthValidation("First name", 3, 30),
        last_name: StringLengthValidation("Last name", 3, 30),
        city: KeyRequired("City"),
        email: EmailRequired("Email address"),
        role: Yup.number()
          .typeError("Role is required")
          .required("Role is required")
          .oneOf(
            [
              UserRole.Admin,
              UserRole.Basic,
              // UserRole["Super Admin"],
              UserRole.Manager,
            ],
            "You must select role"
          ),
      }),
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        if (userData?.uuid) {
          setPending(true);
          const formValue = { ...values, uuid: userData.uuid };
          editU(formValue);
        }
      },
    });

  const editU = async (values: EditUserForm) => {
    try {
      if (userData?.role === UserRole["Super Admin"]) {
        delete values.role;
      }
      await editUser(values);
      toast.success("User is updated successfully");
      resetForm();
      dispatch(refreshUserList());
      hide();
    } catch (error: any) {
      toast.error(error.message || "Failed");
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (editId) {
        const data = await viewUser(editId);
        if (data) {
          console.log(data);
          setData(data);
        }
      }
    };
    fetchData();
  }, [editId]);

  return (
    <Offcanvas
      show={show}
      onHide={hide}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {Title}
        </h5>
        <button type="button" className="btn-close" onClick={hide}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="first_name"
                  labelText="First Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.first_name}
                  touched={touched.first_name}
                  errors={errors.first_name}
                />{" "}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="last_name"
                  labelText="Last Name"
                  required={true}
                  handleChange={handleChange}
                  value={values.last_name}
                  touched={touched.last_name}
                  errors={errors.last_name}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="email"
                  labelText="Email"
                  required={true}
                  handleChange={handleChange}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="city"
                  labelText="City"
                  required={true}
                  handleChange={handleChange}
                  value={values.city}
                  touched={touched.city}
                  errors={errors.city}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="mobile_number"
                  labelText="Mobile number"
                  // required={true}
                  readOnly
                  handleChange={handleChange}
                  value={values.mobile_number}
                  touched={touched.mobile_number}
                  errors={errors.mobile_number}
                />
              </div>
              {userData?.role !== UserRole["Super Admin"] ? (
                <div className="col-xl-6 mb-3">
                  <CustomSelectWithLabel
                    id="role"
                    labelText="Role"
                    required={true}
                    handleChange={handleChange}
                    value={values.role}
                    touched={touched.role}
                    errors={errors.role}
                  >
                    <>
                      <option>Select role</option>
                      <option value="2">{UserRole[2]}</option>
                      <option value="4">{UserRole[4]}</option>
                      <option value="8">{UserRole[8]}</option>
                    </>
                  </CustomSelectWithLabel>
                </div>
              ) : null}

              <div>
                <button
                  type="submit"
                  disabled={pending}
                  className="btn btn-primary me-1"
                >
                  Submit
                </button>
                <Link
                  to={"#"}
                  onClick={hide}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default UserEditCanvas;
