import React from "react";
interface BookingDetailItemIconProps {
  title: string;
  icon: string;
  value: string | number | null;
}
const BookingDetailItemIcon = ({
  title,
  icon,
  value,
}: BookingDetailItemIconProps) => (
  <li>
    <i className={`fa-solid fa-${icon}`} title={title}></i>
    <div>
      <h6>{value}</h6>
    </div>
  </li>
);

export default BookingDetailItemIcon;
