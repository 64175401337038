// import React from react;
// type ICustomerServiceAgentIcon = {
//   strokeColor: string;
// };
const CustomerServiceAgentIcon = () => {
  // <style>.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px;}</style>
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 24"
      id="Layer_1"
      data-name="Layer 1"
    >
      <defs></defs>
      <rect
        stroke="#888888"
        // class="cls-1"
        x="6.27"
        y="5.32"
        width="11.45"
        height="15.27"
        rx="5.73"
      />
      <path
        stroke="#888888"
        d="M17.73,9.14h1.91A2.86,2.86,0,0,1,22.5,12v1.91a2.86,2.86,0,0,1-2.86,2.86H17.73a0,0,0,0,1,0,0V9.14A0,0,0,0,1,17.73,9.14Z"
      />
      <path
        stroke="#888888"
        d="M1.5,9.14H3.41A2.86,2.86,0,0,1,6.27,12v1.91a2.86,2.86,0,0,1-2.86,2.86H1.5a0,0,0,0,1,0,0V9.14A0,0,0,0,1,1.5,9.14Z"
        transform="translate(7.77 25.91) rotate(180)"
      />
      <path
        stroke="#888888"
        d="M4.36,9.14h0A7.64,7.64,0,0,1,12,1.5h0a7.64,7.64,0,0,1,7.64,7.64h0"
      />
      <path stroke="#888888" d="M19.64,16.77v1a4.78,4.78,0,0,1-4.78,4.77" />
    </svg>
  );
};

export default CustomerServiceAgentIcon;
