import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BookingHotel } from "src/store/reducers/bookings";
import AddHotelBookingCanvas from "./AddHotelBookingCanvas";
import EditBookingHotelCanvas from "./EditBookingHotelCanvas";

interface ListBookingCabsProps {
  bookingId: string;
  bookingHotelData: BookingHotel[];
  setDeleteHotelId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
function ListBookingHotels({
  bookingId,
  bookingHotelData,
  setDeleteHotelId,
}: ListBookingCabsProps) {
  const [editHotelId, setEditHotelId] = useState<number>();
  const [showHotelAddModal, setShowHotelAddModal] = useState(false);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Hotels</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  onClick={() => setShowHotelAddModal(true)}
                >
                  + Add Hotel
                </Link>
              </div>
              <div className="card-body ">
                {bookingHotelData.length > 0 ? (
                  <div className="table-responsive active-projects">
                    <div
                      id="projects-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="projects-tbl"
                        className="table table-hover dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Advance paid</th>
                            <th>Check in date</th>
                            <th>Check out date</th>
                            <th>Meal plan</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookingHotelData?.map((hotelData, index) => (
                            <tr key={hotelData.id}>
                              <td>{hotelData.hotel?.name}</td>
                              <td>{hotelData.hotel_price}</td>
                              <td>{hotelData.advance_amount_paid}</td>
                              <td>
                                {new Date(
                                  hotelData.checkin_date
                                ).toLocaleDateString('en-IN')}
                              </td>
                              <td>
                                {new Date(
                                  hotelData.checkout_date
                                ).toLocaleDateString('en-IN')}
                              </td>
                              <td>
                                {
                                  hotelData.meal_plan
                                }
                              </td>
                              {/* <td>{hotelData.}</td> */}
                              <td>
                                <NavLink
                                  className="btn btn-primary shadow btn-xs sharp me-1"
                                  onClick={() => setEditHotelId(index)}
                                  to={`#`}
                                  title="Edit"
                                >
                                  <i className={`fas fa-pen`} />
                                </NavLink>{" "}
                                <NavLink
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => {
                                    setDeleteHotelId(hotelData.id);
                                  }}
                                  to={`#`}
                                  title="Delete"
                                >
                                  <i className={`fas fa-x`} />
                                </NavLink>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                  <PaginationText
                    currentPage={currentPage}
                    totalRecords={tableData.length}
                    perPage={sort}
                  />
                  <Pagination
                    currentPage={currentPage}
                    totalRecords={tableData.length}
                    perPage={sort}
                    changePage={setCurrentPage}
                  />
                </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    &nbsp;
                    <strong>Add hotels for this booking </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddHotelBookingCanvas
        Title={"Add Hotel"}
        show={showHotelAddModal}
        bookingId={bookingId}
        setShow={setShowHotelAddModal}
      />
      <EditBookingHotelCanvas
        editId={
          typeof editHotelId !== "undefined"
            ? bookingHotelData?.[editHotelId]?.id
            : ""
        }
        Title={"Edit Hotel"}
        data={
          typeof editHotelId !== "undefined"
            ? bookingHotelData?.[editHotelId]
            : undefined
        }
        hide={() => setEditHotelId(undefined)}
      />
    </>
  );
}

export default ListBookingHotels;
