import React, { useEffect } from "react";

import DepositlineChart from "./DepositlineChart";
import AllProjectDonutChart from "./AllProjectDonutChart";
import { SVGICON } from "../../../constant/theme";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { fetchDashboardData } from "src/store/reducers/reports";
import { BOOKING_STATUS_LABEL } from "src/store/reducers/bookings";
import Rupee from "src/layouts/svgIcons/Rupee";
import DocumentIcon from "src/layouts/svgIcons/DocumentIcon";
import RectIcon from "src/layouts/svgIcons/RectIcon";

const CardWidget = () => {
  const dashboardData = useAppSelector(
    (state) => state.reports.dashboardDataObj.data
  );
  const dashboardDataStatus = useAppSelector(
    (state) => state.reports.dashboardDataObj.status
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (dashboardDataStatus === "idle") {
      dispatch(fetchDashboardData());
    }
  }, [dashboardDataStatus, dispatch]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-sm-12 text-center fw-bold">
        1 {months[new Date().getUTCMonth()]}, {new Date().getFullYear()} -{" "}
        {new Date().getUTCDate()} {months[new Date().getUTCMonth()]},{" "}
        {new Date().getFullYear()}
      </div>
      <div className="col-xl-3  col-lg-6 col-sm-6">
        <div className="widget-stat card">
          <div className="card-body p-4">
            <div className="media ai-icon">
              <span className="me-3 bgl-warning text-warning">
                <DocumentIcon />
              </span>
              <div className="media-body">
                <p className="mb-1">Total Bookings</p>
                <h4 className="mb-0">
                  {dashboardData?.revenue?.[0].total_bookings}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body depostit-card p-0">
            <div className="depostit-card-media d-flex justify-content-between pb-0">
              <div>
                <h6>Total Bookings</h6>
                <h3>{dashboardData?.revenue?.[0].total_bookings}</h3>
              </div>
            </div>
            <DepositlineChart chartcolor="var(--secondary)" />
          </div>
        </div>
      </div> */}
      <div className="col-xl-3 col-sm-6">
        <div className="card same-card">
          <div className="card-body d-flex align-items-center  py-2">
            {dashboardData?.status_report &&
            dashboardData?.status_report?.length > 0 ? (
              <AllProjectDonutChart statusData={dashboardData?.status_report} />
            ) : (
              <AllProjectDonutChart statusData={[]} />
            )}
            <ul className="project-list">
              <li>
                <h6>All Bookings</h6>
              </li>
              <li>
                <RectIcon fillColor="var(--secondary)" /> &nbsp;
                {BOOKING_STATUS_LABEL[0]}
              </li>
              <li>
                <RectIcon fillColor="var(--primary)" /> &nbsp;
                {BOOKING_STATUS_LABEL[1]}
              </li>
              <li>
                <RectIcon fillColor="#3AC977" /> &nbsp;
                {BOOKING_STATUS_LABEL[2]}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-xl-3  col-lg-6 col-sm-6">
        <div className="widget-stat card">
          <div className="card-body p-4">
            <div className="media ai-icon">
              <span className="me-3 bgl-danger text-danger">
                <Rupee strokeColor={"red"} />
              </span>
              <div className="media-body">
                <p className="mb-1">Total Revenue</p>
                <h4 className="mb-0">
                  {dashboardData?.revenue?.[0].total_revenue}
                </h4>
                {/* <span className="badge badge-warning">+3.5%</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3  col-lg-6 col-sm-6">
        <div className="widget-stat card">
          <div className="card-body p-4">
            <div className="media ai-icon">
              <span className="me-3 bgl-primary text-primary">
                <Rupee strokeColor={"var(--primary)"} />
              </span>
              <div className="media-body">
                <p className="mb-1">Total Profit</p>
                <h4 className="mb-0">
                  {dashboardData?.revenue?.[0].total_profit}
                </h4>
                {/* <span className="badge badge-warning">+3.5%</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body depostit-card p-0">
            <div className="depostit-card-media d-flex justify-content-between pb-0">
              <div>
                <h6>Total Revenue</h6>
                <h3>{dashboardData?.revenue?.[0].total_revenue}</h3>
              </div>
              <div className="icon-box bg-danger-light">
                <Rupee strokeColor={"red"} />
              </div>
            </div>
            <DepositlineChart chartcolor="#FF5E5E" />
          </div>
        </div>
      </div> 
      <div className="col-xl-3 col-sm-6 same-card">
        <div className="card">
          <div className="card-body depostit-card">
            <div className="depostit-card-media d-flex justify-content-between style-1">
              <div>
                <h6>Total Profit</h6>
                <h3>{dashboardData?.revenue?.[0].total_profit}</h3>
              </div>
              <div className="icon-box bg-primary-light">
                <Rupee strokeColor={"var(--primary)"} />
              </div>
            </div>
            <div className="progress-box mt-0">
              <div className="d-flex justify-content-between">
                <p className="mb-0">Tasks Not Finished</p>
                <p className="mb-0">20/28</p>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-primary"
                  style={{ width: "50%", height: "5px", borderRadius: "4px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CardWidget;
