import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "src/store/hooks";
import {
  BookingData,
  BookingTransactForm,
  // PartnerType,
  TransactionType,
  addBookingTransaction,
} from "src/store/reducers/bookings";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";
// import { KeyRequired } from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import { fetchListDrivers } from "src/store/reducers/drivers";
import { fetchListCabVendors } from "src/store/reducers/cabVendors";
import { fetchListAgents } from "src/store/reducers/agents";
// import { fetchListHotels } from "src/store/reducers/hotels";
// import { fetchUsers } from "src/store/reducers/users";
import { fetchListTaxis } from "src/store/reducers/taxis";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

type IAddTransaction = {
  Title: string;
  bookingId: string;
  bookingData?: BookingData;
  hide: () => void;
};
const AddTransaction = ({
  Title,
  bookingId,
  hide,
  bookingData,
}: IAddTransaction) => {
  const dispatch = useAppDispatch();
  const [vendorArr, setVendorArr] = useState<
    { label: string; value: string }[]
  >([]);

  const hotelsList = useMemo(
    () =>
      bookingData?.booking_hotels?.map((vendor) => ({
        label: vendor?.hotel?.name,
        value: vendor?.id,
      })) || [],
    [bookingData?.booking_hotels]
  );

  const cabsList = useMemo(
    () =>
      bookingData?.bookingcab_set?.filter(vendor => 
        typeof vendor.cab_vendor?.id !== 'undefined'
      ).map((vendor) => ({
        label: vendor?.cab_vendor?.contact_person_name,
        value: vendor?.id,
      })) || [],
    [bookingData?.bookingcab_set]
  );

  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    touched,
    errors,
    setErrors,
  } = useFormik({
    initialValues: {
      amount: "",
      notes: "",
      transaction_type: "",
      partner_type: "",
      group_id: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount received is a required")
        // .positive()
        .integer(),
      // notes: KeyRequired("Notes"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // console.log(values);
      addTransact(values);
    },
  });

  const GroupSelection = useMemo(
    () =>
      [
        { id: "driver", name: "Driver" },
        { id: "taxi", name: "Taxi" },
        { id: "agent", name: "Travel Agent" },
        { id: "booking_cabvendor", name: "Cab Vendor" },
        { id: "booking_hotel", name: "Hotel" },
        // { id: "user_id", name: "User" },
      ] as const,
    []
  );

  useEffect(() => {
    resetForm();
  }, [resetForm, bookingId]);

  const addTransact = useCallback(
    async (
      values: Pick<
        BookingTransactForm,
        | "amount"
        | "notes"
        | "transaction_type"
        | "agent"
        | "driver"
        | "taxi"
        | "booking_hotel"
        | "booking_cabvendor"
      > & {
        group_id: string;
        partner_type: "agent" | "driver" | "taxi" | "booking_hotel" | "booking_cabvendor";
      }
    ) => {
      try {
        const formValues: BookingTransactForm = {
          amount: values.amount,
          notes: values.notes,
          booking_id: bookingId,
        };
        if (values.transaction_type) {
          formValues.transaction_type = values.transaction_type;
        }
        if (values.partner_type && values.group_id !== "") {
          formValues[values.partner_type] = values.group_id;
        }
        await dispatch(addBookingTransaction(formValues)).unwrap();
        toast.success("Booking transaction succesfully added");
        hide();
        resetForm();
        //   navigate("/bookings");
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
        // toast.error(error.message || "Failed");
      }
    },
    [bookingId, dispatch, hide, resetForm, setErrors]
  );

  const fetchVendorDataArr = useCallback(
    async (fetchMethod: any, label = "name", value = "id") => {
      try {
        const data = await dispatch(fetchMethod()).unwrap();
        setVendorArr(
          data.map((vendor: any) => ({
            label: vendor[label],
            value: vendor[value],
          }))
        );
      } catch (error: any) {
        // FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        // setPending(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async function () {
      switch (values?.partner_type) {
        case "driver":
          await fetchVendorDataArr(fetchListDrivers, "name", "id");
          break;
        case "taxi":
          await fetchVendorDataArr(fetchListTaxis, "vehicle_name", "id");
          break;
        case "agent":
          await fetchVendorDataArr(fetchListAgents, "name", "id");
          break;
        case "booking_cabvendor":
          setVendorArr(cabsList);

          // await fetchVendorDataArr(
          //   fetchListCabVendors,
          //   "contact_person_name",
          //   "id"
          // );
          break;
        case "booking_hotel":
          setVendorArr(hotelsList);
          // await fetchVendorDataArr(fetchListHotels, "name", "id");
          break;
        default:
          setVendorArr([]);
          break;
      }
    })();
  }, [dispatch, fetchVendorDataArr, hotelsList, values?.partner_type]);

  return (
    <Modal
      className="modal fade"
      id="exampleModal1"
      show={bookingId !== ""}
      onHide={hide}
      centered
    >
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel1">
            {Title}
          </h1>
          <button type="button" className="btn-close" onClick={hide}></button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12">
                <CustomSelectWithLabel
                  id="transaction_type"
                  labelText="Transaction Type"
                  // required={true}
                  handleChange={handleChange}
                  value={values.transaction_type}
                  touched={touched.transaction_type}
                  errors={errors.transaction_type}
                >
                  <option>Select Transaction Type</option>
                  {[
                    { value: TransactionType.Inwards, label: "Received" },
                    { value: TransactionType.OutWards, label: "Paid" },
                  ].map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </CustomSelectWithLabel>
              </div>
              {values.transaction_type == TransactionType.OutWards ? (
                <>
                  <div className="col-xl-12 mt-3">
                    <CustomSelectWithLabel
                      id="partner_type"
                      labelText="Partner type"
                      // required={true}
                      handleChange={handleChange}
                      value={values.partner_type}
                      touched={touched.partner_type}
                      errors={errors.partner_type}
                    >
                      <option>Select Partner type</option>
                      {GroupSelection.map((group) => (
                        <option value={group.id} key={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </CustomSelectWithLabel>
                  </div>

                  <div className="col-xl-12 mt-3">
                    <CustomReactSelectWithLabel
                      // labelText="User"
                      placeholder={
                        GroupSelection.find(
                          (group) => group.id === values.partner_type
                        )?.name
                      }
                      id={"group_id"}
                      options={vendorArr}
                      handleChange={handleChange}
                      touched={touched.group_id}
                      errors={errors.group_id}
                      value={
                        vendorArr.filter(
                          (user) => user.value === values.group_id
                        ) || ""
                      }
                    />
                    {/* <CustomSelectWithLabel
                      id="group_id"
                      // placeholder={"Select User"}
                      // labelContent={{ text: "Select User" }}
                      handleChange={handleChange}
                      value={values.group_id}
                      // touched={touched.group_id}
                      // errors={errors.group_id}
                    >
                      <option>
                        Select{" "}
                        {GroupSelection.find(
                          ({ id }) => values.partner_type === id
                        )?.name ?? "Vendor"}
                      </option>
                      {vendorArr.map((agent) => (
                        <option value={agent.value} key={agent.value}>
                          {agent.label}
                        </option>
                      ))}
                    </CustomSelectWithLabel> */}
                  </div>
                </>
              ) : null}
              <div className="col-xl-12 mt-3">
                <CustomInputWithLabel
                  id="amount"
                  inputType="number"
                  labelText="Amount"
                  required={true}
                  handleChange={handleChange}
                  value={values.amount}
                  touched={touched.amount}
                  errors={errors.amount}
                />
              </div>
              <div className="col-xl-12">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={hide}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddTransaction;
