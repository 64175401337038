// import { APIResponseCover } from "./registration";
import { AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { APIResponseCover } from "src/store/reducers/auth.d";
import { UserData, ViewUserData } from "src/store/reducers/users";
import { UserRole } from "src/utils/constants";
// import { UserData, ViewUserData } from "@app/store/reducers/users";

export interface UserViewData {
  uuid: string;
}
export interface EditUserForm {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  role?: UserRole;
}
export interface DeleteUserForm {
  uuid: string;
  is_active: number;
}

export const viewUser = async (uuid: string) => {
  try {
    const data = { uuid: uuid };
    const response = await axiosInstance.post<
      APIResponseCover<ViewUserData[]>,
      AxiosResponse<APIResponseCover<ViewUserData[]>, UserViewData>,
      UserViewData
    >("UsersApi/view-user/?format=json", data);
    // console.log(response)
    return response?.data?.data?.[0];
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const editUser = async (data: EditUserForm) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<UserData[]>,
      AxiosResponse<APIResponseCover<UserData[]>, EditUserForm>,
      EditUserForm
    >("UsersApi/update-user/?format=json", data);
    console.log(response);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<any>,
      AxiosResponse<APIResponseCover<any>, DeleteUserForm>,
      DeleteUserForm
    >("UsersApi/update-user/?format=json", { uuid: id, is_active: 0 });
    console.log(response);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
