import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../store";
import axiosInstance from "src/apis/axiosInstance";
import {
  APIResponseCover,
  Pagination,
  PartnerAPIResponseCover,
} from "./auth.d";
import { readAPIErrorMessage, throwErrApi } from "src/utils/errorUtil";
import { API_STATUS, DATA_LIMIT } from "src/utils/constants";

export interface HotelDataForm {
  name: string;
  address: string;
  // country: string;
  // pincode: string;
  category: string;
  contact_number: string;
  // status: string;
  // check_in_time: string;
  // check_out_time: string;
  city: string;
  location: string;
  email_id: string;
  contact_person_name: string;
  reservation_contact_number: string;
  account_details: string;
  notes: string;
}

export interface HotelEditDataForm extends HotelDataForm {
  id: string;
}

export interface HotelData {
  id: string;
  name: string;
  address: string;
  city: string;
  location: string;
  // country: string;
  // pincode: number;
  category: string;
  // check_in_time: string;
  // check_out_time: string;
  contact_number: string;
  notes: string;
  email_id: string;
  contact_person_name: string;
  reservation_contact_number: string;
  account_details: string;
  status: number;
  created_date: string;
  updated_date: string;
}

export interface HotelsState {
  data?: Pagination<HotelData>;
  // data?: HotelData[];
  status: API_STATUS;
  list: {
    data: HotelData[];
    status: API_STATUS;
  };
  view: {
    data?: HotelData;
    status: API_STATUS;
  };
}

const initialState: HotelsState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  list: {
    data: [],
    status: "idle",
  },
  view: {
    data: undefined,
    status: "idle",
  },
  status: "idle",
};

export const hotelsSlice = createSlice({
  name: "hotels",
  initialState,
  reducers: {
    refreshHotelList: (state) => {
      state.status = "idle";
    },
    resetHotelView(state) {
      state.view.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHotels.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(fetchHotels.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchHotels.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(fetchListHotels.pending, (state, action) => {
      state.list.status = "pending";
    });
    builder.addCase(fetchListHotels.fulfilled, (state, action) => {
      state.list.status = "success";
      state.list.data = action.payload;
    });
    builder.addCase(fetchListHotels.rejected, (state, action) => {
      state.list.status = "error";
    });
    builder.addCase(addHotel.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(viewHotel.fulfilled, (state, action) => {
      state.view.data = action.payload;
      state.view.status = "success";
    });
    builder.addCase(viewHotel.pending, (state, action) => {
      state.view.status = "pending";
    });
    builder.addCase(viewHotel.rejected, (state, action) => {
      state.view.status = "error";
    });
  },
});

export const fetchHotels = createAsyncThunk<
  Pagination<HotelData>,
  { page: number; keyword: string },
  { state: RootState }
>("hotel/fetch", async ({ page, keyword }, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<HotelData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<HotelData>>>
    >(
      `partners/hotel?limit=${DATA_LIMIT}&keyword=${keyword}&offset=${
        DATA_LIMIT * (page - 1)
      }&format=json`
    );
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const fetchListHotels = createAsyncThunk<
  HotelData[],
  void,
  { state: RootState }
>("hotels/fetchList", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<HotelData[]>,
      AxiosResponse<PartnerAPIResponseCover<HotelData[]>>
    >(`partners/hotel/search/?format=json`);
    return thunkAPI.fulfillWithValue(response.data.data);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const listHotels = createAsyncThunk<
  HotelData[],
  void,
  { state: RootState }
>("hotel/list", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      PartnerAPIResponseCover<Pagination<HotelData>>,
      AxiosResponse<PartnerAPIResponseCover<Pagination<HotelData>>>
    >("partners/hotel/search/?format=json");
    // console.log(response.data);
    return thunkAPI.fulfillWithValue(response.data.data.results);
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const deleteHotel = createAsyncThunk<
  { status: string },
  string,
  { state: RootState }
>("hotel/delete", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.delete<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>>
    >(`partners/hotel/${id}?format=json`);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
    // return thunkAPI.rejectWithValue(e.response.data.data.data[0]);
    // console.log(e.response.data.data.data[0])
  }
});

export const updateHotel = createAsyncThunk<
  { status: string },
  HotelEditDataForm,
  { state: RootState }
>("hotel/update", async (data: HotelEditDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.put<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, HotelEditDataForm>,
      HotelEditDataForm
    >(`partners/hotel/${data.id}?format=json`, {
      ...data,
    });
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "pending" });
  } catch (e) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const viewHotel = createAsyncThunk<
  HotelData,
  string,
  { state: RootState }
>("hotel/view", async (id, thunkAPI) => {
  try {
    const response = await axiosInstance.get<
      APIResponseCover<HotelData>,
      AxiosResponse<APIResponseCover<HotelData>>
    >(`partners/hotel/${id}?format=json`);
    // console.log(response.data);
    return response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new Error(readAPIErrorMessage(e));
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const addHotel = createAsyncThunk<
  { status: string },
  HotelDataForm,
  { state: RootState }
>("hotel/add", async (data: HotelDataForm, thunkAPI) => {
  try {
    const response = await axiosInstance.post<
      APIResponseCover<Record<never, never>>,
      AxiosResponse<APIResponseCover<Record<never, never>>, HotelDataForm>,
      HotelDataForm
    >("partners/hotel?format=json", data);
    // console.log(response.data);
    return thunkAPI.fulfillWithValue({ status: "idle" });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throwErrApi(e);
    } else if (e instanceof Error) {
      throw new Error(e.message);
    }
    return thunkAPI.rejectWithValue(false);
  }
});

export const { refreshHotelList, resetHotelView } = hotelsSlice.actions;

export default hotelsSlice.reducer;
