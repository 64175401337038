import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInputWithLabel from "src/layouts/form/CustomInputWithLabel";
import { BookingHotelForm, addHotelBooking } from "src/store/reducers/bookings";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import CustomDatePickerWithLabel from "src/layouts/form/CustomDateTimeInputWithLabel";
import { KeyRequired } from "src/layouts/form/validations/Validation";
import { FormikAPIErrorHandler } from "src/utils/errorUtil";
import { fetchListHotels } from "src/store/reducers/hotels";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";
import CustomTextAreaWithLabel from "src/layouts/form/CustomTextAreaWithLabel";

type IAddHotelBookingCanvas = {
  Title: string;
  show: boolean;
  bookingId: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddHotelBookingCanvas = ({
  Title,
  show,
  bookingId,
  setShow,
}: IAddHotelBookingCanvas) => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();

  const hotelsList = useAppSelector((state) => state.hotels.list.data);
  // const hotelsListStatus = useAppSelector((state) => state.hotels.list.status);
  const {
    handleChange,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    // setFieldTouched,
    touched,
    errors,
    setErrors,
  } = useFormik<{
    hotel: string;
    room_category: string;
    checkin_date: string;
    checkout_date: string;
    hotel_price: string;
    advance_amount_paid: string;
    payment_notification_date: string | null;
    meal_plan: string;
    notes: string;
}>({
    initialValues: {
      hotel: "",
      room_category: "",
      checkin_date: "",
      checkout_date: "",
      hotel_price: "",
      advance_amount_paid: "",
      payment_notification_date: '',
      meal_plan: "",
      notes: "",
    },
    validationSchema: Yup.object({
      checkin_date: KeyRequired("Check in Date"),
      checkout_date: KeyRequired("Check out Date"),
      // payment_notification_date: KeyRequired("Payment notification Date"),
      hotel: KeyRequired("Hotel"),
      // room_category: KeyRequired("Room category"),
      hotel_price: Yup.number()
        .required("Hotel price is required")
        .typeError("Hotel price must be an number")
        .positive("Hotel price is required")
        .integer("Hotel price must be an integer"),
        // meal_plan:  KeyRequired("Check out Date"),
        // notes :  KeyRequired("Check out Date"),
      // advance_amount_paid: Yup.number()
      //   .required("Advance amount paid is required")
      //   .typeError("Advance amount paid is required")
      //   .positive("Advance amount paid is required")
      //   .integer("Advance amount paid must be an integer"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setPending(true);
      if (values.payment_notification_date === '') {
        values.payment_notification_date = null
      }
      // console.log(values);
      addU({ ...values, booking: bookingId });
    },
  });

  const addU = useCallback(
    async (values: BookingHotelForm) => {
      try {
        await dispatch(addHotelBooking(values)).unwrap();
        toast.success("Booking hotel is added successfully");
        setShow(false);
      } catch (error: any) {
        FormikAPIErrorHandler(error.message, setErrors, toast.error);
      } finally {
        setPending(false);
      }
    },
    [dispatch, setErrors, setShow]
  );

  // useEffect(() => {
  //   console.log(errors);
  //   console.log(touched);
  //   console.log(values);
  // }, [errors, touched, values]);

  useEffect(() => {
    resetForm();
    if (show) {
      dispatch(fetchListHotels());
    }
  }, [dispatch, resetForm, show]);

  // useEffect(() => {
  //   if (hotelsListStatus === "idle") {
  //     dispatch(fetchListHotels());
  //   }
  // }, [hotelsListStatus, dispatch]);

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h3 className="modal-title" id="#gridSystemModal">
          {Title}
        </h3>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShow(false)}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className="col-xl-12">
                <h4>Hotel</h4>
              </div> */}
              <div className="col-xl-6 mb-3">
                <CustomReactSelectWithLabel
                  labelText="Hotel"
                  placeholder="Hotel"
                  id={"hotel"}
                  required
                  // setFieldTouched={setFieldTouched}
                  options={hotelsList.map((hotel) => ({
                    label: hotel.name,
                    value: hotel.id,
                  }))}
                  touched={touched.hotel}
                  errors={errors.hotel}
                  handleChange={handleChange}
                  value={
                    hotelsList
                      .filter((hotel) => hotel.id === values.hotel)
                      ?.map((hotel) => ({
                        label: hotel.name,
                        value: hotel.id,
                      }))?.[0] || ""
                  }
                />
                {/* <CustomSelectWithLabel
                  id="hotel"
                  labelText="Hotel"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel}
                  touched={touched.hotel}
                  errors={errors.hotel}
                >
                  <option>Select hotel</option>
                  {hotelsList.map((hotel) => (
                    <option value={hotel.id} key={hotel.id}>
                      {hotel.name}
                    </option>
                  ))}
                </CustomSelectWithLabel> */}
                {/* <CustomInputWithLabel
                  id="hotel"
                  labelText="Hotel"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel}
                  touched={touched.hotel}
                  errors={errors.hotel}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="room_category"
                  labelText="Room category"
                  // required={true}
                  handleChange={handleChange}
                  value={values.room_category}
                  touched={touched.room_category}
                  errors={errors.room_category}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="checkin_date"
                  labelText="Check in date"
                  required={true}
                  handleChange={(date) => setFieldValue("checkin_date", date)}
                  value={values.checkin_date}
                  selectsStart
                  endDate={values.checkout_date}
                  touched={touched.checkin_date}
                  errors={errors.checkin_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="checkout_date"
                  labelText="Check out date"
                  required={true}
                  handleChange={(date) => setFieldValue("checkout_date", date)}
                  value={values.checkout_date}
                  selectsEnd
                  startDate={values.checkin_date}
                  touched={touched.checkout_date}
                  errors={errors.checkout_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="hotel_price"
                  labelText="Hotel price"
                  required={true}
                  handleChange={handleChange}
                  value={values.hotel_price}
                  touched={touched.hotel_price}
                  errors={errors.hotel_price}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="advance_amount_paid"
                  labelText="Advance amount paid"
                  // required={true}
                  handleChange={handleChange}
                  value={values.advance_amount_paid}
                  touched={touched.advance_amount_paid}
                  errors={errors.advance_amount_paid}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomDatePickerWithLabel
                  id="payment_notification_date"
                  labelText="Payment notification date"
                  // required={true}
                  handleChange={(date) =>
                    setFieldValue("payment_notification_date", date)
                  }
                  value={values.payment_notification_date || ''}
                  touched={touched.payment_notification_date}
                  errors={errors.payment_notification_date}
                />
              </div>
              <div className="col-xl-6 mb-3">
                <CustomInputWithLabel
                  id="meal_plan"
                  labelText="Meal plan"
                  // required={true}
                  handleChange={handleChange}
                  value={values.meal_plan}
                  touched={touched.meal_plan}
                  errors={errors.meal_plan}
                />
              </div>
              <div className="col-xl-12 mb-3">
                <CustomTextAreaWithLabel
                  id="notes"
                  labelText="Notes"
                  // required={true}
                  handleChange={handleChange}
                  value={values.notes}
                  touched={touched.notes}
                  errors={errors.notes}
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={pending}
              className="btn btn-primary me-1"
            >
              Submit
            </button>
            <Link
              to={"#"}
              onClick={() => setShow(false)}
              className="btn btn-danger light ms-1"
            >
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
};

export default AddHotelBookingCanvas;
