// import {GoogleProvider} from '@app/utils/oidc-providers';
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axiosInstance from "@app/apis/axiosInstance";
import { APIResponseCover, AuthResponse, LoginReq } from "./auth.d";
import { AxiosError, AxiosResponse } from "axios";
import { readAPIErrorMessage } from "src/utils/errorUtil";
import axiosInstance from "src/apis/axiosInstance";
import { API_STATUS, LocalStorageKey } from "src/utils/constants";

export interface AuthState {
  // authentication?: User;
  data?: AuthResponse;
  status: API_STATUS;
  forgot_password?: ForgotPasswordEmailForm | ForgotPasswordMobForm;
}

const initialState: AuthState = {
  // authentication: undefined,
  data: undefined,
  status: "idle",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.data = payload;
    },
    setForgotPasswordData: (
      state,
      {
        payload,
      }: PayloadAction<ForgotPasswordEmailForm | ForgotPasswordMobForm>
    ) => {
      state.forgot_password = payload;
    },
    logout: {
      prepare: () => {
        localStorage.removeItem(LocalStorageKey);
        return { payload: "" };
      },
      reducer: (state) => {
        state.data = undefined;
        state.status = "idle";
      },
    },
  },
  extraReducers(builder) {
    builder.addCase(loginReq.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(loginReq.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(loginReq.rejected, (state, action) => {
      state.status = "error";
    });
  },
});

export const loginReq = createAsyncThunk(
  "auth/login",
  async (data: LoginReq, thunkAPI) => {
    try {
      const response = await axiosInstance.post<
        APIResponseCover<AuthResponse>,
        AxiosResponse<APIResponseCover<AuthResponse>, LoginReq>,
        LoginReq
      >("UsersApi/login/?format=json", data);
      console.log(response.data);
      localStorage.setItem(LocalStorageKey, JSON.stringify(response.data.data));
      return thunkAPI.fulfillWithValue(response.data.data);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        throw new Error(readAPIErrorMessage(e));
      } else if (e instanceof Error) {
        throw new Error(e.message);
      }
    }
  }
);

export interface ForgotPasswordMobForm {
  mobile_number: string;
}

export interface ForgotPasswordEmailForm {
  email: string;
}
export const forgotPasswordReq = createAsyncThunk(
  "auth/forgot",
  async (data: ForgotPasswordMobForm | ForgotPasswordEmailForm, thunkAPI) => {
    try {
      const response = await axiosInstance.put<
        APIResponseCover<AuthResponse>,
        AxiosResponse<APIResponseCover<AuthResponse>, LoginReq>,
        ForgotPasswordMobForm | ForgotPasswordEmailForm
      >("UsersApi/send-code/?format=json", data);
      console.log(response.data);
      return response.data;
      // return thunkAPI.fulfillWithValue(response.data.data);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        // console.log(e?.response?.data?.data?.non_field_errors?.[0]);
        throw new Error(readAPIErrorMessage(e));
      } else if (e instanceof Error) {
        throw new Error(e.message);
      }
    }
  }
);

export interface ResetPasswordMobForm {
  mobile_number: string;
  otp: string;
  password: string;
}

export interface ResetPasswordEmailForm {
  email: string;
  otp: string;
  password: string;
}

export const resetPasswordReq = createAsyncThunk(
  "auth/reset",
  async (data: ResetPasswordMobForm | ResetPasswordEmailForm, thunkAPI) => {
    try {
      const response = await axiosInstance.put<
        APIResponseCover<AuthResponse>,
        AxiosResponse<APIResponseCover<AuthResponse>, LoginReq>,
        ResetPasswordMobForm | ResetPasswordEmailForm
      >("UsersApi/reset-password/?format=json", data);
      console.log(response.data);
      return response.data;
      // return thunkAPI.fulfillWithValue(response.data.data);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        // console.log(e?.response?.data?.data?.non_field_errors?.[0]);
        throw new Error(readAPIErrorMessage(e));
      } else if (e instanceof Error) {
        throw new Error(e.message);
      }
    }
  }
);

export const { setAuthentication, setForgotPasswordData, logout } =
  authSlice.actions;

export default authSlice.reducer;
