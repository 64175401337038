import { useState } from "react";
import { Link } from "react-router-dom";
import { TaxiHistoryProfile } from "src/store/reducers/taxis";
import TaxiProfileFormCanvas from "./TaxiProfileFormCanvas";
// import CustomSelectWithLabel from "src/layouts/form/CustomSelectWithLabel";
import TaxiProfileContainer from "src/layouts/container/TaxiProfileContainer";
import CustomReactSelectWithLabel from "src/layouts/form/CustomReactSelectWithLabel";

interface ViewTaxiProfileProps {
  taxiId: string;
  taxiProfileData: TaxiHistoryProfile[];
  // setDeleteProfileId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
function ViewTaxiProfile({
  taxiId,
  taxiProfileData,
}: // setDeleteProfileId,
ViewTaxiProfileProps) {
  const [editTaxiId, setEditTaxiId] = useState("");
  const [viewProfileId, setViewProfileId] = useState(taxiProfileData?.[0]?.id);

  const profileData = taxiProfileData.find(
    (profile) => profile.id === viewProfileId
  );

  // const [showTaxiAddModal, setShowTaxiAddModal] = useState(false);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Taxi Profile</h4>
                <Link
                  to={"#"}
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="offcanvas"
                  // onClick={() => setShowTaxiAddModal(true)}
                  onClick={() => setEditTaxiId("Add")}
                >
                  + Add Taxi Profile
                </Link>
              </div>
              <div className="card-body ">
                {taxiProfileData.length > 0 ? (
                  <>
                    <div className="card-header">
                      <div className="col-xl-3 mb-3">
                        <CustomReactSelectWithLabel
                          id={"profileYear"}
                          handleChange={(e) => {
                            setViewProfileId(e.target.value);
                          }}
                          // value={viewProfileId}
                          placeholder="year"
                          value={
                            taxiProfileData
                              .filter((profile) => profile.id === viewProfileId)
                              ?.map((profile) => ({
                                label: profile.year,
                                value: profile.id,
                              }))?.[0] || ""
                          }
                          options={taxiProfileData.map((profile) => ({
                            label: profile.year,
                            value: profile.id,
                          }))}
                        >
                          {/* {taxiProfileData?.map((profile) => (
                            <option value={profile.id} key={profile.id}>
                              {profile.year}
                            </option>
                          ))} */}
                        </CustomReactSelectWithLabel>
                      </div>
                      <div className="col-xl-3 mb-3"></div>
                      <div className="col-xl-3 mb-3"></div>
                      <div className="col-xl-3 mb-3 d-flex justify-content-end">
                        <Link
                          to={"#"}
                          onClick={() =>
                            profileData?.id
                              ? setEditTaxiId(profileData?.id)
                              : {}
                          }
                          className={
                            profileData?.id
                              ? "modal-btn btn btn-primary btn-sm  ms-2 "
                              : "modal-btn btn btn-primary btn-sm  ms-2 disabled"
                          }
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          Edit Current Profile
                        </Link>
                      </div>
                      <div className="col-xl-6 mb-3"></div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-xl-12">
                        <div className="row">
                          {profileData?.id ? (
                            <p className="fs-14 fw-bolder">
                              <b>Total maintainance cost</b>:{" "}
                              {profileData?.total_maintenance_cost}
                            </p>
                          ) : (
                            <p className="fs-14">
                              Please select year to view profile detail
                            </p>
                          )}
                        </div>
                      </div>

                      {profileData?.insurance_renewal_date ||
                      profileData?.insurance_price ||
                      profileData?.insurance_company ? (
                        <div className="col-xl-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="heading mb-0">
                                    Insurance Details
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <div className="row g-2 mb-3">
                                    <TaxiProfileContainer
                                      title="Renewal"
                                      value={
                                        profileData?.insurance_renewal_date ||
                                        " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="Price"
                                      value={
                                        profileData?.insurance_price || " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="Company"
                                      value={
                                        profileData?.insurance_company || " "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {profileData?.fitness_date ||
                      profileData?.fitness_price ||
                      profileData?.fitness_renewal_date ? (
                        <div className="col-xl-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="heading mb-0">
                                    Fitness Details
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <div className="row g-2 mb-3">
                                    <TaxiProfileContainer
                                      title="Date"
                                      value={profileData?.fitness_date || " "}
                                    />
                                    <TaxiProfileContainer
                                      title="Price"
                                      value={profileData?.fitness_price || " "}
                                    />
                                    <TaxiProfileContainer
                                      title="Renewal date"
                                      value={
                                        profileData?.fitness_renewal_date || " "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {profileData?.pollution_cert_start_date ||
                      profileData?.pollution_expiry_date ||
                      profileData?.pollution_cert_price ? (
                        <div className="col-xl-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="heading mb-0">
                                    Polllution Details
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <div className="row g-2 mb-3">
                                    <TaxiProfileContainer
                                      title="Start Date"
                                      value={
                                        profileData?.pollution_cert_start_date ||
                                        " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="End date"
                                      value={
                                        profileData?.pollution_expiry_date ||
                                        " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="Price"
                                      value={
                                        profileData?.pollution_cert_price || " "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {profileData?.service_details ||
                      profileData?.service_date ||
                      profileData?.km_at_service ||
                      profileData?.service_renewal_date ||
                      profileData?.service_price ? (
                        <div className="col-xl-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="heading mb-0">
                                    Service Details
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <p className="fs-14">
                                    <b>Service notes</b>:{" "}
                                    {profileData?.service_details}
                                  </p>
                                  <div className="row g-2 mb-3">
                                    <TaxiProfileContainer
                                      title="Date"
                                      value={profileData?.service_date || " "}
                                    />
                                    <TaxiProfileContainer
                                      title="Price"
                                      value={profileData?.service_price || " "}
                                    />
                                    <TaxiProfileContainer
                                      title="KM at service"
                                      value={profileData?.km_at_service || " "}
                                    />
                                    <TaxiProfileContainer
                                      title="Renewal date"
                                      value={
                                        profileData?.service_renewal_date || " "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {profileData?.tyre_change_price_notes ||
                      profileData?.tyre_change_date ||
                      profileData?.km_running_at_tyre_change ||
                      profileData?.tyre_change_price ? (
                        <div className="col-xl-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="heading mb-0">
                                    Tyre Change details
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <p className="fs-14">
                                    <b>Tyre change notes</b>:{" "}
                                    {profileData?.tyre_change_price_notes}
                                  </p>
                                  <div className="row g-2 mb-3">
                                    <TaxiProfileContainer
                                      title="Date"
                                      value={
                                        profileData?.tyre_change_date || " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="Price"
                                      value={
                                        profileData?.tyre_change_price || " "
                                      }
                                    />
                                    <TaxiProfileContainer
                                      title="KM running"
                                      value={
                                        profileData?.km_running_at_tyre_change ||
                                        " "
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {profileData?.other_expenses &&
                      Object.keys(profileData?.other_expenses).length > 0 ? (
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-6">
                              <div className="card">
                                <div className="card-body ">
                                  <div className="table-responsive active-projects ">
                                    <div
                                      id="projects-tbl_wrapper"
                                      className="dataTables_wrapper no-footer"
                                    >
                                      <table
                                        id="projects-tbl"
                                        className="table table-hover dataTable no-footer mb-0"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Expesne Name</th>
                                            <th>Expense amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(
                                            profileData?.other_expenses
                                          ).map((exp, index) => (
                                            <tr key={index}>
                                              <td>{exp}</td>
                                              <td>
                                                {
                                                  profileData?.other_expenses[
                                                    exp
                                                  ]
                                                }
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="col-xl-3 col-lg-3  col-md-3 col-sm-3">
                      <div className="about-me">
                        <ul>
                          <BookingDetailItemIcon
                            title={"Vehicle name"}
                            icon={"taxi"}
                            value={profileData?.taxi}
                          />
                          <BookingDetailItemIcon
                            icon={"hashtag"}
                            title={"Vehicle number"}
                            value={profileData?.fitness_date}
                          />
                        </ul>
                      </div>
                      <div className="d-flex mt-4 justify-content-end">
                        <Link
                          to={"#"}
                          onClick={() => setEditTaxiId(profileData?.id)}
                          className="modal-btn btn btn-primary btn-sm  ms-2"
                        >
                          <i className="fa-solid fa-pen-to-square me-1"></i>
                          Edit
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9  col-md-9 col-sm-9">
                      <div className="product-detail-content">
                        <div className=" pr">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6 c-details">
                              <ul>
                                <BookingDetailItem
                                  title={"Fitness date"}
                                  value={profileData?.fitness_date}
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    &nbsp;
                    <strong>Add history profile for this taxi </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TaxiProfileFormCanvas
        show={editTaxiId !== ""}
        editId={editTaxiId}
        taxiId={taxiId}
        Title={editTaxiId === "Add" ? "Add Taxi Profile" : "Edit Taxi Profile"}
        taxiProfileData={taxiProfileData?.find(
          (taxi) => taxi.id === editTaxiId
        )}
        hide={() => setEditTaxiId("")}
      />
    </>
  );
}

export default ViewTaxiProfile;
