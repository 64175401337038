// import React from react;

const BookingByte = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1328.27 330.76"
    >
      <defs>
        {/* <style>
      .cls-1 {
        fill: url(#linear-gradient-12);
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: url(#linear-gradient);
      }

      .cls-3 {
        fill: url(#linear-gradient-6);
      }

      .cls-4 {
        fill: url(#linear-gradient-11);
      }

      .cls-5 {
        fill: url(#linear-gradient-5);
      }

      .cls-6 {
        fill: url(#linear-gradient-2);
      }

      .cls-7 {
        fill: url(#linear-gradient-7);
      }

      .cls-8 {
        fill: url(#linear-gradient-3);
      }

      .cls-9 {
        fill: url(#linear-gradient-9);
      }

      .cls-10 {
        fill: url(#linear-gradient-8);
      }

      .cls-11 {
        fill: url(#linear-gradient-10);
      }

      .cls-12 {
        fill: url(#linear-gradient-4);
      }
    </style> */}
        <linearGradient
          id="linear-gradient"
          x1="384.97"
          y1="183.91"
          x2="464.12"
          y2="183.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#575ca8" />
          <stop offset=".46" stopColor="#585ba7" />
          <stop offset=".63" stopColor="#5f58a5" />
          <stop offset=".75" stopColor="#6b53a3" />
          <stop offset=".84" stopColor="#7c4b9e" />
          <stop offset=".93" stopColor="#924299" />
          <stop offset="1" stopColor="#ac3693" />
          <stop offset="1" stopColor="#ae3693" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="472.6"
          y1="194.51"
          x2="563.72"
          y2="194.51"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="570.94"
          y1="194.51"
          x2="662.06"
          y2="194.51"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="675.23"
          y1="176.91"
          x2="753.48"
          y2="176.91"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="763.45"
          y1="177.58"
          x2="790.45"
          y2="177.58"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="805.78"
          y1="192.94"
          x2="896.98"
          y2="192.94"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="905.83"
          y1="215.45"
          x2="998.81"
          y2="215.45"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="1011.31"
          y1="183.91"
          x2="1090.46"
          y2="183.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ae3693" />
          <stop offset=".01" stopColor="#b1398d" />
          <stop offset=".08" stopColor="#c54a73" />
          <stop offset=".16" stopColor="#d6595d" />
          <stop offset=".26" stopColor="#e2644c" />
          <stop offset=".38" stopColor="#eb6c40" />
          <stop offset=".54" stopColor="#f07039" />
          <stop offset="1" stopColor="#f27238" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-9"
          x1="1097.23"
          y1="216.56"
          x2="1183.67"
          y2="216.56"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="1184.56"
          y1="176.24"
          x2="1241.46"
          y2="176.24"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="1239.31"
          y1="193.28"
          x2="1323.51"
          y2="193.28"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="4.76"
          y1="165.38"
          x2="330.04"
          y2="165.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#575ca8" />
          <stop offset=".54" stopColor="#ae3693" />
          <stop offset="1" stopColor="#f27238" />
        </linearGradient>
      </defs>
      <g>
        <path
          fill="url(#linear-gradient)"
          strokeWidth={0}
          d="m394.57,239.1c-2.63,0-4.89-.94-6.77-2.83-1.89-1.89-2.83-4.17-2.83-6.84v-92.47c0-2.68.94-4.96,2.83-6.84s4.14-2.82,6.77-2.82c2.28,0,4.31.72,6.1,2.16,4.81-1.98,10.32-3.02,16.51-3.12h.67c11.95,0,21.64,3.72,29.09,11.16,6.25,6.3,9.37,13.96,9.37,22.99v.67c-.1,6.79-2.01,12.37-5.73,16.74,1.39.99,2.65,2.04,3.79,3.13,6.49,6.25,9.74,14.31,9.74,24.17,0,10.37-3.84,18.97-11.53,25.81-7.64,6.84-17.36,10.34-29.16,10.49h-.89c-8.33,0-15.65-1.49-21.95-4.46-1.73,1.39-3.74,2.08-6.02,2.08Zm16.29-92.61c-4.36.99-6.57,2.6-6.62,4.84v65.31c.3.15.54.32.74.52,4.27,3.37,10.09,5.06,17.48,5.06h.67c6.4-.1,11.63-1.73,15.69-4.91,4.02-3.17,6.03-7.21,6.03-12.12,0-4.41-1.26-7.84-3.79-10.27-4.12-3.97-9.84-5.95-17.18-5.95-.6,0-1.19.02-1.79.07-.5,0-1.04-.02-1.64-.07-.99.05-2.31.07-3.94.07h-.15c-2.63,0-4.89-.92-6.77-2.75-1.84-1.88-2.78-4.14-2.83-6.77v-.22c0-2.58.92-4.79,2.75-6.62,1.83-1.93,4.09-2.9,6.77-2.9,9.12-.15,15.15-1.16,18.07-3.05,1.74-1.14,2.63-3.1,2.68-5.88v-.22c0-3.82-1.26-6.99-3.79-9.52-3.57-3.62-8.58-5.43-15.03-5.43h-.74c-2.08,0-4.29.27-6.62.82Z"
        />
        <path
          fill="url(#linear-gradient-2)"
          strokeWidth={0}
          d="m563.72,194.54c0,13.24-4.46,24.55-13.39,33.92-8.88,9.37-19.62,14.06-32.21,14.06s-23.26-4.69-32.13-14.06c-8.93-9.37-13.39-20.68-13.39-33.92s4.46-24.57,13.39-33.99c8.87-9.37,19.59-14.06,32.13-14.06s23.33,4.69,32.21,14.06c8.93,9.42,13.39,20.75,13.39,33.99Zm-71.86,0c0,7.94,2.58,14.71,7.74,20.31,5.11,5.61,11.28,8.41,18.52,8.41s13.44-2.8,18.6-8.41c5.16-5.6,7.74-12.37,7.74-20.31s-2.58-14.7-7.74-20.31c-5.16-5.65-11.36-8.48-18.6-8.48s-13.41,2.83-18.52,8.48c-5.16,5.6-7.74,12.37-7.74,20.31Z"
        />
        <path
          fill="url(#linear-gradient-3)"
          strokeWidth={0}
          className="cls-8"
          d="m662.06,194.54c0,13.24-4.46,24.55-13.39,33.92-8.88,9.37-19.62,14.06-32.21,14.06s-23.26-4.69-32.13-14.06c-8.93-9.37-13.39-20.68-13.39-33.92s4.46-24.57,13.39-33.99c8.87-9.37,19.59-14.06,32.13-14.06s23.33,4.69,32.21,14.06c8.93,9.42,13.39,20.75,13.39,33.99Zm-71.86,0c0,7.94,2.58,14.71,7.74,20.31,5.11,5.61,11.28,8.41,18.52,8.41s13.44-2.8,18.6-8.41c5.16-5.6,7.74-12.37,7.74-20.31s-2.58-14.7-7.74-20.31c-5.16-5.65-11.36-8.48-18.6-8.48s-13.41,2.83-18.52,8.48c-5.16,5.6-7.74,12.37-7.74,20.31Z"
        />
        <path
          fill="url(#linear-gradient-4)"
          strokeWidth={0}
          className="cls-12"
          d="m684.9,240.59c-2.68,0-4.96-.94-6.84-2.83-1.89-1.83-2.83-4.09-2.83-6.77v-108.08c0-2.68.94-4.96,2.83-6.84,1.88-1.89,4.17-2.83,6.84-2.83s4.88.94,6.77,2.83c1.88,1.89,2.83,4.17,2.83,6.84v49.62l41.58-26.04c1.59-.99,3.25-1.49,4.98-1.49.74,0,1.51.1,2.31.3,2.58.6,4.59,2.01,6.03,4.24.99,1.59,1.49,3.25,1.49,4.98,0,.75-.1,1.51-.3,2.31-.6,2.58-2.01,4.59-4.24,6.03l-24.77,15.47c.74.65,1.39,1.39,1.93,2.23l28.42,45.3c1.04,1.59,1.56,3.27,1.56,5.06,0,.7-.1,1.44-.3,2.23-.6,2.58-2.03,4.59-4.31,6.03-1.54.94-3.22,1.41-5.06,1.41-3.57,0-6.3-1.49-8.18-4.46l-28.49-45.3c-.55-.84-.94-1.76-1.19-2.75l-11.46,7.22v35.7c0,2.68-.94,4.94-2.83,6.77-1.89,1.89-4.14,2.83-6.77,2.83Z"
        />
        <path
          fill="url(#linear-gradient-5)"
          strokeWidth={0}
          className="cls-5"
          d="m773.12,141.8c-2.63,0-4.89-.92-6.77-2.75-1.89-1.88-2.85-4.14-2.9-6.77v-.22c0-2.58.92-4.81,2.75-6.7l7.66-7.89c1.89-1.93,4.17-2.9,6.84-2.9h.22c2.53,0,4.73.89,6.62,2.68,1.89,1.89,2.85,4.14,2.9,6.77v.22c0,2.58-.92,4.81-2.75,6.7l-7.66,7.89c-1.93,1.98-4.24,2.98-6.92,2.98Zm-5.8,28.71c0-2.68.94-4.96,2.83-6.84s4.17-2.83,6.84-2.83,4.88.94,6.77,2.83c1.88,1.89,2.83,4.17,2.83,6.84v60.48c0,2.68-.94,4.96-2.83,6.84-1.89,1.84-4.14,2.75-6.77,2.75s-4.96-.92-6.84-2.75c-1.89-1.88-2.83-4.17-2.83-6.84v-60.48Z"
        />
        <path
          fill="url(#linear-gradient-6)"
          strokeWidth={0}
          className="cls-3"
          d="m850.71,164.27c-6.2,0-12,2.7-17.41,8.11-4.41,4.41-6.77,8.11-7.07,11.08,0,1.64-.4,3.15-1.19,4.54v43.22c0,2.68-.94,4.96-2.83,6.84-1.89,1.89-4.14,2.83-6.77,2.83s-4.96-.94-6.84-2.83c-1.89-1.89-2.83-4.17-2.83-6.84v-72.75c0-2.68.94-4.96,2.83-6.84,1.89-1.88,4.17-2.83,6.84-2.83,4.27,0,7.22,1.96,8.85,5.88,8.23-6.45,17.08-9.67,26.55-9.67h.22c13.34.1,24.27,4.46,32.8,13.09,8.53,8.58,12.87,19.61,13.02,33.1.05,6.45.07,13.02.07,19.71s-.03,13.49-.07,20.38c-.05,2.68-1.02,4.93-2.9,6.77-1.84,1.89-4.09,2.83-6.77,2.83h-.07c-2.68-.05-4.93-1.02-6.77-2.9s-2.75-4.09-2.75-6.62v-.22c.1-4.66.15-10.29.15-16.89s-.05-14.28-.15-22.91c-.1-9.08-3.05-16.04-8.85-20.9-4.86-4.02-10.84-6.07-17.93-6.17h-.15Z"
        />
        <path
          fill="url(#linear-gradient-7)"
          strokeWidth={0}
          className="cls-7"
          d="m905.83,193.06c0-13.29,4.56-24.65,13.69-34.07,9.17-9.42,20.18-14.13,33.03-14.13s23.75,4.64,32.88,13.91c1.83,1.84,2.75,4.04,2.75,6.62v.22c0,2.63-.97,4.88-2.9,6.77-1.84,1.84-4.04,2.75-6.62,2.75h-.22c-2.63,0-4.88-.94-6.77-2.83-5.31-5.45-11.68-8.18-19.12-8.18s-13.99,2.83-19.34,8.48c-5.41,5.65-8.11,12.47-8.11,20.46s2.7,14.73,8.11,20.38c5.36,5.65,11.8,8.48,19.34,8.48s13.98-2.78,19.19-8.33c5.21-5.6,7.81-12.45,7.81-20.53v-1.34c0-2.63.94-4.88,2.83-6.77s4.17-2.83,6.84-2.83,4.96.94,6.84,2.83c1.84,1.89,2.75,4.14,2.75,6.77v55.64c0,13.54-3.87,23.7-11.6,30.5-6.35,5.45-14.68,8.18-24.99,8.18-2.73,0-5.58-.2-8.55-.6-2.63-.35-4.76-1.59-6.4-3.72-1.29-1.73-1.94-3.64-1.94-5.73,0-.44.02-.92.07-1.41.4-2.63,1.66-4.76,3.79-6.4,1.68-1.29,3.59-1.94,5.73-1.94.45,0,.92.02,1.41.07,2.28.3,4.39.45,6.32.45,5.21,0,9.15-1.17,11.83-3.5,3.37-2.93,5.06-8.23,5.06-15.92v-15.17c-8.03,6.05-17.03,9.07-27,9.07-12.84,0-23.85-4.71-33.03-14.13-9.13-9.42-13.69-20.78-13.69-34.07Z"
        />
      </g>
      <g>
        <path
          fill="url(#linear-gradient-8)"
          strokeWidth={0}
          className="cls-10"
          d="m1020.91,239.1c-2.63,0-4.89-.94-6.77-2.83-1.89-1.89-2.83-4.17-2.83-6.84v-92.47c0-2.68.94-4.96,2.83-6.84s4.14-2.82,6.77-2.82c2.28,0,4.31.72,6.1,2.16,4.81-1.98,10.32-3.02,16.51-3.12h.67c11.95,0,21.64,3.72,29.09,11.16,6.25,6.3,9.37,13.96,9.37,22.99v.67c-.1,6.79-2.01,12.37-5.73,16.74,1.39.99,2.65,2.04,3.79,3.13,6.49,6.25,9.74,14.31,9.74,24.17,0,10.37-3.84,18.97-11.53,25.81-7.64,6.84-17.36,10.34-29.16,10.49h-.89c-8.33,0-15.65-1.49-21.95-4.46-1.73,1.39-3.74,2.08-6.02,2.08Zm16.29-92.61c-4.36.99-6.57,2.6-6.62,4.84v65.31c.3.15.54.32.74.52,4.27,3.37,10.09,5.06,17.48,5.06h.67c6.4-.1,11.63-1.73,15.69-4.91,4.02-3.17,6.03-7.21,6.03-12.12,0-4.41-1.26-7.84-3.79-10.27-4.12-3.97-9.84-5.95-17.18-5.95-.6,0-1.19.02-1.79.07-.5,0-1.04-.02-1.64-.07-.99.05-2.31.07-3.94.07h-.15c-2.63,0-4.89-.92-6.77-2.75-1.84-1.88-2.78-4.14-2.83-6.77v-.22c0-2.58.92-4.79,2.75-6.62,1.83-1.93,4.09-2.9,6.77-2.9,9.12-.15,15.15-1.16,18.07-3.05,1.74-1.14,2.63-3.1,2.68-5.88v-.22c0-3.82-1.26-6.99-3.79-9.52-3.57-3.62-8.58-5.43-15.03-5.43h-.74c-2.08,0-4.29.27-6.62.82Z"
        />
        <path
          fill="url(#linear-gradient-9)"
          strokeWidth={0}
          className="cls-9"
          d="m1129.96,234.71c-1.19-2.03-3.03-5.63-5.51-10.79-2.88-6.25-6.6-14.8-11.16-25.66-5.31-12.55-10.41-24.89-15.32-37.04-.5-1.24-.75-2.48-.75-3.72s.27-2.5.82-3.79c1.09-2.48,2.87-4.22,5.36-5.21,1.29-.54,2.55-.82,3.79-.82s2.48.27,3.72.82c2.48,1.09,4.22,2.88,5.21,5.36,9.18,22.61,17.01,41.31,23.51,56.09,6.49-14.83,14.33-33.52,23.51-56.09.99-2.48,2.73-4.27,5.21-5.36,1.24-.54,2.48-.82,3.72-.82s2.55.27,3.79.82c2.48.99,4.27,2.73,5.36,5.21.54,1.24.82,2.48.82,3.72s-.27,2.51-.82,3.79c-4.91,12.2-10,24.55-15.25,37.04-4.61,10.86-8.33,19.41-11.16,25.66-2.28,4.96-3.92,8.31-4.91,10.04,0,.1-.03.2-.07.3h-.37v12.5c0,7.69,1.68,12.99,5.06,15.92,2.68,2.33,6.62,3.5,11.83,3.5,1.94,0,4.04-.15,6.32-.45.49-.05.97-.07,1.41-.07,2.13,0,4.04.65,5.73,1.94,2.13,1.64,3.4,3.77,3.79,6.4.05.49.07.97.07,1.41,0,2.08-.65,3.99-1.93,5.73-1.64,2.13-3.77,3.37-6.4,3.72-2.98.4-5.83.6-8.55.6-10.32,0-18.65-2.73-24.99-8.18-7.74-6.8-11.63-16.96-11.68-30.5l-.15-12.05Z"
        />
        <path
          fill="url(#linear-gradient-10)"
          strokeWidth={0}
          className="cls-11"
          d="m1231.79,144.63c2.68,0,4.96.94,6.84,2.83,1.88,1.89,2.83,4.14,2.83,6.77s-.94,4.96-2.83,6.84c-1.89,1.89-4.17,2.83-6.84,2.83h-11.83v67.17c0,2.68-.94,4.96-2.83,6.84-1.89,1.89-4.17,2.83-6.84,2.83s-4.88-.94-6.77-2.83c-1.89-1.89-2.83-4.17-2.83-6.84v-67.17h-6.47c-2.68,0-4.96-.94-6.84-2.83s-2.83-4.17-2.83-6.84.94-4.88,2.83-6.77,4.17-2.83,6.84-2.83h6.47v-23.21c0-2.68.94-4.96,2.83-6.84,1.89-1.88,4.14-2.83,6.77-2.83s4.96.94,6.84,2.83c1.89,1.89,2.83,4.17,2.83,6.84v23.21h11.83Z"
        />
        <path
          fill="url(#linear-gradient-11)"
          strokeWidth={0}
          className="cls-4"
          d="m1282.75,241.48c-11.95,0-22.19-4.71-30.72-14.13-8.48-9.42-12.72-20.78-12.72-34.07s4.24-24.65,12.72-34.07c8.53-9.42,18.77-14.13,30.72-14.13,9.62,0,17.68,2.43,24.18,7.29,7.34,5.46,11.01,13.07,11.01,22.84,0,10.31-6.3,18.65-18.89,24.99-6.84,3.42-14.18,5.83-22.02,7.22-.6.1-1.17.15-1.71.15-1.98,0-3.82-.6-5.5-1.79-2.18-1.54-3.5-3.62-3.94-6.25-.1-.6-.15-1.16-.15-1.71,0-1.98.6-3.82,1.79-5.5,1.54-2.18,3.62-3.5,6.25-3.94,6.05-1.04,11.63-2.88,16.74-5.51,5.41-2.73,8.11-5.28,8.11-7.66,0-7.24-5.28-10.86-15.84-10.86-6.65,0-12.32,2.82-17.03,8.48-4.76,5.65-7.14,12.47-7.14,20.46s2.38,14.78,7.14,20.38c4.71,5.65,10.39,8.48,17.03,8.48,10.66,0,18.17-4.17,22.54-12.5,1.24-2.33,3.15-3.89,5.73-4.69.99-.3,1.96-.45,2.9-.45,1.54,0,3.03.37,4.46,1.12,2.33,1.24,3.89,3.15,4.69,5.73.3.99.45,1.96.45,2.9,0,1.49-.37,2.95-1.12,4.39-8.03,15.22-21.25,22.84-39.65,22.84Z"
        />
      </g>
      <path
        fill="url(#linear-gradient-12)"
        strokeWidth={0}
        d="m244.35,2.74H90.45C43.32,2.74,4.76,41.3,4.76,88.43v153.9c0,47.13,38.56,85.69,85.69,85.69h153.9c47.13,0,85.69-38.56,85.69-85.69V88.43c0-47.13-38.56-85.69-85.69-85.69Zm-110.17,253.05h-.36c-.33.34-.68.67-1.04.98-2.47,2.12-5.7,3.4-9.22,3.4-4.04,0-7.68-1.69-10.27-4.38h-15.34c-10.84,0-20.91-4.86-27.59-13.31-12.15-15.36-17.05-32.91-13.79-49.42,2.89-14.73,11.97-27.47,24.93-35.12-9.81-9.06-15.41-21.81-15.41-35.15s4.98-24.73,14.05-33.78c9.02-9.06,21.02-14.03,33.75-14.03h68.18c4.59,0,8.3,3.71,8.3,8.3v104.52c3.33,2.62,5.48,6.67,5.48,11.24s-1.96,8.25-5.05,10.87c-2.47,2.12-5.7,3.4-9.22,3.4-7.89,0-14.27-6.4-14.27-14.27,0-3.25,1.1-6.22,2.92-8.62-.02-.35-.05-.7-.05-1.05v-94.48h-56.29c-7.43,0-14.4,2.9-19.69,8.19-5.31,5.32-8.2,12.28-8.2,19.67,0,.31,0,.6.01.9.26,8.88,4.76,17.61,12.61,24.59,1.7,1.49,3.9,2.35,6.25,2.44h1.25c5.51,0,10.5,2.23,14.11,5.84,3.61,3.61,5.84,8.6,5.84,14.11h-27.48c-4.23,0-8.33,1.83-11.24,4.99-11.62,12.7-14.95,27.56-9.13,40.75,5.22,11.86,16.95,19.52,29.89,19.52h5.31c.53-.53,1.08-1.04,1.69-1.49,2.37-1.75,5.29-2.77,8.45-2.77,7.87,0,14.27,6.4,14.27,14.28,0,3.64-1.39,6.96-3.65,9.48v.4Zm129.14-15.28c-9.7,9.44-23.05,15.28-37.7,15.28h-74.94c-4.58,0-8.3-3.71-8.3-8.29v-126.28c5.58,0,10.62,2.25,14.28,5.91,3.66,3.66,5.91,8.69,5.91,14.27v94.48h64.12c8.95,0,17.29-3.55,23.5-10,6.22-6.46,9.44-14.93,9.11-23.87-.64-17.33-15.63-31.39-33.42-31.39h-27.18c0-5.51,2.23-10.5,5.84-14.11,3.6-3.61,8.59-5.84,14.1-5.84h2.47c14.76-.45,26.8-11.82,27.65-26.22.48-8.03-2.42-15.61-8.16-21.37-5.29-5.29-12.26-8.19-19.69-8.19h-2.08c-5.11,0-9.93-2-13.53-5.62-3.08-3.08-4.99-7.01-5.48-11.27-.09-.74-.13-1.5-.13-2.25v-.76h21.23c12.74,0,24.73,4.98,33.75,14.03,9.07,9.05,14.05,21.04,14.05,33.78s-5.6,26.1-15.41,35.15c16.18,9.56,26.08,26.99,25.92,45.77-.12,14.36-6.2,27.38-15.9,36.8Z"
      />
    </svg>
  );
};

export default BookingByte;
