import { Form } from "react-bootstrap";
import FormErrorFeedback from "./validations/FormErrorFeedback";

interface CustomTextAreaWithLabelProps {
  id: string;
  labelText: string;
  required?: boolean;
  value?: string | number;
  touched?: boolean;
  errors?: string | string[];
  handleChange: (e: React.ChangeEvent<any>) => void;
}
const CustomTextAreaWithLabel = ({
  id,
  labelText,
  required,
  handleChange,
  value = "",
  touched,
  errors,
}: CustomTextAreaWithLabelProps) => (
  <>
    {labelText ? (
      <Form.Label htmlFor={id} className="form-label mt-3">
        {labelText}
        {required ? <span className="text-danger">*</span> : null}
      </Form.Label>
    ) : null}
    <Form.Control
      as="textarea"
      rows={3}
      id={id}
      onChange={handleChange}
      value={value}
      isValid={touched && !errors}
      isInvalid={touched && !!errors}
      className="form-txtarea form-control"
    />
    {touched && errors ? (
      <FormErrorFeedback id={id} label={labelText} errors={errors} />
    ) : null}
  </>
);

export default CustomTextAreaWithLabel;
