import React from "react";

interface TaxiProfileContainerProps {
    title: string;
    value: string;
  }

const TaxiProfileContainer = ({title, value}: TaxiProfileContainerProps) => {
    return <div className="col-sm-4 col-6">
        <div className="bg-light rounded p-3">
            <h6 className="fs-15 mb-0">{value}</h6>
            <span className="fs-13">{title}</span>
        </div>
    </div>}

export default TaxiProfileContainer