import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import logo from "../../images/logo/logo-full.png";
import LogoWhite from "../../images/logo/logofull-white.png";
import bg6 from "../../images/background/bg6.jpg";
import { RegistrationForm } from "src/store/reducers/auth.d";
import { DEFAULT_ERR_MESSAGE } from "src/utils/constants";
import { registerAPI } from "src/apis/authComponent";
import CustomInput from "src/layouts/form/CustomInput";
import {
  EmailRequired,
  KeyRequired,
  LengthRequired,
  StringLengthValidation,
} from "src/layouts/form/validations/Validation";
import LoginHeader from "src/layouts/modules/login/LoginHeader";

export default function Register() {
  const [isAuthLoading, setAuthLoading] = useState(false);

  const navigate = useNavigate();

  const register = async (values: RegistrationForm) => {
    // console.log(values);
    try {
      setAuthLoading(true);
      await registerAPI(values);
      toast.success("Registration is success, Please login");
      navigate("/login");
    } catch (error: any) {
      toast.error(error.message ?? DEFAULT_ERR_MESSAGE);
      setAuthLoading(false);
    }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      country_code: "",
      mobile_number: "",
      email: "",
      company_name: "",
      city: "",
      password: "",
      passwordRetype: "",
    },
    validationSchema: Yup.object({
      first_name: StringLengthValidation("First name", 3, 30),
      last_name: StringLengthValidation("Last name", 3, 30),
      company_name: StringLengthValidation("Company name", 3, 30),
      city: KeyRequired("City"),
      country_code: Yup.number()
        .positive()
        .integer()
        .test(
          "minlen",
          "Country code must be 1 digits or more",
          (val) => val !== undefined && val.toString().length >= 1
        )
        .test(
          "maxlen",
          "Country code must be 3 digits or less",
          (val) => val !== undefined && val.toString().length <= 3
        )
        .required("Country code is required"),
      mobile_number: LengthRequired("Mobile number", 10),
      email: EmailRequired("Email address"),
      password: Yup.string()
        .min(6, "Password is too short - should be 6 chars minimum.")
        .max(30, "Must be 30 characters or less")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/,
          "Please create a stronger password."
        )
        .required("Required"),
      passwordRetype: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      register(values);
    },
  });
  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ background: "#fff url(" + bg6 + ")", height: "100vh" }}
        >
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: "653px" }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    // dir: "ltr",
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <LoginHeader />
                      <nav className="nav nav-tabs border-bottom-0" style={{"overflowY": "auto", "height" : "calc(100vh - 250px)"}}>
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div className="tab-pane active show fade">
                            {/* {errorMessage && (
                              <div className="">{errorMessage}</div>
                            )}
                            {successMessage && (
                              <div className="">{successMessage}</div>
                            )} */}
                            <h3 className="form-title">Sign Up</h3>
                            <div className="dz-separator-outer m-b5">
                              <div className="dz-separator bg-primary style-liner"></div>
                            </div>
                            <p>Enter your personal details below: </p>
                            <form
                              className="dz-form py-2"
                              onSubmit={handleSubmit}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <CustomInput
                                    id="first_name"
                                    placeholder="First name"
                                    handleChange={handleChange}
                                    value={values.first_name}
                                    touched={touched.first_name}
                                    errors={errors.first_name}
                                  />
                                </div>
                                <div className="col-6">
                                  <CustomInput
                                    id="last_name"
                                    placeholder="Last name"
                                    handleChange={handleChange}
                                    value={values.last_name}
                                    touched={touched.last_name}
                                    errors={errors.last_name}
                                  />
                                </div>
                              </div>
                              <div className="row g-1">
                                <div className="col-3">
                                  <CustomInput
                                    id="country_code"
                                    placeholder="Country code"
                                    // inputType="number"
                                    handleChange={handleChange}
                                    value={values.country_code}
                                    touched={touched.country_code}
                                    errors={errors.country_code}
                                  />
                                </div>
                                <div className="col-9">
                                  <CustomInput
                                    id="mobile_number"
                                    placeholder="Mobile number"
                                    // inputType="number"
                                    handleChange={handleChange}
                                    value={values.mobile_number}
                                    touched={touched.mobile_number}
                                    errors={errors.mobile_number}
                                  />
                                </div>
                              </div>
                              <CustomInput
                                id="email"
                                placeholder="Email"
                                inputType="email"
                                handleChange={handleChange}
                                value={values.email}
                                touched={touched.email}
                                errors={errors.email}
                              />
                              <CustomInput
                                id="company_name"
                                placeholder="Company Name"
                                handleChange={handleChange}
                                value={values.company_name}
                                touched={touched.company_name}
                                errors={errors.company_name}
                              />
                              <CustomInput
                                id="city"
                                placeholder="City"
                                handleChange={handleChange}
                                value={values.city}
                                touched={touched.city}
                                errors={errors.city}
                              />
                              <CustomInput
                                id="password"
                                placeholder="Password"
                                inputType="password"
                                handleChange={handleChange}
                                value={values.password}
                                touched={touched.password}
                                errors={errors.password}
                              />
                              <CustomInput
                                id="passwordRetype"
                                placeholder="Retype password"
                                inputType="password"
                                handleChange={handleChange}
                                value={values.passwordRetype}
                                touched={touched.passwordRetype}
                                errors={errors.passwordRetype}
                              />

                              <div className="mb-3 mt-3">
                                <span className="form-check float-start me-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input mt-0"
                                    id="check2"
                                    name="example1"
                                  />
                                  <label
                                    className="form-check-label d-unset"
                                    htmlFor="check2"
                                  >
                                    I agree to the
                                  </label>
                                </span>
                                <label>
                                  <Link to={"#"}>Terms of Service </Link>&amp;{" "}
                                  <Link to={"#"}>Privacy Policy</Link>
                                </label>
                              </div>
                              <div className="form-group clearfix text-left">
                                <NavLink
                                  to="/login"
                                  className="btn btn-primary outline gray"
                                  type="button"
                                >
                                  Back
                                </NavLink>

                                <Button
                                  type="submit"
                                  disabled={isAuthLoading}
                                  // style={{ width: "100%" }}
                                  className="btn btn-primary float-end"
                                >
                                  {isAuthLoading ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                                {/* <button
                                  type="submit"
                                  className="btn btn-primary float-end"
                                >
                                  Submit
                                </button> */}
                              </div>
                            </form>
                          </div>
                        </div>
                      </nav>
                    </div>
                    {/* <div className="card-footer">
                      <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                        <div className="col-lg-12 text-center">
                          <span>
                            {" "}
                            © Copyright by
                            <span
                              className={`heart ${
                                heartActive ? "" : "heart-blast"
                              }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span>
                            <a
                              href="https://www.dexignzone.com/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {" "}
                              DexignZone{" "}
                            </a>{" "}
                            All rights reserved.
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
