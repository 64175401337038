import { Button, Modal } from "react-bootstrap";

interface ConfirmationModalProps {
  show: boolean;
  handleClose: any;
  confirmClose: any;
}

function ConfirmationModal({
  show,
  handleClose,
  confirmClose,
}: ConfirmationModalProps) {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      {/* <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>Please confirm that you want to delete</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmClose}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmationModal;
